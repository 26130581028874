import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers"; // Đảm bảo bạn có reducer chính xác
import rootSaga from "./saga"; // Đảm bảo bạn có saga chính xác

// Tạo middleware saga
const sagaMiddleware = createSagaMiddleware();

// Cấu hình store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Tắt kiểm tra tuần tự hóa
    }).concat(sagaMiddleware),
});

// Chạy saga
sagaMiddleware.run(rootSaga);

export default store;
