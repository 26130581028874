import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { changePassword, setUserDataNull } from "../../store/auth/slice";
import { Password } from "primereact/password";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);

  const { changePasswordData, changePasswordError } = useSelector(
    (state: any) => ({
      changePasswordData: state.Auth.changePasswordData,
      changePasswordError: state.Auth.changePasswordError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      current_password: Yup.string().required("Vui lòng nhập mật khẩu cũ"),
      new_password: Yup.string().required("Vui lòng nhập mật khẩu mới"),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref("new_password")],
          "Mật khẩu xác nhận phải trùng với mật khẩu mới"
        )
        .required("Xác nhận mật khẩu thay đổi"),
    }),
    onSubmit: (values: any) => {
      const { current_password, new_password } = values; // Destructure values
      dispatch(changePassword({ data: { current_password, new_password } }));
    },
  });

  useEffect(() => {
    if (changePasswordData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: changePasswordData?.message,
        life: 3000,
      });
      formik.resetForm();
      dispatch(setUserDataNull());
    }
    if (changePasswordError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: changePasswordError?.response?.data?.message,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordData, changePasswordError, dispatch]);

  return (
    <div className="flex justify-content-center align-items-center">
      <Toast ref={toast} />
      <div className="bg-white border-round-md shadow-1 p-7 sm:w-8 w-11 xl:w-6 lg:w-8">
        <h1 className="mb-3">Thay đổi mật khẩu</h1>
        <div className="flex flex-column w-full">
          <div className="flex flex-column w-full">
            <label htmlFor="current_password" className="mb-2 font-semibold">
              Mật khẩu cũ
            </label>
            <Password
              value={formik.values.current_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="current_password"
              name="current_password"
              type="current_password"
              autoComplete="on"
              inputClassName="w-full p-3"
              pt={{
                iconField: {
                  root: { className: "w-full" },
                },
              }}
              toggleMask
              feedback={false}
              invalid={
                formik.touched.current_password &&
                formik.errors.current_password
                  ? true
                  : false
              }
            />
            {formik.touched.current_password &&
            formik.errors.current_password ? (
              <p
                style={{
                  color: "red",
                  marginTop: "5px",
                  fontSize: "0.9rem",
                }}
              >
                {String(formik.errors.current_password)}
              </p>
            ) : null}
          </div>

          <label htmlFor="new_password" className="mb-2 font-semibold mt-4">
            Mật khẩu mới
          </label>
          <Password
            value={formik.values.new_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="new_password"
            name="new_password"
            type="new_password"
            autoComplete="on"
            inputClassName="w-full p-3"
            pt={{
              iconField: {
                root: { className: "w-full" },
              },
            }}
            toggleMask
            feedback={false}
            invalid={
              formik.touched.new_password && formik.errors.new_password
                ? true
                : false
            }
          />
          {formik.touched.new_password && formik.errors.new_password ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.new_password)}
            </p>
          ) : null}
          <label htmlFor="confirm_password" className="mb-2 font-semibold mt-4">
            Xác nhận mật khẩu
          </label>
          <Password
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            id="confirm_password"
            name="confirm_password"
            type="confirm_password"
            autoComplete="on"
            inputClassName="w-full p-3"
            pt={{
              iconField: {
                root: { className: "w-full" },
              },
            }}
            toggleMask
            feedback={false}
            invalid={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? true
                : false
            }
          />
          {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
              }}
            >
              {String(formik.errors.confirm_password)}
            </p>
          ) : null}
          <div className="flex gap-2 justify-content-start pt-3 mt-2">
            <Button
              type="submit"
              label="Lưu"
              style={{ width: "80px" }}
              onClick={() => formik.handleSubmit()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
