import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { importExcelSuccess, importExcelFail } from "./slice";
import { IMPORT_EXCEL_CONTAINER } from "../../api/url_helper";
import axios from "axios";

function* importExcelSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { files, date_select } = action.payload;

    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
    }
    const response = yield call(
      axios.post,
      `${IMPORT_EXCEL_CONTAINER}?date_select=${date_select}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    yield put(importExcelSuccess(response.data));
  } catch (error) {
    yield put(importExcelFail(error));
  }
}

function* ContainerSaga(): Generator<any, void, any> {
  yield takeLatest("container/importExcel", importExcelSaga);
}

export default ContainerSaga;
