import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getPackageDataFail,
  getPackageDataSuccess,
  getPlanDataSuccess,
  getPlanDataFail,
  deletePackageFail,
  deletePackageSuccess,
  insertPackageFail,
  insertPackageSuccess,
  updatePackageFail,
  updatePackageSuccess,
  importExcelSuccess,
  importExcelFail,
} from "./slice";

import {
  getAllPackage,
  deletePackage,
  insertPackage,
  updatePackage,
  getAllPlan,
} from "../../api/backend_helper";
import { IMPORT_DATA_EXCEL } from "../../api/url_helper";
import axios from "axios";

interface GetPlanePayload {
  page: number;
  limit: number;
  query?: any;
}

interface GetPlanPayload {
  start_date: string;
  end_date: string;
  query?: any;
}

function* getPackageSaga(
  action: PayloadAction<GetPlanePayload>
): Generator<any, void, any> {
  try {
    const { page, limit, query = {} } = action.payload;
    const data = yield call(getAllPackage, page, limit, query);
    yield put(getPackageDataSuccess(data));
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      yield put(getPackageDataFail(error));
    } else {
      yield put(getPackageDataFail(error));
    }
  }
}

function* getPlanPackageSaga(
  action: PayloadAction<GetPlanPayload>
): Generator<any, void, any> {
  try {
    const { start_date, end_date, query = {} } = action.payload;
    const data = yield call(getAllPlan, start_date, end_date, query);
    yield put(getPlanDataSuccess(data));
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      yield put(getPlanDataFail(error));
    } else {
      yield put(getPlanDataFail(error));
    }
  }
}

function* deletePackageSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deletePackage, action.payload);
    yield put(deletePackageSuccess(data));
  } catch (error) {
    yield put(deletePackageFail(error));
  }
}

function* insertPlaneSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(insertPackage, action.payload);
    yield put(insertPackageSuccess(data));
  } catch (error) {
    yield put(insertPackageFail(error));
  }
}

function* updatePackageSaga(
  action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updatePackage, id, data);
    yield put(updatePackageSuccess(response));
  } catch (error) {
    yield put(updatePackageFail(error));
  }
}

function* importExcelSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const files = action.payload;
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
    }
    const response = yield call(axios.post, IMPORT_DATA_EXCEL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    yield put(importExcelSuccess(response.data));
  } catch (error) {
    yield put(importExcelFail(error));
  }
}

function* PackageSaga(): Generator<any, void, any> {
  yield takeLatest("package/getPackageData", getPackageSaga);
  yield takeLatest("package/getPlanData", getPlanPackageSaga);
  yield takeLatest("package/deletePackage", deletePackageSaga);
  yield takeLatest("package/insertPackage", insertPlaneSaga);
  yield takeLatest("package/updatePackage", updatePackageSaga);
  yield takeLatest("package/importExcel", importExcelSaga);
}

export default PackageSaga;
