import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ContainerState {
  containerData: any | null;
  error: any | null;
  loading: boolean;
  excelContainerData: any | null;
  importExcelContainerData: any | null;
  importExcelContainerError: any | null;
}

const initialState: ContainerState = {
  containerData: null,
  error: null,
  loading: false,
  excelContainerData: null,
  importExcelContainerData: null,
  importExcelContainerError: null,
};

const containerSlice = createSlice({
  name: "container",
  initialState,
  reducers: {
    importExcel: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    importExcelSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelContainerData = action.payload;
      state.importExcelContainerError = null;
    },
    importExcelFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.importExcelContainerData = null;
      state.importExcelContainerError = action.payload;
    },
    setContainerDataNull: (state) => {
      state.importExcelContainerData = null;
      state.importExcelContainerError = null;
    },
  },
});

export const {
  importExcel,
  importExcelSuccess,
  importExcelFail,
  setContainerDataNull,
} = containerSlice.actions;

export default containerSlice.reducer;
