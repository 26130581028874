import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { site } from "../api/url_helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  hasCommonPermissionsCar,
  hasCommonPermissionsPackage,
  hasCommonPermissionsPermission,
  hasCommonPermissionsPlane,
  hasCommonPermissionsShipment,
  hasCommonPermissionsUser,
  hasCommonPermissionsWarehouse,
} from "../ultis/permissionUtils";

const Authmiddleware = (props: any) => {
  const navigate = useNavigate();
  const { path } = props;

  const { dataUserInfo } = useSelector(
    (state: any) => ({
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useEffect(() => {
    const getAuth = async () => {
      try {
        const data = await axios.get(`${site}/api/v1/auth/get-auth`, {
          withCredentials: true,
        });
        if (data.status === 200) {
          const auth = data.data.auth;
          if (auth && dataUserInfo) {
            // Permission checks based on the path and corresponding state
            switch (path) {
              case "/package":
                if (
                  !hasCommonPermissionsPackage(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/car":
                if (
                  !hasCommonPermissionsCar(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/warehouse":
                if (
                  !hasCommonPermissionsWarehouse(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/transit":
                if (
                  !hasCommonPermissionsShipment(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/plane":
                if (
                  !hasCommonPermissionsPlane(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/user":
                if (
                  !hasCommonPermissionsUser(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              case "/permission":
                if (
                  !hasCommonPermissionsPermission(
                    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                  )
                ) {
                  navigate("/blankpage");
                }
                break;
              default:
                navigate(path);
                break;
            }
          }
        }
      } catch (error) {
        navigate("/login");
      }
    };

    getAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, path, dataUserInfo]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;
