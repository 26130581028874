import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CarState {
  carData: any | null;
  error: string | null;
  loading: boolean;
  insertCarData: any | null;
  updateCarData: any | null;
  deleteCarData: any | null;
  insertCarError: string | null;
  updateCarError: string | null;
  deleteCarError: string | null;
  messageRole: string | null;
}

const initialState: CarState = {
  carData: null,
  error: null,
  loading: false,
  insertCarData: null,
  updateCarData: null,
  deleteCarData: null,
  insertCarError: null,
  updateCarError: null,
  deleteCarError: null,
  messageRole: null,
};

interface CarProps {
  id?: string;
  name: string;
  license_plate: string;
  capacity: string;
  description: string;
}

const carSlice = createSlice({
  name: "car",
  initialState,
  reducers: {
    getCarData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getCarDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.carData = action.payload;
      state.error = null;
    },
    getCarDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.carData = null;
      state.error = action.payload;
    },
    insertCar: (state, action: PayloadAction<CarProps>) => {
      state.loading = true;
    },
    insertCarSuccess: (state, action: PayloadAction<CarProps>) => {
      state.loading = false;
      state.insertCarData = action.payload;
      state.insertCarError = null;
    },
    insertCarFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertCarData = null;
      state.insertCarError = action.payload;
    },
    updateCar: (
      state,
      action: PayloadAction<{ id: string; data: CarProps }>
    ) => {
      state.loading = true;
    },
    updateCarSuccess: (state, action) => {
      state.loading = false;
      state.updateCarData = action.payload;
      state.updateCarError = null;
    },
    updateCarFail: (state, action) => {
      state.loading = false;
      state.updateCarData = null;
      state.updateCarError = action.payload;
    },
    deleteCar: (state) => {
      state.loading = true;
    },
    deleteCarSuccess: (state, action) => {
      state.loading = false;
      state.deleteCarData = action.payload;
      state.deleteCarError = null;
    },
    deleteCarFail: (state, action) => {
      state.loading = false;
      state.deleteCarData = null;
      state.deleteCarError = action.payload;
    },
    setCarDataNull: (state) => {
      // state.carData = null;
      state.loading = false;
      state.error = null;
      state.insertCarData = null;
      state.updateCarData = null;
      state.deleteCarData = null;
      state.insertCarError = null;
      state.updateCarError = null;
      state.deleteCarError = null;
    },
  },
});

export const {
  getCarData,
  getCarDataSuccess,
  getCarDataFail,
  insertCar,
  insertCarSuccess,
  insertCarFail,
  updateCar,
  updateCarSuccess,
  updateCarFail,
  deleteCar,
  deleteCarSuccess,
  deleteCarFail,
  setCarDataNull,
} = carSlice.actions;

export default carSlice.reducer;
