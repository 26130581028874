import React from "react";
import moment from "moment";

export const formatCurrency = (value: number) => {
    if (value === null || value === undefined) return "";
    const number = Math.abs(value);
    const formatted = number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return value < 0 ? `-${formatted}` : formatted;
};

export const formatDate = (date: Date | null): string => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateUser = (date: Date | null): string => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
};
