/* eslint-disable react/jsx-no-comment-textnodes */
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UploadFile from "../../components/UploadFile";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { importExcel, setPackageDataNull } from "../../store/listPackage/slice";
import { DataContext } from "../../contexts/data/DataProvider";
import "../../assets/scss/theme-base/table.scss";

interface ModalImportExcelProps {
  visible: boolean;
  onHide: () => void;
  toast: any;
}

const ModalImportExcel: FC<ModalImportExcelProps> = ({
  visible,
  onHide,
  toast,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [clearFiles, setClearFiles] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { totalPackage, currentPackage, setTotalPackage, setCurrentPackage } =
    useContext(DataContext);
  const [excelProcessingResult, setExcelProcessingResult] = useState<any>();

  const header = <div>Thêm từ Excel</div>;

  const handleSubmit = () => {
    setTotalPackage("");
    setCurrentPackage("");
    if (selectedFiles.length > 0) {
      setLoading(true);
      dispatch(importExcel(selectedFiles));
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Vui lòng chọn File cần nhập vào",
        life: 3000,
      });
      return;
    }
  };

  const { importExcelData, importExcelError } = useSelector(
    (state: any) => ({
      importExcelData: state.Package.importExcelData,
      importExcelError: state.Package.importExcelError,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (importExcelData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: importExcelData?.message,
        life: 3000,
      });
      // setTotalPackage("");
      // setCurrentPackage("");
      // setTotalFileError(importExcelData?.total_error);
      setExcelProcessingResult(importExcelData);
      setSelectedFiles([]);
      setClearFiles(!clearFiles);
      dispatch(setPackageDataNull());
    }
    if (importExcelError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: importExcelError?.response?.data?.message,
        life: 3000,
      });
      setClearFiles(!clearFiles);
      dispatch(setPackageDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importExcelData, importExcelError]);

  return (
    <>
      <Dialog
        visible={visible}
        onHide={() => {
          onHide();
          setLoading(false);
          setTotalPackage("");
          setCurrentPackage("");
        }}
        className="sm:w-8 w-10 xl:w-6 lg:w-8"
        header={header}
      >
        <>
          <UploadFile
            onUpload={() => {}}
            getFiles={setSelectedFiles}
            clearFiles={clearFiles}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "60px",
              gap: "1rem",
              alignItems: "end",
            }}
            className="w-full"
          >
            {currentPackage ? (
              <div>
                <p
                  className="font-semibold italic w-full"
                  style={{ color: "#295bac" }}
                >
                  {Number(currentPackage) > 0 &&
                    Number(currentPackage) < Number(totalPackage) && (
                      <>Đang tải File lên</>
                    )}
                </p>
                <div className="mb-2 w-full">
                  <progress
                    id="file"
                    value={currentPackage}
                    max={totalPackage}
                    className="custom-progress"
                    style={{ width: "215px" }}
                  ></progress>
                </div>
                <div className="w-full mt-2">
                  {loading && (
                    <>
                      <p className="text-sm mr-2 font-medium mb-2">
                        <i
                          className="pi pi-box"
                          style={{ color: "#708090" }}
                        ></i>
                        Tổng kiện hàng: {totalPackage}
                      </p>
                      <p className="text-sm mr-2 font-medium mb-1 text-teal-600">
                        {Number(currentPackage) > 0 &&
                          currentPackage === totalPackage && (
                            <>
                              <i
                                className="pi pi-cart-plus"
                                style={{ color: "#0D9488" }}
                              ></i>
                              <span className="text-teal-600">
                                Số kiện hàng thành công:
                              </span>
                              {excelProcessingResult?.total_done}
                            </>
                          )}
                      </p>
                      <div className="flex mt-2">
                        <p className="text-sm mr-2 font-medium mb-1 text-red-500">
                          {Number(currentPackage) > 0 &&
                            currentPackage === totalPackage && (
                              <>
                                <i
                                  className="pi pi-cart-plus"
                                  style={{ color: "red" }}
                                ></i>
                                <span>Số kiện hàng lỗi: </span>
                                {excelProcessingResult?.total_error}
                              </>
                            )}
                        </p>
                        <p className="text-sm mr-2 font-medium mb-1 text-red-500">
                          {Number(currentPackage) > 0 &&
                            currentPackage === totalPackage && (
                              <a
                                href={excelProcessingResult?.fileResult}
                                download
                                className="cursor-pointer text-red-500 font-bold"
                              >
                                (Tải File lỗi)
                              </a>
                            )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className="w-100 d-flex justify-content-end">
              <Button
                label="Huỷ"
                className="px-5 py-2 mr-2 "
                type="submit"
                style={{
                  backgroundColor: "#808080",
                  color: "white",
                  width: "100px", // Set a fixed width for consistency
                }}
                onClick={() => {
                  onHide();
                  setLoading(false);
                  setTotalPackage("");
                  setCurrentPackage("");
                }}
              />
              <Button
                label="Nhập"
                className="px-5 py-2 mt-2"
                type="submit"
                style={{
                  width: "100px", // Set the same fixed width
                }}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </>
      </Dialog>
    </>
  );
};

export default ModalImportExcel;
