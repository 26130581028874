import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts, { ApexOptions } from "apexcharts";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import "../../assets/scss/theme-base/table.scss";

const ChartVehicle = () => {
    const [selectedTransPort, setSelectedTransPort] = useState(null);
    const options: ApexOptions = {
        // borderWidth: 0,
        chart: {
            type: "donut",
        },
        legend: {
            show: false,
        },
        series: [245, 60, 80],
        labels: [
            "Hoàn thành đúng giờ",
            "Hoàn thành trễ hạn",
            "Đang vận chuyển",
        ],
        colors: ["#0000ff", "#ff0000", "#fea500"],
        dataLabels: { enabled: false },
        plotOptions: {
            pie: {
                donut: {
                    size: "75%",
                },
                startAngle: 0,
                endAngle: 360,
            },
        },
    };

    const vehicle = [
        { name: "Xe tải", code: "car" },
        { name: "Máy bay", code: "plane" },
    ];

    return (
        <div className="px-4">
            <div className="flex justify-content-between align-items-center mb-8">
                <p className="font-semibold text-600">Phương tiện vận tải</p>
                <Dropdown
                    value={selectedTransPort}
                    onChange={(e) => setSelectedTransPort(e.value)}
                    options={vehicle}
                    optionLabel="name"
                    placeholder="Lọc phương tiện"
                    className=" mr-2 w-5"
                    style={{ height: "35px" }}
                    showClear
                />
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "1rem",
                }}
            >
                <p className="text-base font-semibold text-600">
                    Tổng số chuyến
                </p>
            </div>
            <div>
                <p
                    style={{
                        marginTop: "1rem",
                        fontSize: "32px",
                        fontWeight: "700",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "2rem",
                    }}
                >
                    100
                </p>
                <ReactApexChart
                    options={options}
                    series={options.series}
                    type="donut"
                    height={"200px"}
                />
                <div className="mt-4 flex justify-content-center">
                    <div
                        // key={item.status}
                        className="flex gap-1 mx-7 w-12 md:w-6"
                        style={{ alignItems: "center" }}
                    >
                        <div
                            className="dot"
                            style={{
                                // backgroundColor: `${item.color}`,
                                backgroundColor: "#0000ff",
                            }}
                        ></div>
                        {/* <p className="col-8">{item.status}</p>
                        <p className="col-2 flex justify-content-end">
                            {item.count}
                        </p> */}
                        <p className="col-8">Hoàn thành đúng giờ</p>
                        <p className="col-2 flex justify-content-end">245</p>
                    </div>
                </div>{" "}
                <div className=" flex justify-content-center">
                    <div
                        // key={item.status}
                        className="flex gap-1 mx-7 w-12 md:w-6"
                        style={{ alignItems: "center" }}
                    >
                        <div
                            className="dot"
                            style={{
                                // backgroundColor: `${item.color}`,
                                backgroundColor: "#ff0000",
                            }}
                        ></div>
                        {/* <p className="col-8">{item.status}</p>
                        <p className="col-2 flex justify-content-end">
                            {item.count}
                        </p> */}
                        <p className="col-8">Hoàn thành trễ hạn</p>
                        <p className="col-2 flex justify-content-end">60</p>
                    </div>
                </div>
                <div className="flex justify-content-center">
                    <div
                        // key={item.status}
                        className="flex gap-1 mx-7 w-12 md:w-6"
                        style={{ alignItems: "center" }}
                    >
                        <div
                            className="dot"
                            style={{
                                // backgroundColor: `${item.color}`,
                                backgroundColor: "#fea500",
                            }}
                        ></div>
                        {/* <p className="col-8">{item.status}</p>
                        <p className="col-2 flex justify-content-end">
                            {item.count}
                        </p> */}
                        <p className="col-8">Đang vận chuyển</p>
                        <p className="col-2 flex justify-content-end">80</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChartVehicle;
