import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  loginFail,
  loginSuccess,
  getDataUserSuccess,
  getDataUserFail,
  registerUserSuccess,
  registerUserFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  resetPasswordFail,
  resetPasswordSuccess,
  uploadFileFail,
  uploadFileSuccess,
  getDataInfoSuccess,
  getDataInfoFail,
  updateProfileSuccess,
  updateProfileFail,
  changePasswordSuccess,
  changePasswordFail,
  logoutSuccess,
  logoutFail,
} from "./slice";
import {
  login,
  getAllUser,
  deleteUser,
  resetPassword,
  getInfo,
  updateProfile,
  changePassword,
  logout,
} from "../../api/backend_helper";
import * as URL_API from "../../api/url_helper";
import axios from "axios";

function* onLogin(action: PayloadAction<any>): Generator<any, void, any> {
  try {
    const response = yield call(login, action.payload);
    yield put(loginSuccess(response));
    localStorage.setItem("currentUserId", response.id);
  } catch (error) {
    yield put(loginFail(error));
  }
}

function* fetchUserDataSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { page, limit, query = {} } = action.payload;
    const data = yield call(getAllUser, page, limit, query);
    yield put(getDataUserSuccess(data));
  } catch (error) {
    yield put(getDataUserFail(error));
  }
}

function* fetchUserInfoDataSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(getInfo);
    yield put(getDataInfoSuccess(data));
  } catch (error) {
    yield put(getDataInfoFail(error));
  }
}

function* deleteUserSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deleteUser, action.payload);
    yield put(deleteUserSuccess(data));
  } catch (error) {
    yield put(deleteUserFail(error));
  }
}

function* resetPasswordSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(resetPassword, action.payload);
    yield put(resetPasswordSuccess(data));
  } catch (error) {
    yield put(resetPasswordFail(error));
  }
}

function* createUserSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const {
      full_name,
      email,
      username,
      password,
      permissions_group_id,
      address,
      phone_number,
      dob,
      position,
      gender,
      file,
      identification_code,
      register_by_admin,
    } = action.payload;

    const formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("phone_number", phone_number);
    formData.append("password", password);
    formData.append("position", position);
    formData.append("address", address);
    formData.append("gender", gender);
    formData.append("dob", dob);
    formData.append("permissions_group_id", permissions_group_id);
    formData.append("identification_code", identification_code);
    formData.append("register_by_admin", register_by_admin);

    if (file) {
      formData.append("file", file);
    }

    const response = yield call(axios.post, URL_API.REGISTER, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });

    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    // Dispatch action thành công
    yield put(registerUserSuccess(dataUpdate));
  } catch (error) {
    yield put(registerUserFail(error));
  }
}

function* updateUserSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const {
      id,
      full_name,
      email,
      username,
      permissions_group_id,
      address,
      phone_number,
      dob,
      position,
      gender,
      file,
      identification_code,
      active,
    } = action.payload;

    const formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("phone_number", phone_number);
    formData.append("position", position);
    formData.append("address", address);
    formData.append("gender", gender);
    formData.append("dob", dob);
    formData.append("permissions_group_id", permissions_group_id);
    formData.append("identification_code", identification_code);
    formData.append("active", active);
    if (file) {
      formData.append("file", file);
    }

    const response = yield call(
      axios.put,
      `${URL_API.UPDATE_USER}?id=${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );

    const headers = { ...response.headers };
    const dataUpdate = { ...response.data, headers };

    yield put(updateUserSuccess(dataUpdate));
  } catch (error) {
    yield put(updateUserFail(error));
  }
}

function* uploadFileSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const { file } = action.payload;
    const formData = new FormData();

    if (file) {
      formData.append("file", file);
    }

    const response = yield call(axios.put, URL_API.UPLOAD_FILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    // Convert Axios headers to a plain object
    const headers = { ...response.headers };
    const uploadFile = { ...response.data, headers };
    yield put(uploadFileSuccess(uploadFile));
  } catch (error) {
    yield put(uploadFileFail(error));
  }
}

function* updateProfileSaga(
  action: PayloadAction<{ data: any }>
): Generator<any, void, any> {
  try {
    const { data } = action.payload;
    const response = yield call(updateProfile, data);
    yield put(updateProfileSuccess(response));
  } catch (error) {
    yield put(updateProfileFail(error));
  }
}

function* changePasswordSaga(
  action: PayloadAction<{ data: any }>
): Generator<any, void, any> {
  try {
    const { data } = action.payload;
    const response = yield call(changePassword, data);
    yield put(changePasswordSuccess(response));
  } catch (error) {
    yield put(changePasswordFail(error));
  }
}

function* logoutSaga(action: PayloadAction<any>): Generator<any, void, any> {
  try {
    const response = yield call(logout);
    yield put(logoutSuccess(response));
  } catch (error) {
    yield put(logoutFail(error));
  }
}

function* AuthSaga(): Generator<any, void, any> {
  yield takeLatest("auth/login", onLogin);
  yield takeLatest("auth/getDataUser", fetchUserDataSaga);
  yield takeLatest("auth/getDataInfo", fetchUserInfoDataSaga);
  yield takeLatest("auth/registerUser", createUserSaga);
  yield takeLatest("auth/updateUser", updateUserSaga);
  yield takeLatest("auth/deleteUser", deleteUserSaga);
  yield takeLatest("auth/resetPassword", resetPasswordSaga);
  yield takeLatest("auth/uploadFile", uploadFileSaga);
  yield takeLatest("auth/updateProfile", updateProfileSaga);
  yield takeLatest("auth/changePassword", changePasswordSaga);
  yield takeLatest("auth/logout", logoutSaga);
}

export default AuthSaga;
