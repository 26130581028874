import React, { useEffect, useLayoutEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import "../../assets/scss/theme-base/table.scss";
import ChartVehicle from "./ChartVehicle";
import ChartPackage from "./ChartPackage";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { PermissionRequired } from "../../components/PermissionRequired";

function Statistical() {
    const [dates, setDates] = useState(null);
    const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
        string[]
    >([]);

    const { dataUserInfo } = useSelector(
        (state: any) => ({
            dataUserInfo: state.Auth.dataUserInfo,
        }),
        shallowEqual
    );

    useLayoutEffect(() => {
        if (dataUserInfo) {
            const values = dataUserInfo?.data?.permissions_data.map(
                (e: any) => e.name
            );
            setSelectArrayPermisstion(values);
        }
    }, [dataUserInfo]);

    return (
        <div>
            <div className="calendar-container max-w-max mb-4">
                <i className="pi pi-calendar text-500 mr-1" />
                <label
                    className="w-max text-500"
                    htmlFor="startDate"
                    style={{ minWidth: "40px" }}
                >
                    Ngày:
                </label>
                <div className="card flex justify-content-center">
                    <Calendar
                        value={dates}
                        onChange={(e: any) => setDates(e.value)}
                        selectionMode="range"
                        readOnlyInput
                        hideOnRangeSelection
                        dateFormat="dd/mm/yy"
                        locale="vi"
                        placeholder="Từ ngày - Đến ngày"
                    />
                </div>
            </div>
            <div className="flex flex-column md:flex-row gap-4">
                <div className="bg-white border-round-lg py-4 chart-factory shadow-1 w-12 md:w-6">
                    {selectArrayPermisstion?.includes("VIEW_REPORT") ? (
                        <ChartVehicle />
                    ) : (
                        <PermissionRequired />
                    )}
                </div>
                <div className="bg-white border-round-lg py-4 chart-factory shadow-1 w-12 md:w-6">
                    {selectArrayPermisstion?.includes("VIEW_REPORT") ? (
                        <ChartPackage />
                    ) : (
                        <PermissionRequired />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Statistical;
