import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export const BlankPage = () => {
  const navigate = useNavigate();
  return (
    <div
      className="card p-5"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <img
        alt="Under development"
        src="/images/errors/error-404.png"
        style={{
          display: "inline-block",
          maxWidth: "100%",
          width: 300,
        }}
      />
      <h3 className="font-semibold mt-3 mb-3">
        Bạn không có quyền truy cập trang này
      </h3>
      <p>Vui lòng liên hệ quản trị viên để biết thêm chi tiết.</p>
      <Button
        label="Quay lại trang chủ"
        icon="pi pi-arrow-left"
        className="mt-3"
        onClick={() => navigate("/")}
      />
    </div>
  );
};
