import { Image } from "primereact/image";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getDataInfo,
  setUserDataNull,
  updateProfile,
  uploadFile,
} from "../../store/auth/slice";
import { formatDateUser } from "../../components/utils";
import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";
import { site } from "../../api/url_helper";

interface Gender {
  name: string;
  code: string;
}

function convertToLocalDateString(dateString: any) {
  const date = new Date(dateString);
  return date;
}

export default function Profile() {
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedImage(file);
    }
  };
  const genderData: Gender[] = [
    { name: "Nam", code: "MALE" },
    { name: "Nữ", code: "FEMALE" },
  ];

  const [isLoadDefault, setIsLoadDefault] = useState<boolean>(false);

  const { dataUserInfo, updateProfileData, updateProfilError } = useSelector(
    (state: any) => ({
      dataUserInfo: state.Auth.dataUserInfo,
      updateProfileData: state.Auth.updateProfileData,
      updateProfilError: state.Auth.updateProfilError,
    }),
    shallowEqual
  );
  const [initialUrlAvatar, setInitialUrlAvatar] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    dispatch(getDataInfo());
  }, [dispatch, updateProfileData]);

  useEffect(() => {
    if (uploadedImage) {
      const url = URL.createObjectURL(uploadedImage);
      setImageUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImageUrl(undefined);
    }
  }, [uploadedImage]);

  const formik = useFormik({
    initialValues: {
      full_name: "",
      phone_number: "",
      dob: "",
      gender: "",
      address: "",
      identification_code: "",
    },
    validationSchema: Yup.object().shape({
      full_name: Yup.string().required("Vui lòng nhập họ và tên"),
      phone_number: Yup.string()
        .required("Vui lòng nhập số điện thoại")
        .matches(/^\d+$/, "Số điện thoại phải là một số")
        .min(10, "Số điện thoại phải có ít nhất 10 chữ số")
        .max(11, "Số điện thoại chỉ được phép có tối đa 11 chữ số"),
      dob: Yup.string().required("Vui lòng chọn ngày sinh"),
      gender: Yup.string().required("Vui lòng chọn giới tính"),
      identification_code: Yup.string().required(
        "Vui lòng nhập số căn cước công đân"
      ),
    }),
    onSubmit: (values: any) => {
      const dataSend = {
        ...values,
        dob: formatDateUser(values.dob),
      };
      dispatch(updateProfile({ data: dataSend }));
      dispatch(uploadFile({ file: uploadedImage }));
    },
  });

  useEffect(() => {
    if (updateProfileData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Cập nhật người dùng thành công",
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    if (updateProfilError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${updateProfilError.message}`,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfileData, updateProfilError, dispatch]);

  useEffect(() => {
    if (dataUserInfo) {
      formik.setValues({
        full_name: dataUserInfo.data.full_name || "",
        phone_number: dataUserInfo.data.phone_number || "",
        dob: convertToLocalDateString(dataUserInfo.data.dob) || "",
        gender: dataUserInfo.data.gender || "",
        address: dataUserInfo.data.address || "",
        identification_code: dataUserInfo.data.identification_code || "",
      });
      setImageUrl(undefined);
      setInitialUrlAvatar(dataUserInfo?.data?.avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUserInfo, isLoadDefault]);

  return (
    <div className="flex justify-content-center align-items-center">
      <Toast ref={toast} />
      <div className="bg-white border-round-md shadow-1 p-7 sm:w-8 w-11 xl:w-6 lg:w-8">
        <h1 className="mb-3">Hồ sơ cá nhân</h1>
        <div className="flex  w-full">
          <div className="">
            <div className="mr-2 mt-5 flex flex-column align-items-center">
              <Avatar
                className="p-overlay-badge"
                image={
                  imageUrl
                    ? imageUrl
                    : initialUrlAvatar
                    ? `${site}${initialUrlAvatar}`
                    : "/images/avatas/avatar-null.png"
                }
                shape="circle"
                style={{ width: "120px", height: "120px" }}
              />
              <div className="button-container w-full text-start mt-2 ml-3">
                <input
                  type="file"
                  name="files"
                  id="upload"
                  multiple
                  hidden
                  accept="image/jpeg, image/png, image/gif"
                  onChange={handleFileChange}
                />
                <label htmlFor="upload" className="upload-file">
                  <i
                    className="pi pi-camera"
                    style={{
                      fontSize: "13px",
                      marginRight: "5px",
                      marginTop: "2px",
                    }}
                  ></i>
                  Tải ảnh
                </label>
              </div>
            </div>
          </div>
          <div className="w-9 ml-4">
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="full_name" className="mb-2 font-semibold">
                Họ & tên
              </label>
              <InputText
                value={formik.values.full_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="full_name"
                name="full_name"
                autoComplete="on"
                type="text"
                className="w-12"
                style={{ padding: "12px" }}
                invalid={
                  formik.touched.full_name && formik.errors.full_name
                    ? true
                    : false
                }
              />
              {formik.touched.full_name && formik.errors.full_name ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.full_name)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="phone_number" className="mb-2 font-semibold">
                Số điện thoại
              </label>
              <InputText
                value={formik.values.phone_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="phone_number"
                name="phone_number"
                autoComplete="on"
                type="text"
                className="w-12 "
                style={{ padding: "12px" }}
                invalid={
                  formik.touched.phone_number && formik.errors.phone_number
                    ? true
                    : false
                }
              />
              {formik.touched.phone_number && formik.errors.phone_number ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.phone_number)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3 ">
              <label className="mb-2 font-semibold" htmlFor="gender">
                Giới tính
              </label>
              <Dropdown
                value={formik.values.gender}
                onChange={(e: DropdownChangeEvent) => {
                  formik.setFieldValue("gender", e.value);
                }}
                options={genderData}
                optionLabel="name"
                className="w-12"
                onBlur={formik.handleBlur}
                optionValue="code"
                style={{ padding: "5px" }}
                invalid={
                  formik.touched.gender && formik.errors.gender ? true : false
                }
              />
              {formik.touched.gender && formik.errors.gender ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.gender)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="dob" className="mb-2 font-semibold">
                Ngày sinh
              </label>
              <Calendar
                id="dob"
                value={formik.values.dob}
                onChange={(e) => formik.setFieldValue("dob", e.value)}
                locale="vi"
                className="w-12"
                dateFormat="dd/mm/yy"
                style={{ height: "44px" }}
              />
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="address" className="mb-2 font-semibold">
                Địa chỉ
              </label>
              <InputText
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="address"
                name="address"
                autoComplete="on"
                type="text"
                className="w-12"
                style={{ padding: "12px" }}
                invalid={
                  formik.touched.address && formik.errors.address ? true : false
                }
              />
              {formik.touched.address && formik.errors.address ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.address)}
                </p>
              ) : null}
            </div>
            <div className="flex flex-column gap-1 flex-1 mb-3">
              <label htmlFor="address" className="mb-2 font-semibold">
                Căn cước công dân
              </label>
              <InputText
                value={formik.values.identification_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="identification_code"
                name="identification_code"
                autoComplete="on"
                type="text"
                className="w-12"
                style={{ padding: "12px" }}
                invalid={
                  formik.touched.identification_code &&
                  formik.errors.identification_code
                    ? true
                    : false
                }
              />
              {formik.touched.identification_code &&
              formik.errors.identification_code ? (
                <p
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontSize: "0.9rem",
                  }}
                >
                  {String(formik.errors.identification_code)}
                </p>
              ) : null}
            </div>
            <div className="flex gap-2 justify-content-start pt-3">
              <Button
                label="Hủy"
                severity="secondary"
                size="small"
                style={{ width: "80px" }}
                onClick={() => {
                  setIsLoadDefault(!isLoadDefault);
                }}
              />
              <Button
                type="submit"
                label="Lưu"
                style={{ width: "80px" }}
                onClick={() => formik.handleSubmit()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
