import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { insertPlane, setPlaneDataNull } from "../../store/plane/slice";
import { formatDate } from "../../components/utils";

interface Plane {
    name: string;
    code: string;
    departure_point: string;
    destination: string;
    description?: string;
    // start_time: any | null;
    // end_time: any | null;
}

export default function ModalAddPlane({
    visible,
    onHide,
    toast,
}: {
    visible: boolean;
    onHide: () => void;
    toast: any;
}) {
    const dispatch = useDispatch();

    const { insertPlaneData, insertPlaneError } = useSelector(
        (state: any) => ({
            insertPlaneData: state.Plane.insertPlaneData,
            insertPlaneError: state.Plane.insertPlaneError,
        }),
        shallowEqual
    );

    // Validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Tên chuyến bay là bắt buộc"),
        code: Yup.string().required("Mã chuyến bay là bắt buộc"),
        departure_point: Yup.string().required("Điểm khởi hành là bắt buộc"),
        destination: Yup.string().required("Điểm đến là bắt buộc"),
        description: Yup.string(),
        // start_time: Yup.date().required("Thời gian bắt đầu là bắt buộc"),
        // end_time: Yup.date()
        //     .required("Thời gian kết thúc là bắt buộc")
        //     .min(
        //         Yup.ref("start_time"),
        //         "Thời gian kết thúc phải sau thời gian bắt đầu"
        //     ),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            code: "",
            departure_point: "",
            destination: "",
            description: "",
            // start_time: null,
            // end_time: null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const planeData: Plane = {
                name: values.name,
                code: values.code,
                departure_point: values.departure_point,
                destination: values.destination,
                description: values.description,
                // start_time: formatDate(values.start_time),
                // end_time: formatDate(values.end_time),
            };
            dispatch(insertPlane(planeData));
            onHide();
        },
    });

    useEffect(() => {
        if (insertPlaneData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm chuyến bay thành công",
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setPlaneDataNull());
        }
        if (insertPlaneError) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: insertPlaneError?.response?.data?.message,
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setPlaneDataNull());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insertPlaneData, insertPlaneError, dispatch]);

    const header = <div>Thêm mới chuyến bay</div>;
    return (
        <Dialog
            visible={visible}
            onHide={() => onHide()}
            header={header}
            className="w-10 md:w-4"
        >
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="name">Tên chuyến bay</label>
                    <InputText
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                            formik.touched.name && formik.errors.name
                                ? "p-invalid"
                                : ""
                        }
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <small className="p-error">{formik.errors.name}</small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="code">Mã chuyến bay</label>
                    <InputText
                        id="code"
                        name="code"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                            formik.touched.code && formik.errors.code
                                ? "p-invalid"
                                : ""
                        }
                    />
                    {formik.touched.code && formik.errors.code ? (
                        <small className="p-error">{formik.errors.code}</small>
                    ) : null}
                </div>
                {/* <div className="flex gap-3 mb-3 justify-content-between">
          <div className="w-full">
            <label htmlFor="start_time" className="block mb-2">
              Thời gian bắt đầu
            </label>
            <Calendar
              id="start_time"
              value={formik.values.start_time}
              onChange={(e) => formik.setFieldValue("start_time", e.value)}
              showTime
              hourFormat="24"
              locale="vi"
              className="w-full"
              dateFormat="dd/mm/yy"
            />
            {formik.touched.start_time && formik.errors.start_time ? (
              <small className="p-error">{formik.errors.start_time}</small>
            ) : null}
          </div>
          <div className="w-full">
            <label htmlFor="end_time" className="block mb-2">
              Thời gian kết thúc
            </label>
            <Calendar
              id="end_time"
              value={formik.values.end_time}
              onChange={(e) => formik.setFieldValue("end_time", e.value)}
              showTime
              hourFormat="24"
              locale="vi"
              className="w-full"
              dateFormat="dd/mm/yy"
            />
            {formik.touched.end_time && formik.errors.end_time ? (
              <small className="p-error">{formik.errors.end_time}</small>
            ) : null}
          </div>
        </div> */}
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="departure_point">Điểm khởi hành</label>
                    <InputText
                        id="departure_point"
                        name="departure_point"
                        value={formik.values.departure_point}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.departure_point &&
                    formik.errors.departure_point ? (
                        <small className="p-error">
                            {formik.errors.departure_point}
                        </small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="destination">Điểm đến</label>
                    <InputText
                        id="destination"
                        name="destination"
                        value={formik.values.destination}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.destination && formik.errors.destination ? (
                        <small className="p-error">
                            {formik.errors.destination}
                        </small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="description">Mô tả</label>
                    <InputTextarea
                        id="description"
                        name="description"
                        rows={4}
                        className="w-full"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="flex gap-2 justify-content-end">
                    <Button
                        label="Hủy"
                        severity="secondary"
                        size="small"
                        type="button"
                        onClick={() => {
                            onHide();
                            formik.resetForm();
                        }}
                    />
                    <Button
                        type="submit"
                        label="Lưu"
                        size="small"
                        // onClick={handeClick}
                    />
                </div>
            </form>
        </Dialog>
    );
}
