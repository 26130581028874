import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
    insertWarehouse,
    setWarehouseDataNull,
} from "../../store/warehouse/slice";

import * as Yup from "yup";
import { useFormik } from "formik";

export default function ModalAddWarehouse({
    visible,
    onHide,
    toast,
}: {
    visible: boolean;
    onHide: () => void;
    toast: any;
}) {
    const dispatch = useDispatch();

    const { insertWarehouseData, insertWarehouseError } = useSelector(
        (state: any) => ({
            insertWarehouseData: state.Warehouse.insertWarehouseData,
            insertWarehouseError: state.Warehouse.insertWarehouseError,
        }),
        shallowEqual
    );

    // Validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Tên kho hàng là bắt buộc"),
        code: Yup.string().required("Mã kho hàng là bắt buộc"),
        address: Yup.string().required("Địa chỉ là bắt buộc"),
        area: Yup.string().required("Thành phố là bắt buộc"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            code: "",
            address: "",
            area: "",
            description: "",
        },
        validationSchema,
        onSubmit: async (values: any) => {
            dispatch(insertWarehouse(values));
            onHide();
        },
    });

    const handleClick = () => {
        formik.handleSubmit();
    };

    useEffect(() => {
        if (insertWarehouseData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Thêm kho hàng thành công",
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setWarehouseDataNull());
        }
        if (insertWarehouseError) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: insertWarehouseError?.response?.data?.message,
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setWarehouseDataNull());
        }
    }, [insertWarehouseData, insertWarehouseError, dispatch]);

    const header = <div>Thêm mới kho hàng</div>;

    const cities = [
        { label: "Hồ Chí Minh", value: "HO_CHI_MINH" },
        { label: "Hà Nội", value: "HA_NOI" },
        { label: "Đà Nẵng", value: "DA_NANG" },
        { label: "Cần Thơ", value: "CAN_THO" },
    ];

    return (
        <Dialog
            visible={visible}
            onHide={() => {
                formik.resetForm();
                onHide();
            }}
            header={header}
            // style={{ width: "70%" }}
            className="w-9 md:w-4"
        >
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-column gap-1 flex-1 mb-3">
                        <label htmlFor="name">Tên kho hàng</label>
                        <InputText
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.name && formik.errors.name
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.name && formik.errors.name ? (
                            <small className="p-error">
                                {String(formik.errors.name)}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column gap-1 flex-1 mb-3">
                        <label htmlFor="code">Mã kho hàng</label>
                        <InputText
                            id="code"
                            name="code"
                            value={formik.values.code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                                formik.touched.code && formik.errors.code
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.code && formik.errors.code ? (
                            <small className="p-error">
                                {String(formik.errors.code)}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column gap-1 flex-1 mb-3">
                        <label htmlFor="address">Địa chỉ</label>
                        <InputText
                            id="address"
                            name="address"
                            type="text"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                                formik.touched.address && formik.errors.address
                                    ? "p-invalid"
                                    : ""
                            }
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <small className="p-error">
                                {String(formik.errors.address)}
                            </small>
                        ) : null}
                    </div>
                    <div className="flex flex-column gap-1 flex-1 mb-5">
                        <label htmlFor="area">Khu vực</label>
                        <Dropdown
                            id="area"
                            name="area"
                            value={formik.values.area}
                            options={cities}
                            onChange={(e) =>
                                formik.setFieldValue("area", e.value)
                            }
                            onBlur={formik.handleBlur}
                            placeholder="Chọn khu vực"
                            optionLabel="label"
                            optionValue="value"
                            className={
                                formik.touched.area && formik.errors.area
                                    ? "p-invalid"
                                    : ""
                            }
                        />
                        {formik.touched.area && formik.errors.area ? (
                            <small className="p-error">
                                {String(formik.errors.area)}
                            </small>
                        ) : null}
                    </div>
                    <FloatLabel>
                        <InputTextarea
                            id="description"
                            rows={4}
                            className="w-full"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <label htmlFor="description">Mô tả</label>
                    </FloatLabel>
                </form>
                <div className="flex gap-2 justify-content-end mt-2">
                    <Button
                        label="Hủy"
                        severity="secondary"
                        size="small"
                        onClick={() => {
                            onHide();
                            formik.resetForm();
                        }}
                    />
                    <Button
                        type="button"
                        label="Lưu"
                        size="small"
                        onClick={handleClick}
                    />
                </div>
            </div>
        </Dialog>
    );
}
