import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getPermissionDataSuccess,
  getPermissionDataFail,
  getPermissionGroupDataSuccess,
  getPermissionGroupDataFail,
  getPermissionGroupDataByIdSuccess,
  getPermissionGroupDataByIdFail,
  insertPermissionSuccess,
  insertPermissionFail,
  updatePermissionSuccess,
  updatePermissionFail,
  deletePermissionSuccess,
  deletePermissionFail,
} from "./slice";

import {
  getAllPermission,
  getPermissionById,
  getAllPermissionGroup,
  insertPermission,
  updatePermission,
  deletePermission,
} from "../../api/backend_helper";

function* fetchPermissionDataAll(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const response = yield call(getAllPermission);
    yield put(getPermissionDataSuccess(response));
  } catch (error) {
    yield put(getPermissionDataFail(error));
  }
}

function* fetchPermissionGroupData(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const response = yield call(getAllPermissionGroup);
    yield put(getPermissionGroupDataSuccess(response));
  } catch (error) {
    yield put(getPermissionGroupDataFail(error));
  }
}

function* fetchPermissionGroupDataById(
  action: PayloadAction<{ id: number }>
): Generator<any, void, any> {
  try {
    const { id } = action.payload;
    const response = yield call(getPermissionById, id);
    yield put(getPermissionGroupDataByIdSuccess(response));
  } catch (error) {
    yield put(getPermissionGroupDataByIdFail(error));
  }
}

function* insertPermissionSaga(
  action: PayloadAction<{ name: string; permission_ids: number[] }>
): Generator<any, void, any> {
  try {
    const { name, permission_ids } = action.payload;
    const data = yield call(insertPermission, name, permission_ids);
    yield put(insertPermissionSuccess(data));
  } catch (error) {
    yield put(insertPermissionFail(error));
  }
}

function* updatePermissionSaga(
  action: PayloadAction<{ id: number; name: string; permission_ids: number[] }>
): Generator<any, void, any> {
  try {
    const { id, name, permission_ids } = action.payload;
    const response = yield call(updatePermission, id, name, permission_ids);
    yield put(updatePermissionSuccess(response));
  } catch (error) {
    yield put(updatePermissionFail(error));
  }
}

function* deletePermissionSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deletePermission, action.payload);
    yield put(deletePermissionSuccess(data));
  } catch (error) {
    yield put(deletePermissionFail(error));
  }
}

function* PermissionSaga(): Generator<any, void, any> {
  yield takeLatest("permission/getPermissionData", fetchPermissionDataAll);
  yield takeLatest(
    "permission/getPermissionGroupData",
    fetchPermissionGroupData
  );
  yield takeLatest(
    "permission/getPermissionGroupDataById",
    fetchPermissionGroupDataById
  );
  yield takeLatest("permission/insertPermission", insertPermissionSaga);
  yield takeLatest("permission/updatePermission", updatePermissionSaga);
  yield takeLatest("permission/deletePermission", deletePermissionSaga);
}

export default PermissionSaga;
