import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { changeDateFormat } from "../Plane";

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

interface Transit {
  createdAt: any;
  package_info: {
    carton_no: string;
    shopee_po_id: string;
    name: string;
    contact: string;
  };
}

// (pdfMake as any).fonts = {
//   notoSansSC: {
//     normal: "NotoSansSC-Regular.ttf",
//     bold: "NotoSansSC-Bold.ttf",
//     italics: "NotoSansSC-Regular.ttf",
//     bolditalics: "NotoSansSC-Bold.ttf",

//     // normal: "Roboto-Regular.ttf",
//     // bold: "Roboto-Medium.ttf",
//     // italics: "Roboto-Italic.ttf",
//     // bolditalics: "Roboto-MediumItalic.ttf",
//   },
// };

export const exportTransitPdf = async (
  action: "print" | "save",
  dataTable: Transit[]
): Promise<void> => {
  const docDefinition: TDocumentDefinitions = {
    // pageSize: { width: 1100, height: 900 },
    // pageMargins: [20, 0, 0, 0],
    content: [
      {
        text: "Bảng kê danh sách kiện hàng đang vận chuyển",
        fontSize: 15,
        margin: [0, 20, 0, 10],
      },

      {
        table: {
          headerRows: 1,
          widths: ["auto", 130, 80, 80, 150],
          body: [
            [
              {
                text: "STT",
                bold: true,
                alignment: "center",
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 5, 0, 5],
              },
              {
                text: "Mã kiện hàng",
                bold: true,
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 5, 0, 5],
              },
              {
                text: "Shoppe PO ID",
                bold: true,
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 5, 0, 5],
              },

              {
                text: "Tên hàng",
                bold: true,
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 5, 0, 5],
              },
              {
                text: "Ngày tạo",
                bold: true,
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 5, 0, 5],
              },
            ],
            ...dataTable?.map((item, index) => [
              {
                text: index + 1,
                alignment: "center",
                margin: [0, 5, 0, 5],
              },
              {
                text: item.package_info.carton_no,
                margin: [0, 5, 0, 5],
              },
              {
                text: item.package_info.shopee_po_id,
                margin: [0, 5, 0, 5],
              },
              {
                text: item.package_info.name,
                margin: [0, 5, 0, 5],
              },
              {
                text: changeDateFormat(item.createdAt),
                margin: [0, 5, 0, 5],
              },
            ]),
          ],
        },
      },
    ],
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  if (action === "print") {
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url, "_blank");
      if (newWindow) {
        newWindow.onload = () => {
          newWindow.print();
        };
      }
    });
  } else if (action === "save") {
    pdfDocGenerator.download("transit.pdf");
  }
};
