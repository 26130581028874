import { all, fork } from "redux-saga/effects";

import AuthSaga from "./auth/saga";
import CarSaga from "./car/saga";
import WarehouseSaga from "./warehouse/saga";
import PhaseSaga from "./phase/saga";
import PlaneSaga from "./plane/saga";
import PackageSaga from "./listPackage/saga";
import PermissionSaga from "./permission/saga";
import TransitSaga from "./transit/saga";
import ShipmentSaga from "./shipment/saga";
import ContainerSaga from "./container/saga";

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(WarehouseSaga),
    fork(CarSaga),
    fork(PhaseSaga),
    fork(PlaneSaga),
    fork(PackageSaga),
    fork(PermissionSaga),
    fork(TransitSaga),
    fork(ShipmentSaga),
    fork(ContainerSaga),
  ]);
}
