import { del, get, post, put, getAddress } from "./api_helper";
import * as url from "./url_helper";

let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

let config_upload_file = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

//user
export const login = (data: any) => post(url.LOGIN, data, config);
export const logout = () => post(url.LOGOUT, config);
export const getAllUser = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALL_USER}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
export const register = (data: any) => post(`${url.REGISTER}`, data, config);
export const updateUser = (id: number, data: any) =>
  put(`${url.UPDATE_USER}?id=${id}`, data, config);
export const deleteUser = (id: number) =>
  del(`${url.DELETE_USER}/${id}`, config);
export const resetPassword = (id: number) =>
  put(`${url.RESET_PASSWORD}?id=${id}`, config);
export const uploadFile = (data: any) =>
  post(`${url.UPLOAD_FILE}`, data, config);
export const updateProfile = (data: any) =>
  put(`${url.UPDATE_PROFILE}`, data, config);
export const getInfo = () => get(url.GET_INFO);
export const changePassword = (data: any) =>
  put(`${url.CHANGE_PASSWORD}`, data, config);

//warehouse
export const getAllWarehouse = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALL_WAREHOUSE}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
export const insertWarehouse = (data: any) =>
  post(url.GET_ALL_WAREHOUSE, data, config);
export const updateWarehouse = (id: string, data: any) =>
  put(`${url.GET_ALL_WAREHOUSE}/${id}`, data, config);
export const deleteWarehouse = (id: string) =>
  del(`${url.GET_ALL_WAREHOUSE}/${id}`, config);

//car
export const getAllCar = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALL_CARS}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
export const insertCar = (data: any) => {
  return post(url.INSERT_DATA_CAR, data, config);
};
export const updateCar = (id: string, data: any) => {
  return put(`${url.UPDATE_DATA_CAR}/${id}`, data, config);
};
export const deleteCar = (id: string) => {
  return del(`${url.DELETE_DATA_CAR}/${id}`, config);
};

//plane
export const getAllPlane = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALL_PLANE}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
export const insertPlane = (data: any) => post(url.GET_ALL_PLANE, data, config);
export const updatePlane = (id: string, data: any) =>
  put(`${url.GET_ALL_PLANE}/${id}`, data, config);
export const deletePlane = (id: string) =>
  del(`${url.GET_ALL_PLANE}/${id}`, config);

//phase
export const getAllPhase = (page: number, limit: number, query: any) => {
  return get(`${url.GET_ALL_PHASE}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
};
export const insertPhase = (data: any) => {
  return post(url.INSERT_DATA_PHASE, data, config);
};
export const updatePhase = (id: string, data: any) => {
  return put(`${url.UPDATE_DATA_PHASE}/${id}`, data, config);
};

export const deletePhase = (id: string) => {
  return del(`${url.DELETE_DATA_PHASE}/${id}`, config);
};

//package
export const getAllPackage = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALL_PACKAGE}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });
export const getAllPlan = (start_date: string, end_date: string, query: any) =>
  get(`${url.GET_ALL_PLAN}?start_date=${start_date}&end_date=${end_date}`, {
    params: query,
    ...config,
  });
export const insertPackage = (data: any) =>
  post(url.GET_ALL_PACKAGE, data, config);
export const updatePackage = (id: string, data: any) =>
  put(`${url.GET_ALL_PACKAGE}/${id}`, data, config);
export const deletePackage = (id: string) =>
  del(`${url.GET_ALL_PACKAGE}/${id}`, config);

// permission
export const getAllPermission = () => {
  return get(url.GET_ALL_PERMISSION, config);
};
export const getPermissionById = (id: number) => {
  return get(`${url.GET_PERMISSION_BY_ID}/${id}`, {
    ...config,
  });
};
export const getAllPermissionGroup = () => {
  return get(url.GET_ALL_PERMISSION_GROUP, config);
};
export const insertPermission = (name: string, permission_ids: number[]) => {
  return post(url.INSERT_PERMISSION, { name, permission_ids }, config);
};
export const updatePermission = (
  id: number,
  name: string,
  permission_ids: number[]
) => put(`${url.UPDATE_PERMISSION}/${id}`, { name, permission_ids }, config);
export const deletePermission = (id: number) =>
  del(`${url.DELETE_PERMISSION}/${id}`, config);

//transit
export const getAllTransit = (page: number, limit: number, query: any) =>
  get(`${url.GET_ALl_TRANSIT}?page=${page}&limit=${limit}`, {
    params: query,
    ...config,
  });

//shipment
export const getShipmentByPhase = (trip_id: number) =>
  get(`${url.GET_SHIPMENT_ON_PHASE}${trip_id}`, {
    ...config,
  });
export const getListScan = (
  shipment_id: number,
  s_global: string,
  query: any
) =>
  get(`${url.GET_LIST_SCAN}?shipment_id=${shipment_id}&s_global=${s_global}`, {
    params: query,
    ...config,
  });
