import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getShipmentByPhaseDataSuccess,
  getShipmentByPhaseDataFail,
  getListScanSuccess,
  getListScanFail,
} from "./slice";

import { getShipmentByPhase, getListScan } from "../../api/backend_helper";

function* fetchShipmentByPhaseById(
  action: PayloadAction<{ tripId: number }>
): Generator<any, void, any> {
  try {
    const { tripId } = action.payload;
    const response = yield call(getShipmentByPhase, tripId);
    yield put(getShipmentByPhaseDataSuccess(response));
  } catch (error) {
    yield put(getShipmentByPhaseDataFail(error));
  }
}

function* fetchListScanData(
  action: PayloadAction<{ shipment_id: number; s_global: string; query: {} }>
): Generator<any, void, any> {
  try {
    const { shipment_id, s_global, query } = action.payload;
    const response = yield call(getListScan, shipment_id, s_global, query);
    yield put(getListScanSuccess(response));
  } catch (error) {
    yield put(getListScanFail(error));
  }
}

function* ShipmentSaga(): Generator<any, void, any> {
  yield takeLatest("shipment/getShipmentByPhaseData", fetchShipmentByPhaseById);
  yield takeLatest("shipment/getListScanData", fetchListScanData);
}

export default ShipmentSaga;
