import Statistical from "../pages/Statistical";
import Report from "../pages/Statistical/Report";
import Warehouse from "../pages/Warehouse";
import Car from "../pages/Car";
import Plane from "../pages/Plane";
import Permission from "../pages/Permission";
import Login from "../pages/Authentication/Login";
import Phase from "../pages/Phase";
import Package from "../pages/ListPackage";
import Transit from "../pages/Transit";
import User from "../pages/User";
import DetailTransit from "../pages/Transit/detailTransit";
import Profile from "../pages/Profile";
import ChangePassword from "../pages/Authentication/ChangePassword";
// import License from "../pages/Authentication/License";
import { BlankPage } from "../pages/BlankPage";
import Container from "../pages/Container";
// import Register from "../pages/Authentication/Register";
// import ForgetPassword from "../pages/Authentication/ForgetPassword";

//Public Routes
const publicRoutes = [
  // { path: "/", component: Report },
  { path: "/login", component: Login },
  // { path: "/forget-password", component: ForgetPassword },
  // { path: "/register", component: Register },
  // { path: "/license", component: License },
  { path: "/blankpage", component: BlankPage },
];

//Private Routes
const privateRoutes = [
  { path: "/", component: Statistical },
  { path: "/report", component: Report },
  { path: "/warehouse", component: Warehouse },
  { path: "/car", component: Car },
  { path: "/plane", component: Plane },
  { path: "/phase", component: Phase },
  { path: "/permission", component: Permission },
  { path: "/package", component: Package },
  { path: "/transit", component: Transit },
  { path: "/container", component: Container },
  { path: "/user", component: User },
  { path: "/detail-transit", component: DetailTransit },
  { path: "/profile", component: Profile },
  { path: "/change-password", component: ChangePassword },
];

export { publicRoutes, privateRoutes };
