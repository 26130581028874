import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PermissionState {
  permissionData: any | null;
  permissionGroupData: any | null;
  permissionGroupDataById: any | null;
  error: string | null;
  loading: boolean;
  insertPermissionData: any | null;
  updatePermissionData: any | null;
  deletePermissionData: any | null;
  insertPermissionError: string | null;
  updatePermissionError: string | null;
  deletePermissionError: string | null;
}

const initialState: PermissionState = {
  permissionData: null,
  permissionGroupData: null,
  permissionGroupDataById: null,
  error: null,
  loading: false,
  insertPermissionData: null,
  updatePermissionData: null,
  deletePermissionData: null,
  insertPermissionError: null,
  updatePermissionError: null,
  deletePermissionError: null,
};

const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    getPermissionData: (state) => {
      state.loading = true;
    },
    getPermissionDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionData = action.payload;
      state.error = null;
    },
    getPermissionDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionData = null;
      state.error = action.payload;
    },
    getPermissionGroupData: (state) => {
      state.loading = true;
    },
    getPermissionGroupDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionGroupData = action.payload;
      state.error = null;
    },
    getPermissionGroupDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionGroupData = null;
      state.error = action.payload;
    },
    getPermissionGroupDataById: (
      state,
      action: PayloadAction<{ id: number }>
    ) => {
      state.loading = true;
    },
    getPermissionGroupDataByIdSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionGroupDataById = action.payload;
      state.error = null;
    },
    getPermissionGroupDataByIdFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.permissionGroupDataById = null;
      state.error = action.payload;
    },
    insertPermission: (
      state,
      action: PayloadAction<{
        name: string;
        permission_ids: number[];
      }>
    ) => {
      state.loading = true;
    },
    insertPermissionSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertPermissionData = action.payload;
      state.insertPermissionError = null;
    },
    insertPermissionFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertPermissionData = null;
      state.insertPermissionError = action.payload;
    },
    updatePermission: (
      state,
      action: PayloadAction<{
        id: string;
        name: string;
        permission_ids: number[];
      }>
    ) => {
      state.loading = true;
    },
    updatePermissionSuccess: (state, action) => {
      state.loading = false;
      state.updatePermissionData = action.payload;
      state.updatePermissionError = null;
    },
    updatePermissionFail: (state, action) => {
      state.loading = false;
      state.updatePermissionData = null;
      state.updatePermissionError = action.payload;
    },
    deletePermission: (state) => {
      state.loading = true;
    },
    deletePermissionSuccess: (state, action) => {
      state.loading = false;
      state.deletePermissionData = action.payload;
      state.deletePermissionError = null;
    },
    deletePermissionFail: (state, action) => {
      state.loading = false;
      state.deletePermissionData = null;
      state.deletePermissionError = action.payload;
    },
    setPermissionDataNull: (state) => {
      // state.permissionData = null;
      state.permissionGroupDataById = null;
      state.loading = false;
      state.insertPermissionData = null;
      state.updatePermissionData = null;
      state.deletePermissionData = null;
      state.insertPermissionError = null;
      state.updatePermissionError = null;
      state.deletePermissionError = null;
    },
  },
});

export const {
  getPermissionData,
  getPermissionDataSuccess,
  getPermissionDataFail,
  getPermissionGroupData,
  getPermissionGroupDataSuccess,
  getPermissionGroupDataFail,
  getPermissionGroupDataById,
  getPermissionGroupDataByIdSuccess,
  getPermissionGroupDataByIdFail,
  insertPermission,
  insertPermissionSuccess,
  insertPermissionFail,
  updatePermission,
  updatePermissionSuccess,
  updatePermissionFail,
  deletePermission,
  deletePermissionSuccess,
  deletePermissionFail,
  setPermissionDataNull,
} = permissionSlice.actions;

export default permissionSlice.reducer;
