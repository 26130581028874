import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import store from "./store/index";
import "./locales/setupLocales";
import { DataProvider } from "./contexts/data/DataProvider";

// Performance metrics callback
const handlePerformanceMetrics = (metric: any) => {
    // console.log(metric); // Replace this with your analytics service if needed
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <DataProvider>
            {/* <I18nextProvider i18n={i18n}> */}
            <App />
            {/* </I18nextProvider> */}
        </DataProvider>
    </Provider>
    // </React.StrictMode>
);

// Provide a callback function to reportWebVitals
reportWebVitals(handlePerformanceMetrics);
