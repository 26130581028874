const IconReport = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3823_1793)">
      <path
        d="M5.83398 15L5.83398 12.5"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 15V10"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.166 15V7.5"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.3327 10.0003C18.3327 13.9287 18.3327 15.8929 17.1123 17.1133C15.8919 18.3337 13.9277 18.3337 9.99935 18.3337C6.07098 18.3337 4.10679 18.3337 2.8864 17.1133C1.66602 15.8929 1.66602 13.9287 1.66602 10.0003C1.66602 6.07195 1.66602 4.10777 2.8864 2.88738C4.10679 1.66699 6.07098 1.66699 9.99935 1.66699C13.9277 1.66699 15.8919 1.66699 17.1123 2.88738C17.9237 3.69883 18.1957 4.83912 18.2868 6.66699"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3823_1793">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const IconReportClick = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3823_1793)">
      <path
        d="M5.83398 15L5.83398 12.5"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 15V10"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.166 15V7.5"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M18.3327 10.0003C18.3327 13.9287 18.3327 15.8929 17.1123 17.1133C15.8919 18.3337 13.9277 18.3337 9.99935 18.3337C6.07098 18.3337 4.10679 18.3337 2.8864 17.1133C1.66602 15.8929 1.66602 13.9287 1.66602 10.0003C1.66602 6.07195 1.66602 4.10777 2.8864 2.88738C4.10679 1.66699 6.07098 1.66699 9.99935 1.66699C13.9277 1.66699 15.8919 1.66699 17.1123 2.88738C17.9237 3.69883 18.1957 4.83912 18.2868 6.66699"
        stroke="#FFFFFF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3823_1793">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const IconStatistical = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#555555"
      stroke="none"
    >
      <path
        d="M1941 4859 c-46 -28 -68 -56 -80 -106 -15 -59 -15 -4327 0 -4386 12
-50 34 -78 80 -106 33 -21 45 -21 618 -21 l584 0 42 25 c26 15 50 39 61 62 18
37 19 107 22 2203 2 1547 0 2179 -8 2215 -12 57 -32 84 -81 114 -33 21 -45 21
-619 21 -574 0 -586 0 -619 -21z"
      />
      <path
        d="M3841 3800 c-40 -9 -77 -36 -105 -74 l-21 -27 0 -1679 0 -1679 21
-28 c11 -15 36 -38 54 -51 l34 -22 597 2 c589 3 598 3 626 24 15 11 38 38 50
60 l23 39 0 1652 c0 1146 -3 1660 -11 1680 -13 35 -45 70 -84 91 -27 15 -90
17 -590 19 -308 1 -575 -2 -594 -7z"
      />
      <path
        d="M117 2716 c-48 -18 -85 -51 -103 -93 -12 -27 -14 -225 -14 -1145 l0
-1113 23 -39 c12 -22 35 -49 50 -60 28 -21 37 -21 626 -24 l597 -2 34 22 c18
13 43 36 54 51 21 28 21 30 24 1159 2 1126 2 1130 -18 1167 -12 20 -37 48 -57
61 l-37 25 -570 2 c-467 2 -578 0 -609 -11z"
      />
    </g>
  </svg>
);

const IconStatisticalClick = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M1941 4859 c-46 -28 -68 -56 -80 -106 -15 -59 -15 -4327 0 -4386 12
-50 34 -78 80 -106 33 -21 45 -21 618 -21 l584 0 42 25 c26 15 50 39 61 62 18
37 19 107 22 2203 2 1547 0 2179 -8 2215 -12 57 -32 84 -81 114 -33 21 -45 21
-619 21 -574 0 -586 0 -619 -21z"
      />
      <path
        d="M3841 3800 c-40 -9 -77 -36 -105 -74 l-21 -27 0 -1679 0 -1679 21
-28 c11 -15 36 -38 54 -51 l34 -22 597 2 c589 3 598 3 626 24 15 11 38 38 50
60 l23 39 0 1652 c0 1146 -3 1660 -11 1680 -13 35 -45 70 -84 91 -27 15 -90
17 -590 19 -308 1 -575 -2 -594 -7z"
      />
      <path
        d="M117 2716 c-48 -18 -85 -51 -103 -93 -12 -27 -14 -225 -14 -1145 l0
-1113 23 -39 c12 -22 35 -49 50 -60 28 -21 37 -21 626 -24 l597 -2 34 22 c18
13 43 36 54 51 21 28 21 30 24 1159 2 1126 2 1130 -18 1167 -12 20 -37 48 -57
61 l-37 25 -570 2 c-467 2 -578 0 -609 -11z"
      />
    </g>
  </svg>
);

const IconPackage = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6277 5.8L9.31384 1L1 5.8V15.4L9.31384 20.2M17.6277 5.8L9.31384 11.2M17.6277 5.8V10.6M9.31384 20.2V11.2M9.31384 20.2L11.1133 19.1611M9.31384 11.2L1.51384 6.4M12.9138 8.2L5.11384 3.4M14.1138 16.6L15.3138 17.8L18.9138 14.2"
      stroke="#555555"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconPackageClick = (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6277 5.8L9.31384 1L1 5.8V15.4L9.31384 20.2M17.6277 5.8L9.31384 11.2M17.6277 5.8V10.6M9.31384 20.2V11.2M9.31384 20.2L11.1133 19.1611M9.31384 11.2L1.51384 6.4M12.9138 8.2L5.11384 3.4M14.1138 16.6L15.3138 17.8L18.9138 14.2"
      stroke="#FFFFFF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconTransit = (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 1.45455V0.954545H14.25H0.5V0.5H15.25V2.90909V3.40909H15.75H21.927L21.9773 3.56359L21.9773 3.56361L21.9799 3.57127L23.4799 7.9349L23.4849 7.94939L23.4907 7.96355L23.5 7.98591V13.3182H21.633H21.2492L21.15 13.6889C20.8736 14.7218 19.9113 15.5 18.75 15.5C17.5887 15.5 16.6264 14.7218 16.35 13.6889L16.2508 13.3182H15.867H9.633H9.24919L9.14999 13.6889C8.87365 14.7218 7.91128 15.5 6.75 15.5C5.58872 15.5 4.62635 14.7218 4.35001 13.6889L4.25081 13.3182H3.867H2V9.22727H2.5V12.3636V12.8636H3H3.867H4.25081L4.35001 12.4929C4.62635 11.4601 5.58872 10.6818 6.75 10.6818C7.91128 10.6818 8.87365 11.4601 9.14999 12.4929L9.24919 12.8636H9.633H14.25H14.75V12.3636V1.45455ZM15.75 3.86364H15.25V4.36364V12.3636V12.8636H15.75H15.867H16.2508L16.35 12.4929C16.6264 11.4601 17.5887 10.6818 18.75 10.6818C19.9113 10.6818 20.8736 11.4601 21.15 12.4929L21.2492 12.8636H21.633H22.5H23V12.3636V8.09091V8.00688L22.9725 7.92746L21.6833 4.20019L21.5669 3.86364H21.2108H15.75ZM7 3.86364H1.25V3.40909H7V3.86364ZM5.5 6.77273H2V6.31818H5.5V6.77273ZM8.75 13.0909C8.75 11.988 7.84928 11.1364 6.75 11.1364C5.65072 11.1364 4.75 11.988 4.75 13.0909C4.75 14.1938 5.65072 15.0455 6.75 15.0455C7.84928 15.0455 8.75 14.1938 8.75 13.0909ZM20.75 13.0909C20.75 11.988 19.8493 11.1364 18.75 11.1364C17.6507 11.1364 16.75 11.988 16.75 13.0909C16.75 14.1938 17.6507 15.0455 18.75 15.0455C19.8493 15.0455 20.75 14.1938 20.75 13.0909Z"
      fill="white"
      stroke="#555555"
    />
  </svg>
);
const IconTransitClick = (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.75 1.45455V0.954545H14.25H0.5V0.5H15.25V2.90909V3.40909H15.75H21.927L21.9773 3.56359L21.9773 3.56361L21.9799 3.57127L23.4799 7.9349L23.4849 7.94939L23.4907 7.96355L23.5 7.98591V13.3182H21.633H21.2492L21.15 13.6889C20.8736 14.7218 19.9113 15.5 18.75 15.5C17.5887 15.5 16.6264 14.7218 16.35 13.6889L16.2508 13.3182H15.867H9.633H9.24919L9.14999 13.6889C8.87365 14.7218 7.91128 15.5 6.75 15.5C5.58872 15.5 4.62635 14.7218 4.35001 13.6889L4.25081 13.3182H3.867H2V9.22727H2.5V12.3636V12.8636H3H3.867H4.25081L4.35001 12.4929C4.62635 11.4601 5.58872 10.6818 6.75 10.6818C7.91128 10.6818 8.87365 11.4601 9.14999 12.4929L9.24919 12.8636H9.633H14.25H14.75V12.3636V1.45455ZM15.75 3.86364H15.25V4.36364V12.3636V12.8636H15.75H15.867H16.2508L16.35 12.4929C16.6264 11.4601 17.5887 10.6818 18.75 10.6818C19.9113 10.6818 20.8736 11.4601 21.15 12.4929L21.2492 12.8636H21.633H22.5H23V12.3636V8.09091V8.00688L22.9725 7.92746L21.6833 4.20019L21.5669 3.86364H21.2108H15.75ZM7 3.86364H1.25V3.40909H7V3.86364ZM5.5 6.77273H2V6.31818H5.5V6.77273ZM8.75 13.0909C8.75 11.988 7.84928 11.1364 6.75 11.1364C5.65072 11.1364 4.75 11.988 4.75 13.0909C4.75 14.1938 5.65072 15.0455 6.75 15.0455C7.84928 15.0455 8.75 14.1938 8.75 13.0909ZM20.75 13.0909C20.75 11.988 19.8493 11.1364 18.75 11.1364C17.6507 11.1364 16.75 11.988 16.75 13.0909C16.75 14.1938 17.6507 15.0455 18.75 15.0455C19.8493 15.0455 20.75 14.1938 20.75 13.0909Z"
      fill="white"
      stroke="#FFFFFF"
    />
  </svg>
);
const IconWarehouse = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3859_1718)">
      <path
        d="M17.4173 17.417L1.58398 17.417"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.6242 17.4163V4.74967C16.6242 3.25689 16.6242 2.5105 16.1604 2.04676C15.6967 1.58301 14.9503 1.58301 13.4575 1.58301H11.8742C10.3814 1.58301 9.63499 1.58301 9.17125 2.04676C8.79794 2.42006 8.72514 2.97651 8.71094 3.95801"
        stroke="#555555"
        strokeWidth="1.5"
      />
      <path
        d="M11.875 17.4163V7.12467C11.875 5.63189 11.875 4.8855 11.4113 4.42176C10.9475 3.95801 10.2011 3.95801 8.70833 3.95801H5.54167C4.04889 3.95801 3.3025 3.95801 2.83875 4.42176C2.375 4.8855 2.375 5.63189 2.375 7.12467V17.4163"
        stroke="#555555"
        strokeWidth="1.5"
      />
      <path
        d="M7.125 17.417V15.042"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 6.33301H9.5"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 8.70801H9.5"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 11.083H9.5"
        stroke="#555555"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3859_1718">
        <rect width="19" height="19" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const IconWarehouseClick = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3859_1718)">
      <path
        d="M17.4173 17.417L1.58398 17.417"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.6242 17.4163V4.74967C16.6242 3.25689 16.6242 2.5105 16.1604 2.04676C15.6967 1.58301 14.9503 1.58301 13.4575 1.58301H11.8742C10.3814 1.58301 9.63499 1.58301 9.17125 2.04676C8.79794 2.42006 8.72514 2.97651 8.71094 3.95801"
        stroke="#ffffff"
        strokeWidth="1.5"
      />
      <path
        d="M11.875 17.4163V7.12467C11.875 5.63189 11.875 4.8855 11.4113 4.42176C10.9475 3.95801 10.2011 3.95801 8.70833 3.95801H5.54167C4.04889 3.95801 3.3025 3.95801 2.83875 4.42176C2.375 4.8855 2.375 5.63189 2.375 7.12467V17.4163"
        stroke="#ffffff"
        strokeWidth="1.5"
      />
      <path
        d="M7.125 17.417V15.042"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 6.33301H9.5"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 8.70801H9.5"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 11.083H9.5"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3859_1718">
        <rect width="19" height="19" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconContainer = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#555555"
      stroke="none"
    >
      <path
        d="M735 3894 c-354 -89 -657 -167 -673 -173 -15 -7 -36 -25 -45 -40 -16
-27 -17 -120 -17 -1226 0 -1285 -2 -1237 49 -1264 10 -6 315 -84 677 -175
l659 -165 265 25 c146 13 652 58 1125 99 473 41 932 82 1020 90 88 8 396 35
685 60 289 25 542 50 563 56 26 7 44 21 57 42 19 31 20 60 20 1230 0 1101 -1
1201 -17 1227 -31 53 198 29 -2773 291 -195 17 -489 43 -653 58 l-297 26 -645
-161z m2461 -214 c935 -82 1702 -150 1707 -150 4 0 7 -484 7 -1075 l0 -1074
-32 -5 c-37 -6 -3344 -296 -3370 -296 -17 0 -18 72 -18 1375 0 756 2 1375 4
1375 2 0 768 -67 1702 -150z m-1916 -1226 l0 -1356 -27 7 c-16 3 -111 27 -213
53 l-185 47 0 1249 0 1250 205 52 c113 29 208 53 213 53 4 1 7 -609 7 -1355z
m-640 0 c0 -1134 -1 -1196 -17 -1192 -10 3 -107 27 -216 54 l-197 49 2 1089 3
1090 205 52 c113 29 208 53 213 53 4 1 7 -537 7 -1195z"
      />
      <path
        d="M1983 3615 c-67 -29 -64 43 -61 -1174 l3 -1099 24 -26 c48 -51 142
-40 170 20 8 18 11 335 11 1122 0 1218 5 1128 -66 1158 -40 17 -41 17 -81 -1z"
      />
      <path
        d="M2824 3501 c-17 -11 -36 -34 -43 -52 -8 -23 -11 -305 -11 -996 0
-1037 -1 -1010 51 -1047 30 -21 88 -21 118 0 52 37 51 9 51 1049 0 1058 2
1018 -59 1049 -40 21 -69 20 -107 -3z"
      />
      <path
        d="M3659 3381 l-29 -29 0 -895 c0 -881 0 -895 20 -920 30 -38 80 -52
123 -33 71 29 67 -22 67 951 0 800 -1 879 -17 905 -34 58 -116 68 -164 21z"
      />
      <path
        d="M4543 3295 c-66 -29 -64 9 -61 -854 l3 -779 24 -26 c48 -51 142 -40
170 20 8 18 11 251 11 802 0 863 4 809 -66 838 -40 17 -41 17 -81 -1z"
      />
    </g>
  </svg>
);
const IconContainerClick = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M735 3894 c-354 -89 -657 -167 -673 -173 -15 -7 -36 -25 -45 -40 -16
  -27 -17 -120 -17 -1226 0 -1285 -2 -1237 49 -1264 10 -6 315 -84 677 -175
  l659 -165 265 25 c146 13 652 58 1125 99 473 41 932 82 1020 90 88 8 396 35
  685 60 289 25 542 50 563 56 26 7 44 21 57 42 19 31 20 60 20 1230 0 1101 -1
  1201 -17 1227 -31 53 198 29 -2773 291 -195 17 -489 43 -653 58 l-297 26 -645
  -161z m2461 -214 c935 -82 1702 -150 1707 -150 4 0 7 -484 7 -1075 l0 -1074
  -32 -5 c-37 -6 -3344 -296 -3370 -296 -17 0 -18 72 -18 1375 0 756 2 1375 4
  1375 2 0 768 -67 1702 -150z m-1916 -1226 l0 -1356 -27 7 c-16 3 -111 27 -213
  53 l-185 47 0 1249 0 1250 205 52 c113 29 208 53 213 53 4 1 7 -609 7 -1355z
  m-640 0 c0 -1134 -1 -1196 -17 -1192 -10 3 -107 27 -216 54 l-197 49 2 1089 3
  1090 205 52 c113 29 208 53 213 53 4 1 7 -537 7 -1195z"
      />
      <path
        d="M1983 3615 c-67 -29 -64 43 -61 -1174 l3 -1099 24 -26 c48 -51 142
  -40 170 20 8 18 11 335 11 1122 0 1218 5 1128 -66 1158 -40 17 -41 17 -81 -1z"
      />
      <path
        d="M2824 3501 c-17 -11 -36 -34 -43 -52 -8 -23 -11 -305 -11 -996 0
  -1037 -1 -1010 51 -1047 30 -21 88 -21 118 0 52 37 51 9 51 1049 0 1058 2
  1018 -59 1049 -40 21 -69 20 -107 -3z"
      />
      <path
        d="M3659 3381 l-29 -29 0 -895 c0 -881 0 -895 20 -920 30 -38 80 -52
  123 -33 71 29 67 -22 67 951 0 800 -1 879 -17 905 -34 58 -116 68 -164 21z"
      />
      <path
        d="M4543 3295 c-66 -29 -64 9 -61 -854 l3 -779 24 -26 c48 -51 142 -40
  170 20 8 18 11 251 11 802 0 863 4 809 -66 838 -40 17 -41 17 -81 -1z"
      />
    </g>
  </svg>
);
const IconCar = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.72 3.22439C13.5804 2.98073 13.3799 2.77812 13.1385 2.63671C12.8971 2.49529 12.6232 2.41999 12.344 2.4183H10.4V0.806098C10.4 0.592308 10.3157 0.387274 10.1657 0.236101C10.0157 0.0849281 9.81217 0 9.6 0H1.6C1.17565 0 0.768687 0.169856 0.468629 0.472201C0.168571 0.774547 0 1.18462 0 1.6122V9.67318C0.000287794 9.9559 0.0743542 10.2336 0.214769 10.4783C0.355184 10.7231 0.55701 10.9264 0.8 11.0677C0.771357 11.816 1.03889 12.5451 1.54373 13.0946C2.04858 13.6441 2.74939 13.969 3.492 13.9979C4.23461 14.0268 4.95818 13.7572 5.50353 13.2485C6.04889 12.7398 6.37136 12.0336 6.4 11.2854H8.88C8.96846 11.9598 9.29717 12.5789 9.80491 13.0274C10.3126 13.4758 10.9648 13.7231 11.64 13.7231C12.3152 13.7231 12.9674 13.4758 13.4751 13.0274C13.9828 12.5789 14.3115 11.9598 14.4 11.2854C14.8243 11.2854 15.2313 11.1155 15.5314 10.8132C15.8314 10.5108 16 10.1008 16 9.67318V7.25489C15.9977 7.10797 15.9592 6.96393 15.888 6.83571L13.72 3.22439ZM10.4 4.03049H12.344L13.784 6.44879H10.4V4.03049ZM3.6 12.0915C3.36266 12.0915 3.13065 12.0206 2.93332 11.8877C2.73598 11.7548 2.58217 11.566 2.49134 11.345C2.40052 11.1241 2.37676 10.881 2.42306 10.6464C2.46936 10.4119 2.58365 10.1964 2.75147 10.0273C2.91929 9.85823 3.13311 9.74307 3.36589 9.69641C3.59867 9.64976 3.83995 9.67371 4.05922 9.76522C4.27849 9.85674 4.46591 10.0117 4.59776 10.2106C4.72962 10.4094 4.8 10.6432 4.8 10.8823C4.8 11.203 4.67357 11.5106 4.44853 11.7373C4.22348 11.9641 3.91826 12.0915 3.6 12.0915ZM11.6 12.0915C11.3627 12.0915 11.1307 12.0206 10.9333 11.8877C10.736 11.7548 10.5822 11.566 10.4913 11.345C10.4005 11.1241 10.3768 10.881 10.4231 10.6464C10.4694 10.4119 10.5836 10.1964 10.7515 10.0273C10.9193 9.85823 11.1331 9.74307 11.3659 9.69641C11.5987 9.64976 11.8399 9.67371 12.0592 9.76522C12.2785 9.85674 12.4659 10.0117 12.5978 10.2106C12.7296 10.4094 12.8 10.6432 12.8 10.8823C12.8 11.203 12.6736 11.5106 12.4485 11.7373C12.2235 11.9641 11.9183 12.0915 11.6 12.0915Z"
      fill="#555555"
    />
  </svg>
);
const IconCarClick = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.72 3.22439C13.5804 2.98073 13.3799 2.77812 13.1385 2.63671C12.8971 2.49529 12.6232 2.41999 12.344 2.4183H10.4V0.806098C10.4 0.592308 10.3157 0.387274 10.1657 0.236101C10.0157 0.0849281 9.81217 0 9.6 0H1.6C1.17565 0 0.768687 0.169856 0.468629 0.472201C0.168571 0.774547 0 1.18462 0 1.6122V9.67318C0.000287794 9.9559 0.0743542 10.2336 0.214769 10.4783C0.355184 10.7231 0.55701 10.9264 0.8 11.0677C0.771357 11.816 1.03889 12.5451 1.54373 13.0946C2.04858 13.6441 2.74939 13.969 3.492 13.9979C4.23461 14.0268 4.95818 13.7572 5.50353 13.2485C6.04889 12.7398 6.37136 12.0336 6.4 11.2854H8.88C8.96846 11.9598 9.29717 12.5789 9.80491 13.0274C10.3126 13.4758 10.9648 13.7231 11.64 13.7231C12.3152 13.7231 12.9674 13.4758 13.4751 13.0274C13.9828 12.5789 14.3115 11.9598 14.4 11.2854C14.8243 11.2854 15.2313 11.1155 15.5314 10.8132C15.8314 10.5108 16 10.1008 16 9.67318V7.25489C15.9977 7.10797 15.9592 6.96393 15.888 6.83571L13.72 3.22439ZM10.4 4.03049H12.344L13.784 6.44879H10.4V4.03049ZM3.6 12.0915C3.36266 12.0915 3.13065 12.0206 2.93332 11.8877C2.73598 11.7548 2.58217 11.566 2.49134 11.345C2.40052 11.1241 2.37676 10.881 2.42306 10.6464C2.46936 10.4119 2.58365 10.1964 2.75147 10.0273C2.91929 9.85823 3.13311 9.74307 3.36589 9.69641C3.59867 9.64976 3.83995 9.67371 4.05922 9.76522C4.27849 9.85674 4.46591 10.0117 4.59776 10.2106C4.72962 10.4094 4.8 10.6432 4.8 10.8823C4.8 11.203 4.67357 11.5106 4.44853 11.7373C4.22348 11.9641 3.91826 12.0915 3.6 12.0915ZM11.6 12.0915C11.3627 12.0915 11.1307 12.0206 10.9333 11.8877C10.736 11.7548 10.5822 11.566 10.4913 11.345C10.4005 11.1241 10.3768 10.881 10.4231 10.6464C10.4694 10.4119 10.5836 10.1964 10.7515 10.0273C10.9193 9.85823 11.1331 9.74307 11.3659 9.69641C11.5987 9.64976 11.8399 9.67371 12.0592 9.76522C12.2785 9.85674 12.4659 10.0117 12.5978 10.2106C12.7296 10.4094 12.8 10.6432 12.8 10.8823C12.8 11.203 12.6736 11.5106 12.4485 11.7373C12.2235 11.9641 11.9183 12.0915 11.6 12.0915Z"
      fill="#ffffff"
    />
  </svg>
);
const IconPlane = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04139 9.90673L6.41184 12.2459L6.81544 14.0981L4.9136 16L2.99724 13.0027L0 11.0862L1.90184 9.18434L3.8549 9.68887L6.09232 6.95756L1.37517 3.8127L3.53691 1.65012L9.43506 3.617L12.3345 0.717521C13.2296 -0.178391 14.6162 -0.244132 15.4303 0.569986C16.2444 1.3841 16.1779 2.77001 15.2828 3.66593L12.3834 6.56541L14.3487 12.4615L12.1869 14.624L9.04139 9.90673Z"
      fill="#555555"
    />
  </svg>
);
const IconPlaneClick = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04139 9.90673L6.41184 12.2459L6.81544 14.0981L4.9136 16L2.99724 13.0027L0 11.0862L1.90184 9.18434L3.8549 9.68887L6.09232 6.95756L1.37517 3.8127L3.53691 1.65012L9.43506 3.617L12.3345 0.717521C13.2296 -0.178391 14.6162 -0.244132 15.4303 0.569986C16.2444 1.3841 16.1779 2.77001 15.2828 3.66593L12.3834 6.56541L14.3487 12.4615L12.1869 14.624L9.04139 9.90673Z"
      fill="#ffffff"
    />
  </svg>
);
const IconUser = (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.91797 1.60547C4.86328 0.660156 5.99479 0.1875 7.3125 0.1875C8.63021 0.1875 9.76172 0.660156 10.707 1.60547C11.6523 2.55078 12.125 3.68229 12.125 5C12.125 5.80208 11.9245 6.5612 11.5234 7.27734C11.151 7.99349 10.6354 8.56641 9.97656 8.99609C11.237 9.54036 12.2539 10.3854 13.0273 11.5312C13.8008 12.6484 14.1875 13.9089 14.1875 15.3125H12.8125C12.8125 13.7943 12.2682 12.5052 11.1797 11.4453C10.1198 10.3568 8.83073 9.8125 7.3125 9.8125C5.79427 9.8125 4.49089 10.3568 3.40234 11.4453C2.34245 12.5052 1.8125 13.7943 1.8125 15.3125H0.4375C0.4375 13.9089 0.824219 12.6484 1.59766 11.5312C2.37109 10.3854 3.38802 9.54036 4.64844 8.99609C3.98958 8.56641 3.45964 7.99349 3.05859 7.27734C2.6862 6.5612 2.5 5.80208 2.5 5C2.5 3.68229 2.97266 2.55078 3.91797 1.60547ZM9.71875 2.59375C9.0599 1.90625 8.25781 1.5625 7.3125 1.5625C6.36719 1.5625 5.55078 1.90625 4.86328 2.59375C4.20443 3.2526 3.875 4.05469 3.875 5C3.875 5.94531 4.20443 6.76172 4.86328 7.44922C5.55078 8.10807 6.36719 8.4375 7.3125 8.4375C8.25781 8.4375 9.0599 8.10807 9.71875 7.44922C10.4062 6.76172 10.75 5.94531 10.75 5C10.75 4.05469 10.4062 3.2526 9.71875 2.59375Z"
      fill="#555555"
    />
  </svg>
);
const IconUserClick = (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.91797 1.60547C4.86328 0.660156 5.99479 0.1875 7.3125 0.1875C8.63021 0.1875 9.76172 0.660156 10.707 1.60547C11.6523 2.55078 12.125 3.68229 12.125 5C12.125 5.80208 11.9245 6.5612 11.5234 7.27734C11.151 7.99349 10.6354 8.56641 9.97656 8.99609C11.237 9.54036 12.2539 10.3854 13.0273 11.5312C13.8008 12.6484 14.1875 13.9089 14.1875 15.3125H12.8125C12.8125 13.7943 12.2682 12.5052 11.1797 11.4453C10.1198 10.3568 8.83073 9.8125 7.3125 9.8125C5.79427 9.8125 4.49089 10.3568 3.40234 11.4453C2.34245 12.5052 1.8125 13.7943 1.8125 15.3125H0.4375C0.4375 13.9089 0.824219 12.6484 1.59766 11.5312C2.37109 10.3854 3.38802 9.54036 4.64844 8.99609C3.98958 8.56641 3.45964 7.99349 3.05859 7.27734C2.6862 6.5612 2.5 5.80208 2.5 5C2.5 3.68229 2.97266 2.55078 3.91797 1.60547ZM9.71875 2.59375C9.0599 1.90625 8.25781 1.5625 7.3125 1.5625C6.36719 1.5625 5.55078 1.90625 4.86328 2.59375C4.20443 3.2526 3.875 4.05469 3.875 5C3.875 5.94531 4.20443 6.76172 4.86328 7.44922C5.55078 8.10807 6.36719 8.4375 7.3125 8.4375C8.25781 8.4375 9.0599 8.10807 9.71875 7.44922C10.4062 6.76172 10.75 5.94531 10.75 5C10.75 4.05469 10.4062 3.2526 9.71875 2.59375Z"
      fill="white"
    />
  </svg>
);
const IconPermission = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 0.1875H1.4375H4.1875H4.875V0.875V3.625V4.3125H4.1875H1.4375H0.75V3.625V0.875V0.1875ZM2.125 1.5625V2.9375H3.5V1.5625H2.125ZM6.25 1.5625H16.5625V2.9375H6.25V1.5625ZM0.75 5.6875H1.4375H4.1875H4.875V6.375V9.125V9.8125H4.1875H1.4375H0.75V9.125V6.375V5.6875ZM2.125 7.0625V8.4375H3.5V7.0625H2.125ZM6.25 7.0625H16.5625V8.4375H6.25V7.0625ZM0.75 11.1875H1.4375H4.1875H4.875V11.875V14.625V15.3125H4.1875H1.4375H0.75V14.625V11.875V11.1875ZM2.125 12.5625V13.9375H3.5V12.5625H2.125ZM6.25 12.5625H16.5625V13.9375H6.25V12.5625Z"
      fill="#555555"
    />
  </svg>
);
const IconPermissionClick = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 0.1875H1.4375H4.1875H4.875V0.875V3.625V4.3125H4.1875H1.4375H0.75V3.625V0.875V0.1875ZM2.125 1.5625V2.9375H3.5V1.5625H2.125ZM6.25 1.5625H16.5625V2.9375H6.25V1.5625ZM0.75 5.6875H1.4375H4.1875H4.875V6.375V9.125V9.8125H4.1875H1.4375H0.75V9.125V6.375V5.6875ZM2.125 7.0625V8.4375H3.5V7.0625H2.125ZM6.25 7.0625H16.5625V8.4375H6.25V7.0625ZM0.75 11.1875H1.4375H4.1875H4.875V11.875V14.625V15.3125H4.1875H1.4375H0.75V14.625V11.875V11.1875ZM2.125 12.5625V13.9375H3.5V12.5625H2.125ZM6.25 12.5625H16.5625V13.9375H6.25V12.5625Z"
      fill="white"
    />
  </svg>
);
const IconStartReceiving = (
  <svg
    width="15"
    height="22"
    viewBox="0 0 19 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 21L18 16V6L9.5 1L1 6V16L9.5 21ZM9.5 21V11.625M9.5 11.625L1.52535 6.625M9.5 11.625L17.4746 6.625"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconFinishedReceiving = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6133 5.84746L9.30663 1L1 5.84746V15.5424L9.30663 20.3898M17.6133 5.84746L9.30663 11.3008M17.6133 5.84746V10.6949M9.30663 20.3898V11.3008M9.30663 20.3898L11.1045 19.3407M9.30663 11.3008L1.5134 6.45339M12.9035 8.27119L5.11028 3.42373M14.1025 16.7542L15.3014 17.9661L18.8983 14.3305"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconComeWarehouse = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.185547 0.169434V1.52537H13.0266V11.6949H8.86613C8.5647 10.5294 7.51984 9.66096 6.26817 9.66096C5.0165 9.66096 3.97164 10.5294 3.67022 11.6949H2.88894V8.30503H1.53724V13.0508H3.67022C3.97164 14.2162 5.0165 15.0847 6.26817 15.0847C7.51984 15.0847 8.5647 14.2162 8.86613 13.0508H14.4838C14.7852 14.2162 15.8301 15.0847 17.0817 15.0847C18.3334 15.0847 19.3783 14.2162 19.6797 13.0508H21.8127V7.5213L21.7701 7.41486L20.4184 3.34706L20.2717 2.8813H14.3783V0.169434H0.185547ZM0.861394 2.8813V4.23723H6.94402V2.8813H0.861394ZM14.3783 4.23723H19.2992L20.461 7.71181V11.6949H19.6797C19.3783 10.5294 18.3334 9.66096 17.0817 9.66096C15.8301 9.66096 14.7852 10.5294 14.4838 11.6949H14.3783V4.23723ZM1.53724 5.59316V6.94909H5.59233V5.59316H1.53724ZM6.26817 11.0169C7.0231 11.0169 7.61987 11.6155 7.61987 12.3728C7.61987 13.1301 7.0231 13.7288 6.26817 13.7288C5.51325 13.7288 4.91648 13.1301 4.91648 12.3728C4.91648 11.6155 5.51325 11.0169 6.26817 11.0169ZM17.0817 11.0169C17.8367 11.0169 18.4334 11.6155 18.4334 12.3728C18.4334 13.1301 17.8367 13.7288 17.0817 13.7288C16.3268 13.7288 15.73 13.1301 15.73 12.3728C15.73 11.6155 16.3268 11.0169 17.0817 11.0169Z"
      fill="white"
    />
  </svg>
);
const IconTransitWarehouse = (
  <svg
    width="18"
    height="30"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.16943V4.52537H13.0265V14.6949H8.866C8.56457 13.5294 7.51971 12.661 6.26804 12.661C5.01637 12.661 3.97151 13.5294 3.67008 14.6949H2.8888V11.305H1.53711V16.0508H3.67008C3.97151 17.2162 5.01637 18.0847 6.26804 18.0847C7.51971 18.0847 8.56457 17.2162 8.866 16.0508H14.4836C14.7851 17.2162 15.8299 18.0847 17.0816 18.0847C18.3333 18.0847 19.3781 17.2162 19.6796 16.0508H21.8125V10.5213L21.77 10.4149L20.4183 6.34706L20.2716 5.8813H14.3782V3.16943H12ZM14.3782 7.23723H19.2991L20.4608 10.7118V14.6949H19.6796C19.3781 13.5294 18.3333 12.661 17.0816 12.661C15.8299 12.661 14.7851 13.5294 14.4836 14.6949H14.3782V7.23723ZM6.26804 14.0169C7.02296 14.0169 7.61974 14.6155 7.61974 15.3728C7.61974 16.1301 7.02296 16.7288 6.26804 16.7288C5.51312 16.7288 4.91635 16.1301 4.91635 15.3728C4.91635 14.6155 5.51312 14.0169 6.26804 14.0169ZM17.0816 14.0169C17.8365 14.0169 18.4333 14.6155 18.4333 15.3728C18.4333 16.1301 17.8365 16.7288 17.0816 16.7288C16.3267 16.7288 15.7299 16.1301 15.7299 15.3728C15.7299 14.6155 16.3267 14.0169 17.0816 14.0169Z"
      fill="white"
    />
    <path
      d="M7.74556 4.87944L5.375 7.25L4.56694 6.44194M6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const IconSPX = (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 20.6136V16.2003C19 15.7755 19 15.5631 18.9309 15.3651C18.8698 15.19 18.7692 15.024 18.6334 14.8743C18.4794 14.7048 18.2669 14.564 17.8387 14.2848L12.7387 10.9438C11.9461 10.4244 11.5487 10.1643 11.1024 10.0656C10.7093 9.97812 10.2907 9.97812 9.8965 10.0656C9.45025 10.1643 9.055 10.4236 8.26238 10.9422L3.16131 14.2848C2.73419 14.5648 2.52063 14.7048 2.36763 14.8735C2.23141 15.0234 2.13044 15.1897 2.06906 15.3651C2 15.5623 2 15.7755 2 16.2003V20.6136C2 21.355 2 21.7257 2.1615 22.0176C2.26817 22.2107 2.4246 22.3861 2.62186 22.534C2.81911 22.6818 3.05333 22.799 3.31113 22.8791C3.70213 23 4.19725 23 5.1875 23C6.17775 23 6.67287 23 7.06387 22.8791C7.32167 22.799 7.55589 22.6818 7.75314 22.534C7.9504 22.3861 8.10683 22.2107 8.2135 22.0176C8.375 21.7257 8.375 21.355 8.375 20.6136V19.8181C8.375 19.3962 8.59888 18.9915 8.9974 18.6931C9.39591 18.3948 9.93641 18.2272 10.5 18.2272C11.0636 18.2272 11.6041 18.3948 12.0026 18.6931C12.4011 18.9915 12.625 19.3962 12.625 19.8181V20.6136C12.625 21.355 12.625 21.7257 12.7865 22.0176C12.8932 22.2107 13.0496 22.3861 13.2469 22.534C13.4441 22.6818 13.6783 22.799 13.9361 22.8791C14.3271 23 14.8223 23 15.8125 23C16.8027 23 17.2979 23 17.6889 22.8791C17.9467 22.799 18.1809 22.6818 18.3781 22.534C18.5754 22.3861 18.7318 22.2107 18.8385 22.0176C19 21.7257 19 21.355 19 20.6136Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.49609 5.38672V5.31641H2.59766V5.38672C2.59766 5.6888 2.70703 5.91667 2.92578 6.07031C3.14714 6.22135 3.45443 6.29688 3.84766 6.29688C4.24349 6.29688 4.54688 6.22266 4.75781 6.07422C4.96875 5.92578 5.07422 5.73177 5.07422 5.49219C5.07682 5.25781 4.98698 5.07943 4.80469 4.95703C4.625 4.83464 4.19661 4.69661 3.51953 4.54297C2.84505 4.38672 2.35677 4.17969 2.05469 3.92188C1.7526 3.66406 1.60156 3.29688 1.60156 2.82031C1.60156 2.35156 1.79688 1.96354 2.1875 1.65625C2.58073 1.34896 3.1224 1.19531 3.8125 1.19531C4.5 1.19531 5.04688 1.36198 5.45312 1.69531C5.85938 2.02604 6.0625 2.42839 6.0625 2.90234V2.96875H4.96484V2.90234C4.96484 2.63672 4.86849 2.42188 4.67578 2.25781C4.48307 2.09375 4.19531 2.01172 3.8125 2.01172C3.43229 2.01172 3.15104 2.07812 2.96875 2.21094C2.78906 2.34115 2.69922 2.50391 2.69922 2.69922C2.69922 2.9388 2.78385 3.11589 2.95312 3.23047C3.125 3.34505 3.50911 3.47005 4.10547 3.60547C4.79036 3.76172 5.30599 3.98047 5.65234 4.26172C5.9987 4.54036 6.17188 4.93229 6.17188 5.4375C6.17188 5.93229 5.96224 6.33594 5.54297 6.64844C5.1263 6.96094 4.5612 7.11719 3.84766 7.11719C3.13932 7.11719 2.57031 6.96224 2.14062 6.65234C1.71094 6.34245 1.49609 5.92057 1.49609 5.38672ZM8.13672 7V1.3125H10.2969C11.0182 1.3125 11.5716 1.47656 11.957 1.80469C12.3424 2.13021 12.5352 2.56641 12.5352 3.11328C12.5352 3.66276 12.3424 4.10417 11.957 4.4375C11.5716 4.76823 11.0182 4.93359 10.2969 4.93359H9.23047V7H8.13672ZM9.23047 4.13672H10.2969C10.7188 4.13672 11.0143 4.03776 11.1836 3.83984C11.3555 3.64193 11.4414 3.39974 11.4414 3.11328C11.4414 2.82943 11.3555 2.59115 11.1836 2.39844C11.0143 2.20573 10.7188 2.10938 10.2969 2.10938H9.23047V4.13672ZM13.8711 7L15.8555 3.84766H16.0859L17.582 1.3125H18.6562L16.7734 4.38281H16.5391L14.9531 7H13.8711ZM13.9648 1.3125H15.2422L16.7383 3.84375H16.7734L18.7578 7H17.4766L15.8867 4.38672H15.8555L13.9648 1.3125Z"
      fill="white"
    />
  </svg>
);
const IconPackageCustom = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6277 5.8L9.31384 1L1 5.8V15.4L9.31384 20.2M17.6277 5.8L9.31384 11.2M17.6277 5.8V10.6M9.31384 20.2V11.2M9.31384 20.2L11.1133 19.1611M9.31384 11.2L1.51384 6.4M12.9138 8.2L5.11384 3.4M14.1138 16.6L15.3138 17.8L18.9138 14.2"
      stroke="#295bca"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconCarCustom = (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.72 3.22439C13.5804 2.98073 13.3799 2.77812 13.1385 2.63671C12.8971 2.49529 12.6232 2.41999 12.344 2.4183H10.4V0.806098C10.4 0.592308 10.3157 0.387274 10.1657 0.236101C10.0157 0.0849281 9.81217 0 9.6 0H1.6C1.17565 0 0.768687 0.169856 0.468629 0.472201C0.168571 0.774547 0 1.18462 0 1.6122V9.67318C0.000287794 9.9559 0.0743542 10.2336 0.214769 10.4783C0.355184 10.7231 0.55701 10.9264 0.8 11.0677C0.771357 11.816 1.03889 12.5451 1.54373 13.0946C2.04858 13.6441 2.74939 13.969 3.492 13.9979C4.23461 14.0268 4.95818 13.7572 5.50353 13.2485C6.04889 12.7398 6.37136 12.0336 6.4 11.2854H8.88C8.96846 11.9598 9.29717 12.5789 9.80491 13.0274C10.3126 13.4758 10.9648 13.7231 11.64 13.7231C12.3152 13.7231 12.9674 13.4758 13.4751 13.0274C13.9828 12.5789 14.3115 11.9598 14.4 11.2854C14.8243 11.2854 15.2313 11.1155 15.5314 10.8132C15.8314 10.5108 16 10.1008 16 9.67318V7.25489C15.9977 7.10797 15.9592 6.96393 15.888 6.83571L13.72 3.22439ZM10.4 4.03049H12.344L13.784 6.44879H10.4V4.03049ZM3.6 12.0915C3.36266 12.0915 3.13065 12.0206 2.93332 11.8877C2.73598 11.7548 2.58217 11.566 2.49134 11.345C2.40052 11.1241 2.37676 10.881 2.42306 10.6464C2.46936 10.4119 2.58365 10.1964 2.75147 10.0273C2.91929 9.85823 3.13311 9.74307 3.36589 9.69641C3.59867 9.64976 3.83995 9.67371 4.05922 9.76522C4.27849 9.85674 4.46591 10.0117 4.59776 10.2106C4.72962 10.4094 4.8 10.6432 4.8 10.8823C4.8 11.203 4.67357 11.5106 4.44853 11.7373C4.22348 11.9641 3.91826 12.0915 3.6 12.0915ZM11.6 12.0915C11.3627 12.0915 11.1307 12.0206 10.9333 11.8877C10.736 11.7548 10.5822 11.566 10.4913 11.345C10.4005 11.1241 10.3768 10.881 10.4231 10.6464C10.4694 10.4119 10.5836 10.1964 10.7515 10.0273C10.9193 9.85823 11.1331 9.74307 11.3659 9.69641C11.5987 9.64976 11.8399 9.67371 12.0592 9.76522C12.2785 9.85674 12.4659 10.0117 12.5978 10.2106C12.7296 10.4094 12.8 10.6432 12.8 10.8823C12.8 11.203 12.6736 11.5106 12.4485 11.7373C12.2235 11.9641 11.9183 12.0915 11.6 12.0915Z"
      fill="#295bca"
    />
  </svg>
);
const IconWarehouseCustom = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3859_1718)">
      <path
        d="M17.4173 17.417L1.58398 17.417"
        stroke="#295bca"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.6242 17.4163V4.74967C16.6242 3.25689 16.6242 2.5105 16.1604 2.04676C15.6967 1.58301 14.9503 1.58301 13.4575 1.58301H11.8742C10.3814 1.58301 9.63499 1.58301 9.17125 2.04676C8.79794 2.42006 8.72514 2.97651 8.71094 3.95801"
        stroke="#295bca"
        strokeWidth="1.5"
      />
      <path
        d="M11.875 17.4163V7.12467C11.875 5.63189 11.875 4.8855 11.4113 4.42176C10.9475 3.95801 10.2011 3.95801 8.70833 3.95801H5.54167C4.04889 3.95801 3.3025 3.95801 2.83875 4.42176C2.375 4.8855 2.375 5.63189 2.375 7.12467V17.4163"
        stroke="#295bca"
        strokeWidth="1.5"
      />
      <path
        d="M7.125 17.417V15.042"
        stroke="#295bca"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 6.33301H9.5"
        stroke="#295bca"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 8.70801H9.5"
        stroke="#295bca"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.75 11.083H9.5"
        stroke="#295bca"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3859_1718">
        <rect width="19" height="19" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const IconPlaneCustom = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.04139 9.90673L6.41184 12.2459L6.81544 14.0981L4.9136 16L2.99724 13.0027L0 11.0862L1.90184 9.18434L3.8549 9.68887L6.09232 6.95756L1.37517 3.8127L3.53691 1.65012L9.43506 3.617L12.3345 0.717521C13.2296 -0.178391 14.6162 -0.244132 15.4303 0.569986C16.2444 1.3841 16.1779 2.77001 15.2828 3.66593L12.3834 6.56541L14.3487 12.4615L12.1869 14.624L9.04139 9.90673Z"
      fill="#295bca"
    />
  </svg>
);

const IconContainerCustom = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#295bca"
      stroke="none"
    >
      <path
        d="M735 3894 c-354 -89 -657 -167 -673 -173 -15 -7 -36 -25 -45 -40 -16
-27 -17 -120 -17 -1226 0 -1285 -2 -1237 49 -1264 10 -6 315 -84 677 -175
l659 -165 265 25 c146 13 652 58 1125 99 473 41 932 82 1020 90 88 8 396 35
685 60 289 25 542 50 563 56 26 7 44 21 57 42 19 31 20 60 20 1230 0 1101 -1
1201 -17 1227 -31 53 198 29 -2773 291 -195 17 -489 43 -653 58 l-297 26 -645
-161z m2461 -214 c935 -82 1702 -150 1707 -150 4 0 7 -484 7 -1075 l0 -1074
-32 -5 c-37 -6 -3344 -296 -3370 -296 -17 0 -18 72 -18 1375 0 756 2 1375 4
1375 2 0 768 -67 1702 -150z m-1916 -1226 l0 -1356 -27 7 c-16 3 -111 27 -213
53 l-185 47 0 1249 0 1250 205 52 c113 29 208 53 213 53 4 1 7 -609 7 -1355z
m-640 0 c0 -1134 -1 -1196 -17 -1192 -10 3 -107 27 -216 54 l-197 49 2 1089 3
1090 205 52 c113 29 208 53 213 53 4 1 7 -537 7 -1195z"
      />
      <path
        d="M1983 3615 c-67 -29 -64 43 -61 -1174 l3 -1099 24 -26 c48 -51 142
-40 170 20 8 18 11 335 11 1122 0 1218 5 1128 -66 1158 -40 17 -41 17 -81 -1z"
      />
      <path
        d="M2824 3501 c-17 -11 -36 -34 -43 -52 -8 -23 -11 -305 -11 -996 0
-1037 -1 -1010 51 -1047 30 -21 88 -21 118 0 52 37 51 9 51 1049 0 1058 2
1018 -59 1049 -40 21 -69 20 -107 -3z"
      />
      <path
        d="M3659 3381 l-29 -29 0 -895 c0 -881 0 -895 20 -920 30 -38 80 -52
123 -33 71 29 67 -22 67 951 0 800 -1 879 -17 905 -34 58 -116 68 -164 21z"
      />
      <path
        d="M4543 3295 c-66 -29 -64 9 -61 -854 l3 -779 24 -26 c48 -51 142 -40
170 20 8 18 11 251 11 802 0 863 4 809 -66 838 -40 17 -41 17 -81 -1z"
      />
    </g>
  </svg>
);

export {
  IconStatistical,
  IconStatisticalClick,
  IconReport,
  IconReportClick,
  IconPackage,
  IconPackageClick,
  IconTransit,
  IconTransitClick,
  IconWarehouse,
  IconWarehouseClick,
  IconCar,
  IconCarClick,
  IconPlane,
  IconPlaneClick,
  IconUser,
  IconUserClick,
  IconPermission,
  IconPermissionClick,
  IconStartReceiving, //bắt đầu nhận hàng
  IconFinishedReceiving, // nhận hàng xong
  IconComeWarehouse, // xuất phát từ kho
  IconTransitWarehouse, // kho trung chuyển
  IconSPX, // đến kho spx
  IconPackageCustom,
  IconCarCustom,
  IconWarehouseCustom,
  IconPlaneCustom,
  IconContainer,
  IconContainerClick,
  IconContainerCustom,
};
