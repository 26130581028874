import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPackageData } from "../../store/listPackage/slice";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../../components/LoadingSpinner";
import { PermissionRequired } from "../../components/PermissionRequired";
import ModalDetailContainer from "./ModalDetailContainer";
import { IconContainerCustom } from "../../components/Icon";
import ModalImportExcelContainer from "./ModalImportExcelContainer";

export default function Container() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [modalDetail, setModalDetail] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [modelOpenImportExcel, setmodelOpenImportExcel] =
    useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const {
    packageData,
    deletePackageData,
    deletePackageError,
    updatePackageData,
    insertPackageData,
    importExcelData,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      packageData: state.Package.packageData,
      deletePackageData: state.Package.deletePackageData,
      deletePackageError: state.Package.deletePackageError,
      updatePackageData: state.Package.updatePackageData,
      insertPackageData: state.Package.insertPackageData,
      importExcelData: state.Package.importExcelData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const fetchData = (page: number, limit: number) => {
    dispatch(getPackageData({ page, limit, query: {} }));
  };

  useEffect(() => {
    fetchData((first + rows) / rows, rows);
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, first, rows]);

  useEffect(() => {
    if (
      deletePackageData ||
      updatePackageData ||
      insertPackageData ||
      importExcelData
    ) {
      fetchData((first + rows) / rows, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    deletePackageData,
    updatePackageData,
    insertPackageData,
    importExcelData,
  ]);

  useEffect(() => {
    if (packageData) {
      setLoading(false);
    }
  }, [packageData]);

  const containerData = {
    data: [
      {
        id: 1,
        nameContainer: "Container A",
        quantity: 100,
        dateOfReceipt: "2023-09-01",
        totalPackageDone: "23",
        totalPackageNoMove: "45",
      },
      {
        id: 2,
        nameContainer: "Container B",
        quantity: 200,
        dateOfReceipt: "2023-09-02",
        totalPackageDone: "30",
        totalPackageNoMove: "45",
      },
      {
        id: 3,
        nameContainer: "Container C",
        quantity: 150,
        dateOfReceipt: "2023-09-03",
        totalPackageDone: "45",
        totalPackageNoMove: "50",
      },
      {
        id: 4,
        nameContainer: "Container D",
        quantity: 250,
        dateOfReceipt: "2023-09-03",
        totalPackageDone: "56",
        totalPackageNoMove: "100",
      },
      {
        id: 5,
        nameContainer: "Container E",
        quantity: 350,
        dateOfReceipt: "2023-09-03",
        totalPackageDone: "54",
        totalPackageNoMove: "30",
      },
    ],
    total: 3,
  };

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  const rowsPerPageOptions = [
    { name: "10", code: 10 },
    { name: "20", code: 20 },
    { name: "30", code: 30 },
    { name: "100", code: 100 },
    { name: "200", code: 200 },
    { name: "500", code: 500 },
    { name: "Tất cả", code: Number(packageData?.total) },
  ];

  const statusContainerNameTemplate = (rowData: any) => {
    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        {IconContainerCustom}
        <div>{rowData?.nameContainer}</div>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <div>
          <Button
            label="Nhập từ Excel"
            className="mr-3"
            onClick={() => {
              setmodelOpenImportExcel(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
          />
        </div>
        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <IconField iconPosition="left" className="search-item w-6 flex gap-3">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm Container"
              className=" border-1"
              style={{ height: "35px" }}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          </IconField>

          <div className="calendar-container">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tạo:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                placeholder="Từ ngày - Đến ngày"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        {selectArrayPermisstion?.includes("VIEW_PACKAGE") ? (
          containerData && containerData.data ? (
            <div>
              <DataTable
                value={containerData?.data}
                emptyMessage="Không có dữ liệu"
                size="small"
                className="package-table"
                reorderableColumns
                rowClassName={rowClassName}
                scrollable
                onRowClick={(event) => {
                  setModalDetail(true);
                  setRowData(event.data);
                }}
              >
                <Column
                  header="STT"
                  body={(rowData, options) => (
                    <div className="text-center">
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  style={{ width: "5%" }}
                  alignHeader={"center"}
                  align={"center"}
                  frozen
                />
                <Column
                  field="nameContainer"
                  header="Tên Container"
                  body={statusContainerNameTemplate}
                ></Column>
                <Column field="quantity" header="Số lượng"></Column>
                <Column field="dateOfReceipt" header="Ngày nhận"></Column>
                <Column
                  field="totalPackageDone"
                  header="Số kiện hàng đã chuyển"
                  align={"center"}
                ></Column>
                <Column
                  field="totalPackageNoMove"
                  header="Số kiện hàng chưa chuyển"
                  align={"center"}
                ></Column>
              </DataTable>
              {loading && <LoadingSpinner />}
              <div className="card flex justify-content-center align-items-center">
                <Paginator
                  first={first}
                  rows={rows}
                  totalRecords={containerData.total}
                  onPageChange={onPageChange}
                />
                <Dropdown
                  value={rows}
                  onChange={(e) => setRows(e.value)}
                  options={rowsPerPageOptions}
                  optionLabel="name"
                  optionValue="code"
                  style={{ height: "40px" }}
                />
              </div>
            </div>
          ) : (
            <LoadingSpinner />
          )
        ) : (
          <PermissionRequired />
        )}
      </div>
      <ModalImportExcelContainer
        visible={modelOpenImportExcel}
        onHide={() => setmodelOpenImportExcel(false)}
        toast={toast}
      />
      <ModalDetailContainer
        visible={modalDetail}
        onHide={() => setModalDetail(false)}
        rowData={rowData}
      />
    </div>
  );
}
