import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getPhaseDataSuccess,
  getPhaseDataFail,
  insertPhaseSuccess,
  insertPhaseFail,
  updatePhaseSuccess,
  updatePhaseFail,
  deletePhaseSuccess,
  deletePhaseFail,
} from "./slice";

import {
  getAllPhase,
  insertPhase,
  updatePhase,
  deletePhase,
} from "../../api/backend_helper";

function* fetchPhaseDataAll(
  action: PayloadAction<{ page: number; limit: number; query?: any }>
): Generator<any, void, any> {
  try {
    const { page, limit, query = {} } = action.payload;
    const response = yield call(getAllPhase, page, limit, query);
    yield put(getPhaseDataSuccess(response));
  } catch (error) {
    yield put(getPhaseDataFail(error));
  }
}

function* createPhaseSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(insertPhase, action.payload);
    yield put(insertPhaseSuccess(data));
  } catch (error) {
    yield put(insertPhaseFail(error));
  }
}

function* updatePhaseSaga(
  action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updatePhase, id, data);
    yield put(updatePhaseSuccess(response));
  } catch (error) {
    yield put(updatePhaseFail(error));
  }
}

function* deletePhaseSaga(
  action: PayloadAction<any>
): Generator<any, void, any> {
  try {
    const data = yield call(deletePhase, action.payload);
    yield put(deletePhaseSuccess(data));
  } catch (error) {
    yield put(deletePhaseFail(error));
  }
}

function* PhaseSaga(): Generator<any, void, any> {
  yield takeLatest("phase/getPhaseData", fetchPhaseDataAll);
  yield takeLatest("phase/insertPhase", createPhaseSaga);
  yield takeLatest("phase/updatePhase", updatePhaseSaga);
  yield takeLatest("phase/deletePhase", deletePhaseSaga);
}

export default PhaseSaga;
