import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import ModalAddUser from "./ModalAddUser";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import ModelDelete from "../../components/ModalDelete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionMenu from "../../components/ActionMenu";
import { Toast } from "primereact/toast";
import {
  getDataUser,
  deleteUser,
  setUserDataNull,
  resetPassword,
} from "../../store/auth/slice";
import ModalEditUser from "./ModalEditUser";
import { Avatar } from "primereact/avatar";
import LoadingSpinner from "../../components/LoadingSpinner";
import ModelResetPassword from "../../components/ModelResetPassword";
import { site } from "../../api/url_helper";
import { PermissionRequired } from "../../components/PermissionRequired";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";

interface Gender {
  name: string;
  code: string;
}

function User() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [rows, setRows] = useState(10);
  const [first, setFirst] = useState(0);

  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dataTable, setDataTable] = useState([]);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [activeUser, setActiveUser] = useState<boolean>(true);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const genderData: Gender[] = [
    { name: "Nam", code: "MALE" },
    { name: "Nữ", code: "FEMALE" },
  ];

  useEffect(() => {
    if (tabMenuActive === 0) {
      setActiveUser(true);
    } else {
      setActiveUser(false);
    }
  }, [tabMenuActive]);

  const listStatus = [
    { label: "Đã kích hoạt" },
    { label: "Chưa được kích hoạt" },
  ];

  const {
    dataUser,
    insertUserData,
    deleteUserData,
    deleteUserError,
    updateUserData,
    resetPasswordData,
    resetPasswordError,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      dataUser: state.Auth.dataUser,
      insertUserData: state.Auth.insertUserData,
      updateUserData: state.Auth.updateUserData,
      deleteUserData: state.Auth.deleteUserData,
      deleteUserError: state.Auth.deleteUserError,
      resetPasswordData: state.Auth.resetPasswordData,
      resetPasswordError: state.Auth.resetPasswordError,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        getDataUser({
          page: (first + rows) / rows,
          limit: rows,
          query: {
            s_global: inputSearch,
            s_active: activeUser,
          },
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [
    dispatch,
    insertUserData,
    deleteUserData,
    updateUserData,
    resetPasswordData,
    first,
    rows,
    inputSearch,
    activeUser,
  ]);

  useEffect(() => {
    setDataTable(dataUser?.data);
  }, [dispatch, dataUser]);

  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    if (selectArrayPermisstion?.includes("UPDATE_USER")) {
      setModalEdit(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    if (selectArrayPermisstion?.includes("DELETE_USER")) {
      setModalDelete(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  // reset password
  const handleResetPassword = (rowData: any) => {
    if (
      dataUserInfo &&
      dataUserInfo?.data?.permission_group_info?.name === "ADMIN"
    ) {
      setModalResetPassword(true);
      setRowData(rowData);
    } else {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Bạn không có quyền",
        life: 3000,
      });
    }
  };

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deleteUser(id));
      }
      setModalDelete(false);
    }
  };

  const acceptResetPassword = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(resetPassword(id));
      }
      setModalResetPassword(false);
    }
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        onResetPassword={() => handleResetPassword(rowData)}
        type="reset-password"
      />
    );
  };

  const statusPermisionTemplate = (rowData: any) => {
    return (
      <>
        <p> {rowData ? rowData.permission_group_info?.name : ""}</p>
      </>
    );
  };

  const statusFullNameTemplate = (rowData: any) => {
    const imageUrl = rowData.avatar
      ? `${site}${rowData.avatar}`
      : "/images/avatas/avatar-null.png";

    return (
      <div className="flex flex-row align-items-center py-2 gap-2">
        <Avatar image={imageUrl} shape="circle" />
        <div>{rowData?.full_name}</div>
      </div>
    );
  };

  const statusGenderTemplate = (rowData: any) => {
    const option = genderData.find((e) => e.code === rowData?.gender);
    return (
      <>
        <p> {option ? option.name : ""}</p>
      </>
    );
  };

  const statusAddressTemplate = (rowData: any) => {
    return (
      <>
        <p>{rowData && rowData.address !== "null" ? rowData.address : ""}</p>
      </>
    );
  };

  const statusEmailTemplate = (rowData: any) => {
    return (
      <>
        <p>{rowData && rowData.email !== "null" ? rowData.email : ""}</p>
      </>
    );
  };

  const statusPositionTemplate = (rowData: any) => {
    return (
      <>
        <p>{rowData && rowData.position !== "null" ? rowData.position : ""}</p>
      </>
    );
  };

  const changeDateFormat = (data: any) => {
    const dob = new Date(data?.dob);
    const day = String(dob.getDate()).padStart(2, "0");
    const month = String(dob.getMonth() + 1).padStart(2, "0");
    const year = dob.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  useEffect(() => {
    if (deleteUserData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `${deleteUserData.message}`,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    if (deleteUserError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${deleteUserError.message}`,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }

    if (resetPasswordData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: `${resetPasswordData.message}`,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    if (resetPasswordError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${resetPasswordError.message}`,
        life: 3000,
      });
      dispatch(setUserDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteUserData, deleteUserError, resetPasswordData, resetPasswordError]);

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex justify-content-between mb-3">
        <div className="flex gap-3 w-8">
          <Button
            label="Thêm mới"
            onClick={() => {
              setAddDialogVisible(true);
            }}
            style={{ height: "35px" }}
            disabled={!selectArrayPermisstion?.includes("CREATE_USER")}
          />
          <IconField
            iconPosition="left"
            className="search-item flex items-center w-8"
          >
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm"
              className="border-1"
              style={{
                height: "35px",
              }}
              value={inputSearch}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInputSearch(e.target.value)
              }
            />
          </IconField>
        </div>
      </div>
      <ModalAddUser
        visible={addDialogVisible}
        onHide={() => setAddDialogVisible(false)}
        toast={toast}
      />
      <div className="bg-white border-round-md shadow-1 p-3">
        <div className="flex justify-content-between align-items-center ">
          <TabMenu
            model={listStatus.map((item, index) => ({
              ...item,
              template: () =>
                tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
            }))}
            activeIndex={tabMenuActive}
            onTabChange={(e) => setTabmenuActive(e.index)}
          />
        </div>
        {selectArrayPermisstion?.includes("VIEW_USER") ? (
          dataUser && dataUser.data ? (
            <>
              <DataTable
                value={dataTable}
                emptyMessage="Không có dữ liệu"
                size="small"
                className="custom-table"
                tableStyle={{ minWidth: "50rem" }}
              >
                <Column
                  header="STT"
                  body={(rowData, options) => (
                    <div className="text-center">
                      {first + options.rowIndex + 1}
                    </div>
                  )}
                  style={{ width: "5%" }}
                  alignHeader={"center"}
                  align={"center"}
                />
                <Column
                  header="Họ & tên"
                  body={statusFullNameTemplate}
                  style={{ minWidth: "250px" }}
                  align="center"
                />
                <Column
                  header="Tên đăng nhập"
                  field="username"
                  style={{ minWidth: "120px" }}
                />
                <Column
                  header="Nhóm quyền"
                  body={statusPermisionTemplate}
                  style={{ minWidth: "100px" }}
                />
                <Column
                  header="Chức vụ"
                  body={statusPositionTemplate}
                  style={{ minWidth: "120px" }}
                />
                <Column
                  header="Ngày sinh"
                  body={(rowData: any) => changeDateFormat(rowData)}
                  style={{ minWidth: "100px" }}
                />
                <Column
                  header="Giới tính"
                  body={statusGenderTemplate}
                  style={{ minWidth: "70px" }}
                />
                <Column
                  header="Email"
                  body={statusEmailTemplate}
                  style={{ minWidth: "150px" }}
                />
                <Column
                  header="Số điện thoại"
                  field="phone_number"
                  style={{ minWidth: "120px" }}
                />
                <Column
                  header="Căn cước công dân"
                  field="identification_code"
                  style={{ minWidth: "150px" }}
                />
                <Column
                  header="Địa chỉ"
                  body={statusAddressTemplate}
                  style={{
                    minWidth: "200px",
                    lineHeight: "1.5",
                  }}
                />
                <Column body={actionBodyTeamplate} />
              </DataTable>
              <Paginator
                first={first}
                rows={rows}
                totalRecords={dataUser?.total}
                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                onPageChange={onPageChange}
              />
            </>
          ) : (
            <LoadingSpinner />
          )
        ) : (
          <PermissionRequired />
        )}
      </div>
      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa người dùng này không?
          </div>
        }
      />
      <ModalEditUser
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        rowData={rowData}
        toast={toast}
      />

      <ModelResetPassword
        visible={modalResetPassword}
        setVisible={setModalResetPassword}
        accept={acceptResetPassword}
      />
    </div>
  );
}

export default User;
