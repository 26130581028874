import React from "react";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";

interface ModelResetPasswordProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  accept: () => void;
  msg?: string;
}
const ModelResetPassword: React.FC<ModelResetPasswordProps> = ({
  visible,
  setVisible,
  accept,
  msg = "Bạn chắc chắn đặt lại mật khẩu không ?",
}) => {
  return (
    <>
      <ConfirmDialog
        visible={visible}
        content={() => (
          <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i className="pi pi-exclamation-triangle text-5xl"></i>
            </div>
            <span className="font-bold text-2xl block mb-2 mt-4">Cảnh báo</span>
            <div className="mb-0">{msg}</div>
            <div className="flex align-items-center gap-2 mt-4">
              <Button
                label="Hủy"
                outlined
                onClick={() => setVisible(false)}
                className="w-8rem"
              ></Button>
              <Button
                label="Đồng ý"
                onClick={accept}
                className="w-8rem"
              ></Button>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default ModelResetPassword;
