import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { updatePlane, setPlaneDataNull } from "../../store/plane/slice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { formatDate } from "../../components/utils";

interface Plane {
    name: string;
    code: string;
    departure_point: string;
    destination: string;
    description?: string;
    // start_time: any | null;
    // end_time: any | null;
}

function convertToDate(dateString: string | null): Date | null {
    if (!dateString) return null;

    // Tách phần ngày và giờ (nếu có)
    const [datePart, timePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-").map(Number);

    // Nếu không có phần giờ, mặc định giờ là 00:00:00
    const hours = 0;
    const minutes = 0;
    const seconds = 0;

    // Nếu có phần giờ, phân tích phần giờ
    if (timePart) {
        const [hourPart, minutePart, secondPart] = timePart
            .split(":")
            .map(Number);
        return new Date(
            year,
            month - 1,
            day,
            hourPart || hours,
            minutePart || minutes,
            secondPart || seconds
        );
    }

    // Nếu không có phần giờ, chỉ tạo đối tượng Date với ngày
    return new Date(year, month - 1, day, hours, minutes, seconds);
}

export default function ModalEditPlane({
    visible,
    onHide,
    toast,
    rowData,
}: {
    visible: boolean;
    onHide: () => void;
    toast: any;
    rowData: any;
}) {
    const dispatch = useDispatch();

    const { updatePlaneData, updatePlaneError } = useSelector(
        (state: any) => ({
            updatePlaneData: state.Plane.updatePlaneData,
            updatePlaneError: state.Plane.updatePlaneError,
        }),
        shallowEqual
    );

    // Validation schema
    const validationSchema = Yup.object({
        name: Yup.string().required("Tên chuyến bay là bắt buộc"),
        code: Yup.string().required("Mã chuyến bay là bắt buộc"),
        departure_point: Yup.string().required("Điểm khởi hành là bắt buộc"),
        destination: Yup.string().required("Điểm đến là bắt buộc"),
        description: Yup.string(),
        // start_time: Yup.date().required("Thời gian bắt đầu là bắt buộc"),
        // end_time: Yup.date()
        //     .required("Thời gian kết thúc là bắt buộc")
        //     .min(
        //         Yup.ref("start_time"),
        //         "Thời gian kết thúc phải sau thời gian bắt đầu"
        //     ),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: rowData?.name || "",
            code: rowData?.code || "",
            departure_point: rowData?.departure_point || "",
            destination: rowData?.destination || "",
            description: rowData?.description || "",
            // start_time: convertToDate(rowData?.start_time) || null,
            // end_time: convertToDate(rowData?.end_time) || null,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            dispatch(updatePlane({ id: rowData?.id, data: values }));
            onHide();
        },
    });

    useEffect(() => {
        if (updatePlaneData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Chỉnh sửa chuyến bay thành công",
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setPlaneDataNull());
        }
        if (updatePlaneError) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: updatePlaneError?.response?.data?.message,
                life: 3000,
            });
            formik.resetForm();
            onHide();
            dispatch(setPlaneDataNull());
        }
    }, [updatePlaneData, updatePlaneError, dispatch]);

    const header = <div>Chỉnh sửa chuyến bay</div>;
    return (
        <Dialog
            visible={visible}
            onHide={() => onHide()}
            header={header}
            className="w-10 md:w-4"
        >
            <div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="name">Tên chuyến bay</label>
                    <InputText
                        id="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                            formik.touched.name && formik.errors.name
                                ? "p-invalid"
                                : ""
                        }
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <small className="p-error">
                            {formik.errors.name.toString()}
                        </small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="code">Mã chuyến bay</label>
                    <InputText
                        id="code"
                        name="code"
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                            formik.touched.code && formik.errors.code
                                ? "p-invalid"
                                : ""
                        }
                    />
                    {formik.touched.code && formik.errors.code ? (
                        <small className="p-error">
                            {formik.errors.code.toString()}
                        </small>
                    ) : null}
                </div>
                {/* <div className="flex gap-3 mb-3 justify-content-between">
          <div className="w-full">
            <label htmlFor="start_time" className="block mb-2">
              Thời gian bắt đầu
            </label>
            <Calendar
              id="start_time"
              value={formik.values.start_time}
              onChange={(e) => formik.setFieldValue("start_time", e.value)}
              showTime
              className="w-full"
              hourFormat="24"
              locale="vi"
            />
            {formik.touched.start_time && formik.errors.start_time ? (
              <small className="p-error">
                {formik.errors.start_time.toString()}
              </small>
            ) : null}
          </div>
          <div className="w-full">
            <label htmlFor="end_time" className="block mb-2">
              Thời gian kết thúc
            </label>
            <Calendar
              id="end_time"
              value={formik.values.end_time}
              onChange={(e) => formik.setFieldValue("end_time", e.value)}
              showTime
              hourFormat="24"
              locale="vi"
              className="w-full"
            />
            {formik.touched.end_time && formik.errors.end_time ? (
              <small className="p-error">
                {formik.errors.end_time.toString()}
              </small>
            ) : null}
          </div>
        </div> */}
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="departure_point">Điểm khởi hành</label>
                    <InputText
                        id="departure_point"
                        name="departure_point"
                        value={formik.values.departure_point}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.departure_point &&
                    formik.errors.departure_point ? (
                        <small className="p-error">
                            {formik.errors.departure_point.toString()}
                        </small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="destination">Điểm đến</label>
                    <InputText
                        id="destination"
                        name="destination"
                        value={formik.values.destination}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.destination && formik.errors.destination ? (
                        <small className="p-error">
                            {formik.errors.destination.toString()}
                        </small>
                    ) : null}
                </div>
                <div className="flex flex-column gap-1 flex-1 mb-3">
                    <label htmlFor="description">Mô tả</label>

                    <InputTextarea
                        id="description"
                        name="description"
                        rows={4}
                        className="w-full"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className="flex gap-2 justify-content-end">
                    <Button
                        label="Hủy"
                        severity="secondary"
                        size="small"
                        type="button"
                        onClick={() => {
                            onHide();
                            formik.resetForm();
                        }}
                    />
                    <Button
                        type="submit"
                        label="Lưu"
                        size="small"
                        onClick={() => formik.handleSubmit()}
                    />
                </div>
            </div>
        </Dialog>
    );
}
