import React, { useEffect, useRef, useState } from "react";
import "../../assets/scss/theme-base/table.scss";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionMenu from "../../components/ActionMenu";
import { Paginator } from "primereact/paginator";
import ModelDelete from "../../components/ModalDelete";
import { Toast } from "primereact/toast";
import {
  getPhaseData,
  deletePhase,
  setPhaseDataNull,
} from "../../store/phase/slice";
import ModelAddPhase from "./ModalAddPhase";
import ModelEditPhase from "./ModalEditPhase";
import LoadingSpinner from "../../components/LoadingSpinner";

function Phase() {
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch();

  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>(null);
  const [modalEdit, setModalEdit] = useState<boolean>(false);

  const {
    phaseData,
    deletePhaseData,
    updatePhaseData,
    insertPhaseData,
    deletePhaseError,
  } = useSelector(
    (state: any) => ({
      phaseData: state.Phase.phaseData,
      insertPhaseData: state.Phase.insertPhaseData,
      updatePhaseData: state.Phase.updatePhaseData,
      deletePhaseData: state.Phase.deletePhaseData,
      deletePhaseError: state.Phase.deletePhaseError,
    }),
    shallowEqual
  );

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    dispatch(getPhaseData({ page: (first + rows) / rows, limit: rows }));
  }, [dispatch, rows, first]);

  const acceptDelete = () => {
    if (rowData && rowData !== undefined) {
      const id = rowData.id;
      if (id !== undefined) {
        dispatch(deletePhase(id));
      }
      setModalDelete(false);
    }
  };

  useEffect(() => {
    if (insertPhaseData || updatePhaseData || deletePhaseData) {
      dispatch(getPhaseData({ page: (first + rows) / rows, limit: rows }));
    }
  }, [
    dispatch,
    rows,
    first,
    insertPhaseData,
    updatePhaseData,
    deletePhaseData,
  ]);

  //sửa dữ liệu
  const handleEdit = (rowData: any) => {
    setModalEdit(true);
    setRowData(rowData);
  };

  //xóa dữ liệu
  const handleDelete = (rowData: any) => {
    setModalDelete(true);
    setRowData(rowData);
  };

  //Menu hành động
  const actionBodyTeamplate = (rowData: any) => {
    return (
      <ActionMenu
        onEdit={() => handleEdit(rowData)}
        onDelete={() => handleDelete(rowData)}
        type="branch"
      />
    );
  };

  useEffect(() => {
    if (deletePhaseData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Xóa giai đoạn thành công",
        life: 3000,
      });
      dispatch(setPhaseDataNull());
    }
    if (deletePhaseError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: "Xóa giai đoạn thất bại",
        life: 3000,
      });
      dispatch(setPhaseDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePhaseData, deletePhaseError]);

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <Button
          label="Thêm mới"
          onClick={() => {
            setAddDialogVisible(true);
          }}
          style={{ height: "35px" }}
        />
        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <Button
            label="Xóa bộ lọc"
            icon="pi pi-filter-slash"
            severity="secondary"
            size="small"
          ></Button>
          <Button
            label="Xuất Excel"
            icon="pi pi-file-excel"
            severity="danger"
            outlined
            style={{ height: "35px" }}
          />
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        {phaseData && phaseData.data ? (
          <DataTable value={phaseData?.data} emptyMessage="Không có dữ liệu">
            <Column
              header="STT"
              body={(rowData, options) => (
                <div className="text-center">
                  {first + options.rowIndex + 1}
                </div>
              )}
              style={{ width: "5%" }}
              alignHeader={"center"}
              align={"center"}
            />
            <Column header="Tên giai đoạn" field="name" />
            <Column header="Vị trí" field="index" />
            <Column header="Ghi chú" field="description" />
            <Column body={actionBodyTeamplate} />
          </DataTable>
        ) : (
          <LoadingSpinner />
        )}
        <Paginator
          first={first}
          rows={rows}
          totalRecords={phaseData?.total}
          rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
          onPageChange={onPageChange}
        />
      </div>

      <ModelDelete
        visible={modalDelete}
        setVisible={setModalDelete}
        accept={acceptDelete}
        msg={
          <div style={{ textAlign: "center" }}>
            Bạn chắc chắn muốn xóa giai đoạn này không?
          </div>
        }
      />
      <ModelAddPhase
        visible={addDialogVisible}
        onHide={() => setAddDialogVisible(false)}
        toast={toast}
      />
      <ModelEditPhase
        visible={modalEdit}
        onHide={() => setModalEdit(false)}
        rowData={rowData}
        toast={toast}
      />
    </div>
  );
}

export default Phase;
