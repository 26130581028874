import React from "react";
import Header from "./Header";
import SidebarMenu from "./SidebarMenu";

interface PrivateLayoutProps {
    children: React.ReactNode;
}

const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
    return (
        <div className="h-screen w-screen flex flex-row surface-100">
            <div className="hidden xl:flex bg-white">
                <SidebarMenu />
            </div>
            <div className="flex flex-column h-screen overflow-hidden flex-auto ">
                <div>
                    <Header />
                </div>
                <div className="p-2 md:p-4 overflow-auto">{children}</div>
            </div>
        </div>
    );
};

export default PrivateLayout;
