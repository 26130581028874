import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  dataLogin: any | null;
  dataUser: any | null;
  dataLogout: any | null;
  dataUserInfo: any | null;
  error: string | null;
  errorLogin: string | null;
  isAuthenticated: boolean;
  loading: boolean;
  dataByUserId: any | null;
  insertUserData: any | null;
  insertUserError: any | null;
  updateUserData: any | null;
  updateUserError: any | null;
  deleteUserError: any | null;
  deleteUserData: any | null;
  resetPasswordData: any | null;
  resetPasswordError: any | null;
  uploadFileData: any | null;
  uploadFileError: any | null;
  updateProfileData: any | null;
  updateProfilError: any | null;
  changePasswordData: any | null;
  changePasswordError: any | null;
}

const initialState: AuthState = {
  dataLogin: null,
  dataUser: null,
  dataUserInfo: null,
  dataLogout: null,
  error: null,
  errorLogin: null,
  isAuthenticated: false,
  loading: false,
  dataByUserId: null,
  insertUserData: null,
  insertUserError: null,
  updateUserData: null,
  updateUserError: null,
  deleteUserData: null,
  deleteUserError: null,
  resetPasswordData: null,
  resetPasswordError: null,
  uploadFileData: null,
  uploadFileError: null,
  updateProfileData: null,
  updateProfilError: null,
  changePasswordData: null,
  changePasswordError: null,
};

type UserProps = {
  id?: number;
  username?: string;
  password?: string;
  permissions_group_id?: number;
  full_name?: string;
  phone_number: string;
  dob: Date;
  email: string;
  position: string;
  address?: string;
  gender: string;
  file?: File;
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataLogin = action.payload;
      state.isAuthenticated = true;
      state.errorLogin = null;
    },
    loginFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataLogin = null;
      state.errorLogin = action.payload;
    },
    logout: (state) => {
      state.loading = true;
      state.isAuthenticated = false;
    },
    logoutSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.dataLogout = action.payload;
      state.error = null;
    },
    logoutFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataLogout = null;
      state.error = action.payload;
    },
    getDataUser: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getDataUserSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataUser = action.payload;
      state.error = null;
    },
    getDataUserFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataUser = null;
      state.error = action.payload;
    },
    registerUser: (state, action: PayloadAction<UserProps>) => {
      state.loading = true;
    },
    registerUserSuccess: (state, action: PayloadAction<UserProps>) => {
      state.loading = false;
      state.insertUserData = action.payload;
      state.insertUserError = null;
    },
    registerUserFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertUserData = null;
      state.insertUserError = action.payload;
    },
    updateUser: (
      state,
      action: PayloadAction<{ id: string; data: UserProps }>
    ) => {
      state.loading = true;
    },
    updateUserSuccess: (state, action) => {
      state.loading = false;
      state.updateUserData = action.payload;
      state.updateUserError = null;
    },
    updateUserFail: (state, action) => {
      state.loading = false;
      state.updateUserData = null;
      state.updateUserError = action.payload;
    },
    deleteUser: (state) => {
      state.loading = true;
    },
    deleteUserSuccess: (state, action) => {
      state.loading = false;
      state.deleteUserData = action.payload;
      state.deleteUserError = null;
    },
    deleteUserFail: (state, action) => {
      state.loading = false;
      state.deleteUserData = null;
      state.deleteUserError = action.payload;
    },
    resetPassword: (state) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.resetPasswordData = action.payload;
      state.resetPasswordError = null;
    },
    resetPasswordFail: (state, action) => {
      state.loading = false;
      state.resetPasswordData = null;
      state.resetPasswordError = action.payload;
    },
    uploadFile: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    uploadFileSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.uploadFileData = action.payload;
      state.uploadFileError = null;
    },
    uploadFileFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.uploadFileData = null;
      state.uploadFileError = action.payload;
    },
    getDataInfo: (state) => {
      state.loading = true;
    },
    getDataInfoSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataUserInfo = action.payload;
      state.error = null;
    },
    getDataInfoFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dataUserInfo = null;
      state.error = action.payload;
    },
    updateProfile: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    updateProfileSuccess: (state, action) => {
      state.loading = false;
      state.updateProfileData = action.payload;
      state.updateProfilError = null;
    },
    updateProfileFail: (state, action) => {
      state.loading = false;
      state.updateProfileData = null;
      state.updateProfilError = action.payload;
    },
    changePassword: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    changePasswordSuccess: (state, action) => {
      state.loading = false;
      state.changePasswordData = action.payload;
      state.changePasswordError = null;
    },
    changePasswordFail: (state, action) => {
      state.loading = false;
      state.changePasswordData = null;
      state.changePasswordError = action.payload;
    },
    setUserDataNull: (state) => {
      state.dataLogin = null;
      // state.dataUserInfo = null;
      state.loading = false;
      state.errorLogin = null;
      state.insertUserData = null;
      state.insertUserError = null;
      state.updateUserData = null;
      state.updateUserError = null;
      state.deleteUserData = null;
      state.deleteUserError = null;
      state.resetPasswordData = null;
      state.resetPasswordError = null;
      state.uploadFileData = null;
      state.uploadFileError = null;
      state.updateProfileData = null;
      state.updateProfilError = null;
      state.changePasswordData = null;
      state.changePasswordError = null;
    },
  },
});

export const {
  login,
  loginSuccess,
  loginFail,
  logout,
  logoutSuccess,
  logoutFail,
  getDataUser,
  getDataUserSuccess,
  getDataUserFail,
  registerUser,
  registerUserSuccess,
  registerUserFail,
  setUserDataNull,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  deleteUser,
  deleteUserSuccess,
  deleteUserFail,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
  uploadFile,
  uploadFileFail,
  uploadFileSuccess,
  getDataInfo,
  getDataInfoSuccess,
  getDataInfoFail,
  updateProfile,
  updateProfileSuccess,
  updateProfileFail,
  changePassword,
  changePasswordSuccess,
  changePasswordFail,
} = userSlice.actions;

export default userSlice.reducer;
