import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PublicLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  const location = useLocation();

  const showHeader = location.pathname !== "/blankpage";

  return (
    <div className="surface-100 h-screen">
      {showHeader && <Header />}
      <div className="flex flex-row justify-content-center align-items-center">
        {children}
      </div>
    </div>
  );
};

export default PublicLayout;
