import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PhaseState {
  phaseData: any | null;
  error: string | null;
  loading: boolean;
  insertPhaseData: any | null;
  updatePhaseData: any | null;
  deletePhaseData: any | null;
  insertPhaseError: string | null;
  updatePhaseError: string | null;
  deletePhaseError: string | null;
}

const initialState: PhaseState = {
  phaseData: null,
  error: null,
  loading: false,
  insertPhaseData: null,
  updatePhaseData: null,
  deletePhaseData: null,
  insertPhaseError: null,
  updatePhaseError: null,
  deletePhaseError: null,
};

interface PhaseProps {
  id?: string;
  name: string;
  index: string;
  description: string;
}

const phaseSlice = createSlice({
  name: "phase",
  initialState,
  reducers: {
    getPhaseData: (
      state,
      action: PayloadAction<{
        page: number;
        limit: number;
        query?: any;
      }>
    ) => {
      state.loading = true;
    },
    getPhaseDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.phaseData = action.payload;
      state.error = null;
    },
    getPhaseDataFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.phaseData = null;
      state.error = action.payload;
    },
    insertPhase: (state, action: PayloadAction<PhaseProps>) => {
      state.loading = true;
    },
    insertPhaseSuccess: (state, action: PayloadAction<PhaseProps>) => {
      state.loading = false;
      state.insertPhaseData = action.payload;
      state.insertPhaseError = null;
    },
    insertPhaseFail: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.insertPhaseData = null;
      state.insertPhaseError = action.payload;
    },
    updatePhase: (
      state,
      action: PayloadAction<{ id: string; data: PhaseProps }>
    ) => {
      state.loading = true;
    },
    updatePhaseSuccess: (state, action) => {
      state.loading = false;
      state.updatePhaseData = action.payload;
      state.updatePhaseError = null;
    },
    updatePhaseFail: (state, action) => {
      state.loading = false;
      state.updatePhaseData = null;
      state.updatePhaseError = action.payload;
    },
    deletePhase: (state) => {
      state.loading = true;
    },
    deletePhaseSuccess: (state, action) => {
      state.loading = false;
      state.deletePhaseData = action.payload;
      state.deletePhaseError = null;
    },
    deletePhaseFail: (state, action) => {
      state.loading = false;
      state.deletePhaseData = null;
      state.deletePhaseError = action.payload;
    },
    setPhaseDataNull: (state) => {
      state.phaseData = null;
      state.loading = false;
      state.insertPhaseData = null;
      state.updatePhaseData = null;
      state.deletePhaseData = null;
      state.insertPhaseError = null;
      state.updatePhaseError = null;
      state.deletePhaseError = null;
    },
  },
});

export const {
  getPhaseData,
  getPhaseDataSuccess,
  getPhaseDataFail,
  insertPhase,
  insertPhaseSuccess,
  insertPhaseFail,
  updatePhase,
  updatePhaseSuccess,
  updatePhaseFail,
  deletePhase,
  deletePhaseSuccess,
  deletePhaseFail,
  setPhaseDataNull,
} = phaseSlice.actions;

export default phaseSlice.reducer;
