import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { TabMenu } from "primereact/tabmenu";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";

export default function ModalDetailContainer({
  visible,
  onHide,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  rowData: any;
}) {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [packageMove, setPackageMove] = useState<boolean>(true);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const sampleData = [
    {
      id: 1,
      code: "CN001",
      quantity: 50,
      dateOfReceipt: "2023-08-25",
    },
    {
      id: 2,
      code: "CN002",
      quantity: 120,
      dateOfReceipt: "2023-08-26",
    },
    {
      id: 3,
      code: "CN003",
      quantity: 200,
      dateOfReceipt: "2023-08-27",
    },
    {
      id: 4,
      code: "CN004",
      quantity: 75,
      dateOfReceipt: "2023-08-28",
    },
    {
      id: 5,
      code: "CN005",
      quantity: 30,
      dateOfReceipt: "2023-08-29",
    },
    {
      id: 6,
      code: "CN006",
      quantity: 95,
      dateOfReceipt: "2023-08-30",
    },
    {
      id: 7,
      code: "CN007",
      quantity: 150,
      dateOfReceipt: "2023-08-31",
    },
    {
      id: 8,
      code: "CN008",
      quantity: 80,
      dateOfReceipt: "2023-09-01",
    },
  ];

  const listStatus = [
    { label: "Kiện hàng đã chuyển" },
    { label: "Kiện hàng chưa chuyển" },
  ];

  useEffect(() => {
    if (tabMenuActive === 0) {
      setPackageMove(true);
    } else {
      setPackageMove(false);
    }
  }, [tabMenuActive]);

  return (
    <div>
      <Dialog
        visible={visible}
        onHide={() => onHide()}
        header="Chi tiết"
        className="w-10 md:w-4"
        style={{ minHeight: "50vh" }}
      >
        <div className="bg-white border-round-md shadow-1 p-2">
          <div className="flex justify-content-between align-items-center ">
            <TabMenu
              model={listStatus.map((item, index) => ({
                ...item,
                template: () =>
                  tabMenuTemplate(item, index, tabMenuActive, setTabmenuActive),
              }))}
              activeIndex={tabMenuActive}
              onTabChange={(e) => setTabmenuActive(e.index)}
            />
          </div>
          <DataTable
            value={sampleData}
            emptyMessage="Không có dữ liệu"
            size="small"
            className="package-table"
            reorderableColumns
            scrollable
          >
            <Column
              header="STT"
              body={(rowData, options) => (
                <div className="text-center">
                  {first + options.rowIndex + 1}
                </div>
              )}
              style={{ width: "5%" }}
              alignHeader={"center"}
              align={"center"}
              frozen
            />
            <Column field="code" header="Mã kiện hàng"></Column>
            <Column field="quantity" header="Số lượng"></Column>
            <Column field="dateOfReceipt" header="Ngày nhận"></Column>
          </DataTable>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={sampleData?.length}
            onPageChange={onPageChange}
          />
        </div>
      </Dialog>
    </div>
  );
}
