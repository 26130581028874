import React from "react";
import { Image } from "primereact/image";

function Header() {
    return (
        <div className="bg-white flex justify-content-center p-2">
            <div
                className="flex flex-row justify-content-center"
                style={{ width: "450px" }}
            >
                <div className="flex align-items-center justify-content-between ml-2 w-full h-4rem">
                    <Image
                        src="images/logo/unnamed.png"
                        alt="Image"
                        width="80"
                    />
                    <span className="ml-3 font-bold text-blue-600">
                        Logistics
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Header;
