import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../contexts/data/DataProvider";
import { Tooltip } from "primereact/tooltip";
import {
  IconReport,
  IconReportClick,
  IconPackage,
  IconPackageClick,
  IconTransit,
  IconTransitClick,
  IconWarehouse,
  IconWarehouseClick,
  IconCar,
  IconCarClick,
  IconPlane,
  IconPlaneClick,
  IconUser,
  IconUserClick,
  IconPermission,
  IconPermissionClick,
  IconStatistical,
  IconStatisticalClick,
  IconContainer,
  IconContainerClick,
} from "../../components/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  hasCommonPermissionsCar,
  hasCommonPermissionsPackage,
  hasCommonPermissionsPermission,
  hasCommonPermissionsPlane,
  hasCommonPermissionsReport,
  hasCommonPermissionsShipment,
  hasCommonPermissionsUser,
  hasCommonPermissionsWarehouse,
} from "../../ultis/permissionUtils";

interface SidebarMenuProps {
  mobile?: boolean;
  setMobileMenuVisible?: (visible: boolean) => void;
}

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  mobile,
  setMobileMenuVisible,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const { setTitlePage } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    if (setMobileMenuVisible) {
      setMobileMenuVisible(!mobile);
    }
  };

  const { dataUserInfo } = useSelector(
    (state: any) => ({
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  useEffect(() => {
    const currentItem = findCurrentItem(location.pathname);
    if (currentItem) {
      setTitlePage(currentItem.label);
    } else if (location.pathname === "/detail-transit") {
      setTitlePage("Chi tiết xe đang vận chuyển");
    } else if (location.pathname === "/profile") {
      setTitlePage("Thông tin tài khoản");
    } else {
      setTitlePage("Mật khẩu");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const findCurrentItem = (pathname: string) => {
    for (const category of items) {
      for (const item of category.items || []) {
        if (item.url === pathname) {
          return item;
        }
      }
    }
    return null;
  };

  const isPermissionVisibleStatistical = hasCommonPermissionsReport(
    dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
  );

  // thao tác
  const isPermissionVisibleOperation =
    hasCommonPermissionsPackage(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    ) ||
    hasCommonPermissionsShipment(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    );

  const isPermissionVisibleCategory =
    hasCommonPermissionsWarehouse(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    ) ||
    hasCommonPermissionsCar(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    ) ||
    hasCommonPermissionsPlane(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    ) ||
    hasCommonPermissionsUser(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    ) ||
    hasCommonPermissionsPermission(
      dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
    );

  const itemRenderer = (item: any) => {
    if (!item.url) {
      return null;
    }
    const isSelected =
      location.pathname === item.url ||
      (location.pathname === "/detail-transit" && item.url === "/transit");
    return (
      <Link
        to={item.url}
        className={`flex align-items-center p-menuitem-content p-menuitem-link border-round-md mx-3 p-3 svg-hover ${
          isSelected ? " text-white bg-blue-700" : ""
        } ${isSidebarOpen ? "" : "justify-content-center"}`}
        data-pr-tooltip={item.label}
        data-pr-position="right"
        onClick={() => {
          localStorage.setItem("menu", JSON.stringify(item.label));
          handleClick();
        }}
      >
        <span
          className={`p-menuitem-icon ${
            isSidebarOpen ? "" : "menu-item-icon-only"
          }`}
        >
          {isSelected ? <span>{item.svgClick}</span> : <span>{item.svg}</span>}
        </span>
        {isSidebarOpen && (
          <span className="menu-item-text mr-3 white-space-nowrap ml-4">
            {item.label}
          </span>
        )}
      </Link>
    );
  };
  let items = [
    {
      template: () => {
        return <></>;
      },
    },
    ...(isPermissionVisibleStatistical === true
      ? [
          {
            label: t("Báo cáo"),
            items: [
              {
                label: "Thống kê",
                svg: IconStatistical,
                svgClick: IconStatisticalClick,
                url: "/",
                template: itemRenderer,
                visible: hasCommonPermissionsReport(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Báo cáo",
                svg: IconReport,
                svgClick: IconReportClick,
                url: "/report",
                template: itemRenderer,
                visible: hasCommonPermissionsReport(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
            ].filter((subItem) => subItem.visible), // Filter out hidden sub-items
          },
        ]
      : []), // Include parent item only if `hasParentItemVisible` is true
    ...(isPermissionVisibleOperation === true
      ? [
          {
            label: "Thao tác",
            items: [
              {
                label: "Danh sách kiện hàng",
                svg: IconPackage,
                svgClick: IconPackageClick,
                url: "/package",
                template: itemRenderer,
                visible: hasCommonPermissionsPackage(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Quản lý Container",
                svg: IconContainer,
                svgClick: IconContainerClick,
                url: "/container",
                template: itemRenderer,
                visible: hasCommonPermissionsPackage(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Xe đang vận chuyển",
                svg: IconTransit,
                svgClick: IconTransitClick,
                url: "/transit",
                template: itemRenderer,
                visible: hasCommonPermissionsShipment(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
            ],
          },
        ]
      : []),
    ...(isPermissionVisibleCategory === true
      ? [
          {
            label: "Danh mục",
            items: [
              {
                label: "Kho SPX",
                svg: IconWarehouse,
                svgClick: IconWarehouseClick,
                url: "/warehouse",
                template: itemRenderer,
                visible: hasCommonPermissionsWarehouse(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Xe tải",
                svg: IconCar,
                svgClick: IconCarClick,
                url: "/car",
                template: itemRenderer,
                visible: hasCommonPermissionsCar(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Máy bay",
                svg: IconPlane,
                svgClick: IconPlaneClick,
                url: "/plane",
                template: itemRenderer,
                visible: hasCommonPermissionsPlane(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Người dùng",
                svg: IconUser,
                svgClick: IconUserClick,
                url: "/user",
                template: itemRenderer,
                visible: hasCommonPermissionsUser(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
              {
                label: "Phân quyền",
                svg: IconPermission,
                svgClick: IconPermissionClick,
                url: "/permission",
                template: itemRenderer,
                visible: hasCommonPermissionsPermission(
                  dataUserInfo?.data?.permissions_data.map((e: any) => e.name)
                ),
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <div
      className={`sidebar flex flex-column h-screen md:border-right-1 border-gray-200 ${
        isSidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      {!isSidebarOpen && (
        <Tooltip target=".svg-hover" showDelay={300} hideDelay={500} />
      )}
      <button
        className={`toggle-button ${isSidebarOpen ? "btn-open" : "btn-closed"}`}
        onClick={() => {
          setIsSidebarOpen(!isSidebarOpen);
        }}
      >
        <i
          className={`pi ${
            isSidebarOpen
              ? "pi-chevron-left text-primary text-sm"
              : "pi-chevron-right text-primary text-sm"
          }`}
        ></i>
      </button>
      <div
        className={`justify-content-center p-3 logo-image cursor-pointer`}
        onClick={() => navigate("/")}
      >
        <Image src={"images/logo/unnamed.png"} alt="Image" width="60" />
      </div>
      <Menu
        model={items}
        className="w-full border-noround overflow-auto border-none"
      />
    </div>
  );
};

export default SidebarMenu;
