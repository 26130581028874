import React, { useRef, useState, useContext, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import { Badge } from "primereact/badge";
import { DataContext } from "../../contexts/data/DataProvider";
import { getDataInfo, logout } from "../../store/auth/slice";
import { site } from "../../api/url_helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function Header() {
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const accountMenu = useRef<any>(null);
  const notifyMenu = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    uploadFileData,
    updateProfileData,
    dataUserInfo,
    updateUserData,
    deleteUserData,
  } = useSelector(
    (state: any) => ({
      uploadFileData: state.Auth.uploadFileData,
      updateProfileData: state.Auth.updateProfileData,
      dataUserInfo: state.Auth.dataUserInfo,
      updateUserData: state.Auth.updateUserData,
      deleteUserData: state.Auth.deleteUserData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDataInfo());
  }, [
    dispatch,
    uploadFileData,
    updateProfileData,
    updateUserData,
    deleteUserData,
  ]);

  const { titlePage } = useContext(DataContext);

  const profileMenuItems = [
    {
      label: "Tài khoản",
      items: [
        {
          label: "Thông tin tài khoản",
          icon: "pi pi-user",
          command: () => {
            navigate("/profile");
          },
        },
        {
          label: "Thay đổi mật khẩu",
          icon: "pi pi-key",
          command: () => {
            navigate("/change-password");
          },
        },
        {
          label: "Đăng xuất",
          icon: "pi pi-sign-out",
          command: () => {
            dispatch(logout());
            navigate("/login");
            localStorage.removeItem("changeAvatar");
          },
        },
      ],
    },
  ];
  const notifyMenuItems = [
    {
      label: "Thông báo",
      items: [
        {
          label: "Thông báo 1",
          icon: "pi pi-bell",
        },
        {
          label: "Thông báo 2",
          icon: "pi pi-bell",
        },
      ],
    },
  ];

  return (
    <>
      <div className="flex align-items-center justify-content-between bg-white h-4rem px-2 md:px-4">
        <div className="flex flex-row align-items-center">
          <div onClick={() => setMobileMenuVisible(true)}>
            <i
              className="pi pi-bars p-3 cursor-pointer xl:hidden"
              style={{ fontSize: "1.5rem" }}
            ></i>
          </div>
          <h3>{titlePage}</h3>
        </div>
        <div className="flex flex-row align-items-center">
          <div
            className="p-3 cursor-pointer"
            onClick={(event) => {
              notifyMenu.current.toggle(event);
            }}
          >
            <Menu
              model={notifyMenuItems}
              popup
              ref={notifyMenu}
              className="w-auto"
              pt={{ menuitem: { className: "mx-2" } }}
            />
            <i
              className="pi pi-bell p-overlay-badge"
              style={{ fontSize: "1.5rem" }}
            >
              <Badge value="2"></Badge>
            </i>
          </div>
          {/* <SelectLanguage /> */}
          <div
            className="flex align-items-center cursor-pointer p-3 gap-2 border-round text-700"
            onClick={(event) => accountMenu.current.toggle(event)}
          >
            <Menu
              model={profileMenuItems}
              popup
              ref={accountMenu}
              className="w-auto"
              pt={{ menuitem: { className: "mx-2" } }}
            />
            <Avatar
              image={
                dataUserInfo?.data?.avatar
                  ? `${site}${dataUserInfo?.data?.avatar}`
                  : "/images/avatas/avatar-null.png"
              }
              shape="circle"
            />
            <div className="flex flex-column">
              <span className="font-bold">{dataUserInfo?.data?.full_name}</span>
              <span className="font-normal">
                {dataUserInfo?.data?.username}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* SidebarMenu Mobile */}
      <div className="card flex justify-content-center">
        <Sidebar
          header={
            <Image src="images/logo/unnamed.png" alt="Image" width="100" />
          }
          visible={mobileMenuVisible}
          onHide={() => setMobileMenuVisible(false)}
        >
          <SidebarMenu
            mobile={mobileMenuVisible}
            setMobileMenuVisible={setMobileMenuVisible}
          />
        </Sidebar>
      </div>
    </>
  );
}
export default Header;
