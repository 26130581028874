import { takeLatest, put, call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  getCarDataSuccess,
  getCarDataFail,
  insertCarSuccess,
  insertCarFail,
  updateCarSuccess,
  updateCarFail,
  deleteCarSuccess,
  deleteCarFail,
} from "./slice";

import {
  getAllCar,
  insertCar,
  updateCar,
  deleteCar,
} from "../../api/backend_helper";

interface CarProps {
  id?: string;
  name: string;
  license_plate: string;
  capacity: string;
  description: string;
}

function* fetchCarDataAll(
  action: PayloadAction<{ page: number; limit: number; query: {} }>
): Generator<any, void, any> {
  try {
    const { page, limit, query } = action.payload;
    const response = yield call(getAllCar, page, limit, query);
    yield put(getCarDataSuccess(response));
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      yield put(getCarDataFail(error?.response));
    } else {
      yield put(getCarDataFail(error));
    }
  }
}

function* createCarSaga(
  action: PayloadAction<CarProps>
): Generator<any, void, any> {
  try {
    const data = yield call(insertCar, action.payload);
    yield put(insertCarSuccess(data));
  } catch (error) {
    yield put(insertCarFail(error));
  }
}

function* updateCarSaga(
  action: PayloadAction<{ id: string; data: CarProps }>
): Generator<any, void, any> {
  try {
    const { id, data } = action.payload;
    const response = yield call(updateCar, id, data);
    yield put(updateCarSuccess(response));
  } catch (error) {
    yield put(updateCarFail(error));
  }
}

function* deleteCarSaga(action: PayloadAction<any>): Generator<any, void, any> {
  try {
    const data = yield call(deleteCar, action.payload);
    yield put(deleteCarSuccess(data));
  } catch (error) {
    yield put(deleteCarFail(error));
  }
}

function* CarSaga(): Generator<any, void, any> {
  yield takeLatest("car/getCarData", fetchCarDataAll);
  yield takeLatest("car/insertCar", createCarSaga);
  yield takeLatest("car/updateCar", updateCarSaga);
  yield takeLatest("car/deleteCar", deleteCarSaga);
}

export default CarSaga;
