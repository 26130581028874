import { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  setPermissionDataNull,
  insertPermission as insertPermissionGroup,
} from "../../store/permission/slice";

const ModalAddPermission = ({
  visible,
  onHide,
  toast,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
}) => {
  const dispatch = useDispatch();

  const { insertPermissionData, insertPermissionError } = useSelector(
    (state: any) => ({
      insertPermissionData: state.Permission.insertPermissionData,
      insertPermissionError: state.Permission.insertPermissionError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      groupName: "",
      permission_ids: [],
    },
    validationSchema: Yup.object().shape({
      groupName: Yup.string().required("Tên nhóm quyền không được để trống"),
    }),
    onSubmit: (value) => {
      dispatch(
        insertPermissionGroup({ name: value.groupName, permission_ids: [] })
      );
    },
  });

  useEffect(() => {
    if (insertPermissionData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Thêm nhóm quyền thành công",
        life: 3000,
      });
      onHide();
      formik.resetForm();
      dispatch(setPermissionDataNull());
    }
    if (insertPermissionError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${insertPermissionError?.response?.data?.message}`,
        life: 3000,
      });
      dispatch(setPermissionDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertPermissionData, insertPermissionError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        formik.resetForm();
        onHide();
      }}
      header="Thêm nhóm quyền"
      modal
      style={{ width: "400px", height: "35%" }}
      footer={
        <div>
          <Button
            label="Hủy"
            onClick={() => {
              formik.resetForm();
              onHide();
            }}
            severity="secondary"
            className="p-button-text"
          />
          <Button
            type="submit"
            label="Lưu"
            onClick={() => formik.handleSubmit()}
          />
        </div>
      }
    >
      <div className="bg-white  border-round-md">
        <div className="flex flex-column gap-3">
          <div className="flex flex-column w-12">
            <label className="mb-2 ">Tên nhóm quyền</label>
            <InputText
              id="groupName"
              name="groupName"
              value={formik.values.groupName}
              onChange={formik.handleChange}
              invalid={
                formik.touched.groupName && formik.errors.groupName
                  ? true
                  : false
              }
            />
            {formik.touched.groupName && formik.errors.groupName ? (
              <div style={{ color: "red" }}>
                {formik.errors.groupName.toString()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalAddPermission;
