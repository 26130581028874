import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getPlanData } from "../../store/listPackage/slice";
import { formatDateUser } from "../../components/utils";

const convertDateRange = (dateRange: string) => {
  if (!dateRange) return ""; // Return empty string if dateRange is undefined or null

  const dates = dateRange.split("/");

  if (dates.length === 1) {
    // Single date
    return formatSingleDate(dates[0]);
  } else if (dates.length === 2) {
    // Date range
    const [startDate, endDate] = dates;
    return `${formatSingleDate(startDate)}-${formatSingleDate(endDate)}`;
  } else {
    // Invalid format
    return dateRange;
  }
};

const formatSingleDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split("-");
  return `${day}/${month}/${year}`;
};

export const PlanPackage = ({
  startDate,
  endDate,
}: {
  startDate: Date | null;
  endDate: Date | null;
}) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const dispatch = useDispatch();

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    const today = new Date();
    dispatch(
      getPlanData({
        start_date: startDate
          ? formatDateUser(startDate)
          : formatDateUser(today),
        end_date: endDate ? formatDateUser(endDate) : formatDateUser(today),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, endDate, startDate]);

  const { planData } = useSelector(
    (state: any) => ({
      planData: state.Package.planData,
    }),
    shallowEqual
  );

  return (
    <div>
      <DataTable
        value={planData?.plans}
        emptyMessage="Không có dữ liệu"
        size="small"
        className="package-table"
        reorderableColumns
        scrollable
      >
        <Column
          header="STT"
          body={(rowData, options) => (
            <div className="text-center">{first + options.rowIndex + 1}</div>
          )}
          style={{ width: "5%" }}
          alignHeader={"center"}
          align={"center"}
          frozen
        />
        <Column field="warehouse_name" header="Tên kho hàng"></Column>
        <Column field="warehouse_code" header="Mã kho hàng"></Column>
        <Column field="total_package" header="Số lượng"></Column>
        <Column
          field="date_delivery"
          header="Ngày nhận"
          body={(rowData) => convertDateRange(rowData.date_delivery)}
        />
      </DataTable>
      <Paginator
        first={first}
        rows={rows}
        totalRecords={planData?.plans.length}
        onPageChange={onPageChange}
      />
    </div>
  );
};
