import React, { createContext, useState, ReactNode, Dispatch } from "react";

interface DataContextType {
  titlePage: string;
  setTitlePage: React.Dispatch<React.SetStateAction<string>>;
  socket?: any;
  setSocket: React.Dispatch<React.SetStateAction<any>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  totalPackage: string;
  setTotalPackage: React.Dispatch<React.SetStateAction<string>>;
  currentPackage: string;
  setCurrentPackage: React.Dispatch<React.SetStateAction<string>>;
  updateUserCount?: number;
  setUpdateUserCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  notification?: any;
  setNotification: React.Dispatch<React.SetStateAction<any>>;
}

const defaultContextValue: DataContextType = {
  titlePage: "",
  setTitlePage: () => {},
  socket: undefined,
  setSocket: () => {},
  message: "",
  setMessage: () => {},
  totalPackage: "",
  setTotalPackage: () => {},
  currentPackage: "",
  setCurrentPackage: () => {},
  updateUserCount: undefined,
  setUpdateUserCount: () => {},
  notification: undefined,
  setNotification: () => {},
};

export const DataContext = createContext<DataContextType>(defaultContextValue);

interface DataProviderProps {
  children: ReactNode;
}

export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const [message, setMessage] = useState<string>("");
  const [socket, setSocket] = useState<any>();
  const [totalPackage, setTotalPackage] = useState<string>("");
  const [currentPackage, setCurrentPackage] = useState<string>("");
  const [updateUserCount, setUpdateUserCount] = useState<number | undefined>(
    undefined
  );
  const [notification, setNotification] = useState<any>();
  const [titlePage, setTitlePage] = useState<string>("");

  return (
    <DataContext.Provider
      value={{
        titlePage,
        setTitlePage,
        socket,
        setSocket,
        message,
        setMessage,
        totalPackage,
        setTotalPackage,
        currentPackage,
        setCurrentPackage,
        updateUserCount,
        setUpdateUserCount,
        notification,
        setNotification,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
