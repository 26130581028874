import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
    getWarehouseDataFail,
    getWarehouseDataSuccess,
    insertWarehouseFail,
    insertWarehouseSuccess,
    deleteWarehouseFail,
    deleteWarehouseSuccess,
    updateWarehouseFail,
    updateWarehouseSuccess,
} from "./slice";
import {
    getAllWarehouse,
    insertWarehouse,
    deleteWarehouse,
    updateWarehouse,
} from "../../api/backend_helper";

interface GetWarehousePayload {
    page: number;
    limit: number;
    query?: any;
}

function* getWarehouseSaga(
    action: PayloadAction<GetWarehousePayload>
): Generator<any, void, any> {
    try {
        const { page, limit, query = {} } = action.payload;
        const data = yield call(getAllWarehouse, page, limit, query);
        yield put(getWarehouseDataSuccess(data));
    } catch (error) {
        yield put(getWarehouseDataFail(error));
    }
}

function* insertWarehouseSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const data = yield call(insertWarehouse, action.payload);
        yield put(insertWarehouseSuccess(data));
    } catch (error) {
        yield put(insertWarehouseFail(error));
    }
}

function* deleteWarehouseSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const data = yield call(deleteWarehouse, action.payload);
        yield put(deleteWarehouseSuccess(data));
    } catch (error) {
        yield put(deleteWarehouseFail(error));
    }
}

function* updateWarehouseSaga(
    action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
    try {
        const { id, data } = action.payload;
        const response = yield call(updateWarehouse, id, data);
        yield put(updateWarehouseSuccess(response));
    } catch (error) {
        yield put(updateWarehouseFail(error));
    }
}

function* WarehouseSaga(): Generator<any, void, any> {
    yield takeLatest("warehouse/getWarehouseData", getWarehouseSaga);
    yield takeLatest("warehouse/insertWarehouse", insertWarehouseSaga);
    yield takeLatest("warehouse/deleteWarehouse", deleteWarehouseSaga);
    yield takeLatest("warehouse/updateWarehouse", updateWarehouseSaga);
}

export default WarehouseSaga;
