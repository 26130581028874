import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login, setUserDataNull } from "../../store/auth/slice";
import "../../assets/scss/theme-base/table.scss";

export default function Login() {
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { dataLogin, errorLogin } = useSelector(
        (state: any) => ({
            dataLogin: state.Auth.dataLogin,
            errorLogin: state.Auth.errorLogin,
        }),
        shallowEqual
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: Yup.object().shape({
            username: Yup.string().required(
                "Tên đăng nhập không được để trống"
            ),
            password: Yup.string().required("Mật khẩu không được để trống"),
        }),
        onSubmit: (values: any) => {
            dispatch(login(values));
        },
    });

    useEffect(() => {
        if (dataLogin) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Đăng nhập thành công",
                life: 3000,
            });
            setTimeout(() => {
                navigate("/");
            }, 1000);
        }
        dispatch(setUserDataNull());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLogin, navigate]);

    useEffect(() => {
        if (errorLogin) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: `Đăng nhập thất bại. ${errorLogin.response.data.message}`,
                life: 3000,
            });
        }
        dispatch(setUserDataNull());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorLogin]);

    return (
        <div
            className="card p-5 login-container"
            style={{ minWidth: "450px", height: "65vh" }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Toast ref={toast} />
                <div className="card bg-white p-3 border-round-xl mt-8">
                    <div className="text-center p-5">
                        <h4 className="text-3xl mb-2">Đăng nhập</h4>
                    </div>
                    <div className="flex flex-column mb-4">
                        <label htmlFor="username" className="mb-2">
                            Tên đăng nhập
                        </label>
                        <InputText
                            value={formik.values.username}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="username"
                            name="username"
                            autoComplete="on"
                            type="text"
                            placeholder="Tên đăng nhập"
                            className="w-full p-3"
                            invalid={
                                formik.touched.username && formik.errors.email
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.username && formik.errors.username ? (
                            <p
                                style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "0.9rem",
                                }}
                            >
                                {String(formik.errors.username)}
                            </p>
                        ) : null}
                    </div>
                    <div className="flex flex-column mb-4">
                        <label className="mb-2">Mật khẩu</label>
                        <Password
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Mật khẩu"
                            autoComplete="on"
                            inputClassName="w-full p-3"
                            pt={{
                                iconField: { root: { className: "w-full" } },
                            }}
                            toggleMask
                            feedback={false}
                            invalid={
                                formik.touched.password &&
                                formik.errors.password
                                    ? true
                                    : false
                            }
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <p
                                style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "0.9rem",
                                }}
                            >
                                {String(formik.errors.password)}
                            </p>
                        ) : null}
                    </div>
                    <Button
                        type="submit"
                        label="Đăng nhập"
                        className="w-full my-5 bg-blue-600"
                    />
                </div>
            </form>
        </div>
    );
}
