import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import ModalAddWarehouse from "./ModalAddWarehouse";
import ModalEditWarehouse from "./ModalEditWarehouse";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Paginator } from "primereact/paginator";
import ModelDelete from "../../components/ModalDelete";
import {
    getWarehouseData,
    deleteWarehouse,
    setWarehouseDataNull,
} from "../../store/warehouse/slice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ActionMenu from "../../components/ActionMenu";
import { Toast } from "primereact/toast";
import LoadingSpinner from "../../components/LoadingSpinner";
import { PermissionRequired } from "../../components/PermissionRequired";
import { IconWarehouseCustom } from "../../components/Icon";

function Warehouse() {
    const dispatch = useDispatch();
    const toast = useRef<Toast>(null);
    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);

    const [addDialogVisible, setAddDialogVisible] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [rowData, setRowData] = useState<any>(null);
    const [dataTable, setDataTable] = useState([]);
    const [inputSearch, setInputSearch] = useState<string>("");

    const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
        string[]
    >([]);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const {
        warehouseData,
        insertWarehouseData,
        deleteWarehouseData,
        deleteWarehouseError,
        updateWarehouseData,
        dataUserInfo,
    } = useSelector(
        (state: any) => ({
            warehouseData: state.Warehouse.warehouseData,
            insertWarehouseData: state.Warehouse.insertWarehouseData,
            deleteWarehouseData: state.Warehouse.deleteWarehouseData,
            deleteWarehouseError: state.Warehouse.deleteWarehouseError,
            updateWarehouseData: state.Warehouse.updateWarehouseData,
            dataUserInfo: state.Auth.dataUserInfo,
        }),
        shallowEqual
    );

    useLayoutEffect(() => {
        if (dataUserInfo) {
            const values = dataUserInfo?.data?.permissions_data.map(
                (e: any) => e.name
            );
            setSelectArrayPermisstion(values);
        }
    }, [dataUserInfo]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (inputSearch) {
                dispatch(
                    getWarehouseData({
                        page: (first + rows) / rows,
                        limit: rows,
                        query: { s_global: inputSearch },
                    })
                );
            } else {
                dispatch(
                    getWarehouseData({
                        page: (first + rows) / rows,
                        limit: rows,
                    })
                );
            }
        }, 400);
        return () => clearTimeout(timeout);
    }, [dispatch, rows, first, inputSearch]);

    useEffect(() => {
        if (insertWarehouseData || deleteWarehouseData || updateWarehouseData) {
            dispatch(
                getWarehouseData({ page: (first + rows) / rows, limit: rows })
            );
        }
    }, [
        dispatch,
        rows,
        first,
        insertWarehouseData,
        deleteWarehouseData,
        updateWarehouseData,
    ]);

    useEffect(() => {
        setDataTable(warehouseData?.data);
    }, [warehouseData]);
    //sửa dữ liệu
    const handleEdit = (rowData: any) => {
        if (selectArrayPermisstion?.includes("UPDATE_WAREHOUSE")) {
            setModalEdit(true);
            setRowData(rowData);
        } else {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: "Bạn không có quyền",
                life: 3000,
            });
        }
    };

    //xóa dữ liệu
    const handleDelete = (rowData: any) => {
        if (selectArrayPermisstion?.includes("DELETE_WAREHOUSE")) {
            setModalDelete(true);
            setRowData(rowData);
        } else {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: "Bạn không có quyền",
                life: 3000,
            });
        }
    };

    const acceptDelete = () => {
        if (rowData && rowData !== undefined) {
            const id = rowData.id;
            if (id !== undefined) {
                dispatch(deleteWarehouse(id));
            }
            setModalDelete(false);
        }
    };

    //Menu hành động
    const actionBodyTeamplate = (rowData: any) => {
        return (
            <ActionMenu
                onEdit={() => handleEdit(rowData)}
                onDelete={() => handleDelete(rowData)}
                type=""
            />
        );
    };

    useEffect(() => {
        if (deleteWarehouseData) {
            toast.current?.show({
                severity: "success",
                summary: "Thành công",
                detail: "Xóa kho hàng thành công",
                life: 3000,
            });
            dispatch(setWarehouseDataNull());
        }
        if (deleteWarehouseError) {
            toast.current?.show({
                severity: "error",
                summary: "Thất bại",
                detail: deleteWarehouseData.response?.data?.message,
                life: 3000,
            });
            dispatch(setWarehouseDataNull());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteWarehouseData, deleteWarehouseError]);

    const onDeleteAllSearch = () => {
        setInputSearch("");
    };

    const areaMap: { [key: string]: string } = {
        HO_CHI_MINH: "Hồ Chí Minh",
        HA_NOI: "Hà Nội",
        DA_NANG: "Đà Nẵng",
        CAN_THO: "Cần Thơ",
    };

    const areaBodyTemplate = (rowData: any) => {
        return areaMap[rowData.area] || rowData.area;
    };

    const warehouseTemplate = (rowData: any) => {
        return (
            <div className="flex flex-row align-items-center py-2 gap-2">
                {IconWarehouseCustom}
                <div>{rowData?.name}</div>
            </div>
        );
    };

    return (
        <div>
            <Toast ref={toast} />
            <div className="flex justify-content-between">
                <div className="flex gap-3 w-8">
                    <Button
                        label="Thêm mới"
                        onClick={() => {
                            setAddDialogVisible(true);
                        }}
                        style={{ height: "35px" }}
                        disabled={
                            !selectArrayPermisstion?.includes(
                                "CREATE_WAREHOUSE"
                            )
                        }
                    />
                    <IconField
                        iconPosition="left"
                        className="search-item w-8 flex gap-3"
                    >
                        <InputIcon
                            className="pi pi-search"
                            style={{ marginTop: "-14px" }}
                        ></InputIcon>
                        <InputText
                            value={inputSearch}
                            placeholder="Tìm kiếm"
                            className=" border-1 w-full"
                            style={{ height: "35px" }}
                            onChange={(e) => setInputSearch(e.target.value)}
                        />
                    </IconField>
                </div>
                <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
                    <Button
                        label="Xóa bộ lọc"
                        icon="pi pi-filter-slash"
                        severity="secondary"
                        size="small"
                        onClick={() => onDeleteAllSearch()}
                    ></Button>
                    <Button
                        label="Xuất Excel"
                        icon="pi pi-file-excel"
                        severity="danger"
                        outlined
                        style={{ height: "35px" }}
                    />
                    {/* </div> */}
                </div>
            </div>
            {/* <div className="searchEmployee">
                <IconField className="search-item">
                    <InputText
                        placeholder="Tên kho"
                        className="w-full border-1"
                        value={searchNameWareHouse}
                        onChange={(e) => setSearchNameWareHouse(e.target.value)}
                    />
                </IconField>
                <IconField className="search-item">
                    <InputText
                        placeholder="Mã kho"
                        className="w-full border-1"
                        value={searchCodeWareHouse}
                        onChange={(e) => setSearchCodeWareHouse(e.target.value)}
                    />
                </IconField>
            </div> */}
            <ModalAddWarehouse
                toast={toast}
                visible={addDialogVisible}
                onHide={() => setAddDialogVisible(false)}
            />
            <div className="bg-white border-round-md shadow-1 p-3">
                {selectArrayPermisstion?.includes("VIEW_WAREHOUSE") ? (
                    warehouseData && warehouseData.data ? (
                        <>
                            <DataTable
                                value={dataTable}
                                emptyMessage="Không có dữ liệu"
                                size="small"
                                className="custom-table"
                            >
                                <Column
                                    header="STT"
                                    body={(rowData, options) => (
                                        <div className="text-center">
                                            {first + options.rowIndex + 1}
                                        </div>
                                    )}
                                    style={{ width: "5%" }}
                                    alignHeader={"center"}
                                    align={"center"}
                                />
                                <Column
                                    header="Tên kho"
                                    field="name"
                                    body={warehouseTemplate}
                                />
                                <Column header="Mã kho" field="code" />
                                <Column
                                    header="Địa chỉ"
                                    field="address"
                                    style={{ minWidth: "100px" }}
                                />
                                <Column
                                    header="Khu vực"
                                    field="area"
                                    body={areaBodyTemplate}
                                />
                                <Column header="Mô tả" field="description" />
                                <Column body={actionBodyTeamplate} />
                            </DataTable>
                            <Paginator
                                first={first}
                                rows={rows}
                                totalRecords={warehouseData?.total}
                                rowsPerPageOptions={[10, 20, 30, 100, 200, 500]}
                                onPageChange={onPageChange}
                            />
                        </>
                    ) : (
                        <LoadingSpinner />
                    )
                ) : (
                    <PermissionRequired />
                )}
            </div>
            <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
                msg={
                    <div style={{ textAlign: "center" }}>
                        Bạn chắc chắn muốn xóa kho hàng này?
                        <br />
                        Các thông tin liên quan đến kho hàng sẽ bị xóa.
                    </div>
                }
            />
            <ModalEditWarehouse
                visible={modalEdit}
                onHide={() => setModalEdit(false)}
                toast={toast}
                rowData={rowData}
            />
        </div>
    );
}

export default Warehouse;
