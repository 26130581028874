import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getPackageData,
  deletePackage,
  setPackageDataNull,
} from "../../store/listPackage/slice";
import { Toast } from "primereact/toast";
import ModalImportExcel from "./ModalImportExcel";
import tabMenuTemplate from "../../assets/templete/tabMenuTemplete";
import { TabMenu } from "primereact/tabmenu";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { exportPackagePdf } from "./ExportPackagePdf";
import { changeDateFormat } from "../Plane";
import ModalDetailPackage from "./ModalDetailPackage";
import { Dropdown } from "primereact/dropdown";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Menu } from "primereact/menu";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { statusTemplate } from "../../components/status";
import { IconPackageCustom } from "../../components/Icon";
import { PermissionRequired } from "../../components/PermissionRequired";
import { SelectButton } from "primereact/selectbutton";
import { PlanPackage } from "./PlanPackage";

interface ColumnMeta {
  label: string;
  value: string;
  body?: any;
  minWidth?: string;
}

export default function Package() {
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const menuRef = useRef<Menu>(null);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [rowData, setRowData] = useState<any>(null);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [tabMenuActive, setTabmenuActive] = useState(0);
  const [modelOpenImportExcel, setmodelOpenImportExcel] =
    useState<boolean>(false);
  const [inputSearch, setInputSearch] = useState<string>("");
  const [dates, setDates] = useState<Date[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectArrayPermisstion, setSelectArrayPermisstion] = useState<
    string[]
  >([]);
  const switchDetailItems = ["Chi tiết", "Kế hoạch"];
  const [tab, setTab] = useState(0);
  const [switchDetail, setSwitchDetail] = useState(switchDetailItems[tab]);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  interface Package {
    shopee_po_id: string;
    carton_no: string;
    name: string;
    amount_product: number;
    delivery_date: any;
    amount_package: number;
    description: string;
    warehouse_name: string;
  }

  const {
    packageData,
    deletePackageData,
    deletePackageError,
    updatePackageData,
    insertPackageData,
    importExcelData,
    dataUserInfo,
  } = useSelector(
    (state: any) => ({
      packageData: state.Package.packageData,
      deletePackageData: state.Package.deletePackageData,
      deletePackageError: state.Package.deletePackageError,
      updatePackageData: state.Package.updatePackageData,
      insertPackageData: state.Package.insertPackageData,
      importExcelData: state.Package.importExcelData,
      dataUserInfo: state.Auth.dataUserInfo,
    }),
    shallowEqual
  );

  const sampleData: Package[] = [
    {
      shopee_po_id: "V59",
      carton_no: "TWSPKM24080606814",
      name: "259-20240807",
      amount_product: 10,
      delivery_date: "2024-09-15",
      amount_package: 5,
      description: "Description of 259-20240807",
      warehouse_name: "Warehouse 1",
    },
    {
      shopee_po_id: "V59",
      carton_no: "TWSPKM24080606814",
      name: "259-20240807",
      amount_product: 20,
      delivery_date: "2024-09-15",
      amount_package: 10,
      description: "Description of 259-20240807",
      warehouse_name: "Warehouse 2",
    },
    {
      shopee_po_id: "V59",
      carton_no: "TWSPKM24080606814",
      name: "Product C",
      amount_product: 15,
      delivery_date: "2024-09-15",
      amount_package: 7,
      description: "Description of Product C",
      warehouse_name: "Warehouse 3",
    },
  ];

  useLayoutEffect(() => {
    if (dataUserInfo) {
      const values = dataUserInfo?.data?.permissions_data.map(
        (e: any) => e.name
      );
      setSelectArrayPermisstion(values);
    }
  }, [dataUserInfo]);

  const fetchData = (page: number, limit: number) => {
    dispatch(getPackageData({ page, limit, query: {} }));
  };

  useEffect(() => {
    fetchData((first + rows) / rows, rows);
    setLoading(true);
  }, [dispatch, first, rows]);

  useEffect(() => {
    if (
      deletePackageData ||
      updatePackageData ||
      insertPackageData ||
      importExcelData
    ) {
      fetchData((first + rows) / rows, rows);
    }
  }, [
    dispatch,
    deletePackageData,
    updatePackageData,
    insertPackageData,
    importExcelData,
  ]);

  useEffect(() => {
    if (packageData) {
      setDataTable(packageData?.data);
      setLoading(false);
    }
  }, [packageData]);

  //in PDF
  const handlePrint = () => {
    // exportPackagePdf("print", dataTable);
    exportPackagePdf("print", sampleData);
  };

  //xuất PDF
  const handleExportPDF = () => {
    // exportPackagePdf("save", dataTable);
    exportPackagePdf("save", sampleData);
  };

  const items = [
    {
      label: "Xuất Excel",
      icon: "pi pi-file-excel",
    },
    {
      label: "Xuất PDF",
      icon: "pi pi-file-pdf",
      command: () => handleExportPDF(),
    },
  ];

  const listStatus = [
    { label: "Tất cả" },
    { label: "Mới" },
    { label: "Đang vận chuyển" },
    { label: "Giao hàng trễ" },
    { label: "Đã vận chuyển" },
  ];

  const rowClassName = (rowData: any, rowIndex: any) => {
    return "custom-row";
  };

  useEffect(() => {}, []);

  const rowsPerPageOptions = [
    { name: "10", code: 10 },
    { name: "20", code: 20 },
    { name: "30", code: 30 },
    { name: "100", code: 100 },
    { name: "200", code: 200 },
    { name: "500", code: 500 },
    { name: "Tất cả", code: Number(packageData?.total) },
  ];

  const bodyTemplate = (rowData: any) => (
    <div className="flex gap-2 align-items-center">
      <div>{IconPackageCustom}</div>
      <div>{rowData.carton_no}</div>
    </div>
  );

  const columns: ColumnMeta[] = [
    {
      label: "Mã kiện hàng",
      value: "carton_no",
      minWidth: "200px",
      body: bodyTemplate,
    },
    {
      label: "Shoppe PO ID",
      value: "shopee_po_id",
      minWidth: "150px",
    },
    {
      label: "Tên hàng",
      value: "name",
      minWidth: "150px",
    },
    {
      label: "Kho hàng",
      value: "warehouse_name",
      minWidth: "150px",
    },

    { label: "Biển số xe từ kho HN", value: "a", minWidth: "150px" },
    {
      label: "Thời gian bắt đầu nhận hàng ở kho",
      value: "",
      minWidth: "150px",
    },
    {
      label: "Thời gian sau khi nhận hàng ở kho",
      value: "",
      minWidth: "150px",
    },
    {
      label: "Thời gian đến sân bay Nội Bài",
      value: "",
      minWidth: "150px",
    },
    {
      label: "Thời gian dự kiến từ kho - sân bay",
      value: "",
      minWidth: "150px",
    },
    {
      label: "Thời gian thực tế từ kho - sân bay",
      value: "",
      minWidth: "150px",
    },
    { label: "Nguyên nhân trễ", value: "", minWidth: "150px" },
    { label: "Thời gian bắt đầu bay", value: "", minWidth: "120px" },
    { label: "Số hiệu máy bay", value: "", minWidth: "150px" },
    { label: "Loại tàu bay", value: "", minWidth: "150px" },
    { label: "Thời gian đến dự kiến", value: "", minWidth: "120px" },
    { label: "Thời gian đến thực tế", value: "", minWidth: "120px" },
    { label: "Biển số xe đi SPX", value: "", minWidth: "150px" },
    { label: "Thời gian xuất phát đến SPX", value: "", minWidth: "150px" },
    { label: "Thời gian đến SPX", value: "", minWidth: "150px" },
    { label: "Thời gian dự kiến tới SPX", value: "", minWidth: "140px" },
    { label: "Thời gian thực tế đến SPX", value: "", minWidth: "140px" },
    { label: "Nguyên nhân trễ", value: "", minWidth: "150px" },
    { label: "Thời gian nhận hàng", value: "", minWidth: "150px" },
    { label: "Thời gian SPX kí", value: "", minWidth: "150px" },
    {
      label: "Thời gian dự kiến từ kho - SPX nhận hàng",
      value: "",
      minWidth: "180px",
    },
    {
      label: "Thời gian thực tế từ kho - SPX nhận hàng",
      value: "",
      minWidth: "180px",
    },
  ];

  const [visibleColumns, setVisibleColumns] = useState<ColumnMeta[]>(columns);

  useEffect(() => {
    const storedColumns = localStorage.getItem("visibleColumns");

    if (storedColumns) {
      const selectedValues = JSON.parse(storedColumns);
      const newVisibleColumns = columns.filter((column) =>
        selectedValues.includes(column.value)
      );
      setVisibleColumns(newVisibleColumns);
    } else {
      setVisibleColumns(columns);
    }
  }, []);

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    const selectedValues = event.value as string[];
    const newVisibleColumns = columns.filter((column) =>
      selectedValues.includes(column.value)
    );

    setVisibleColumns(newVisibleColumns);
    localStorage.setItem("visibleColumns", JSON.stringify(selectedValues));
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex gap-3 justify-content-between">
        <Button
          label="Nhập từ Excel"
          onClick={() => {
            setmodelOpenImportExcel(true);
          }}
          style={{ height: "35px" }}
          disabled={!selectArrayPermisstion?.includes("IMPORT_EXCEL")}
        />
        <div className="flex gap-3 mb-3 sm:mt-0 md:mt-0">
          <IconField iconPosition="left" className="search-item w-6 flex gap-3">
            <InputIcon className="pi pi-search"></InputIcon>
            <InputText
              placeholder="Tìm kiếm kiện hàng"
              className=" border-1"
              style={{ height: "35px" }}
              onChange={(e) => setInputSearch(e.target.value)}
            />
          </IconField>

          <div className="calendar-container">
            <i className="pi pi-calendar text-500 mr-1" />
            <label
              className="w-max text-500"
              htmlFor="startDate"
              style={{ minWidth: "66px" }}
            >
              Ngày tạo:
            </label>
            <div className="card flex justify-content-center">
              <Calendar
                value={dates}
                onChange={(e: any) => setDates(e.value)}
                selectionMode="range"
                readOnlyInput
                hideOnRangeSelection
                dateFormat="dd/mm/yy"
                locale="vi"
                placeholder="Từ ngày - Đến ngày"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white border-round-md shadow-1 p-3">
        <div className="flex justify-content-between align-items-center mb-2">
          <div></div>
          {/* <TabMenu
                        model={listStatus.map((item, index) => ({
                            ...item,
                            template: () =>
                                tabMenuTemplate(
                                    item,
                                    index,
                                    tabMenuActive,
                                    setTabmenuActive
                                ),
                        }))}
                        activeIndex={tabMenuActive}
                        onTabChange={(e) => setTabmenuActive(e.index)}
                    /> */}
          <div className="flex align-items-center">
            {/* <SplitButton
                            icon="pi pi-print"
                            label="In bảng kê"
                            severity="danger"
                            model={items}
                            style={{ height: "35px" }}
                            onClick={handlePrint}
                            size="small"
                        /> */}
            <SelectButton
              value={switchDetail}
              onChange={(e) => {
                setSwitchDetail(e.value);
              }}
              options={switchDetailItems}
            />
            <div className="select-custom">
              <MultiSelect
                options={columns}
                value={visibleColumns.map((col) => col.value)}
                className="multiSeclect-custom"
                onChange={onColumnToggle}
                optionLabel="label"
                optionValue="value"
                dropdownIcon="pi pi-cog"
              />
            </div>
          </div>
        </div>
        {switchDetail === "Chi tiết" ? (
          selectArrayPermisstion?.includes("VIEW_PACKAGE") ? (
            packageData && packageData.data ? (
              <div>
                <DataTable
                  value={dataTable}
                  emptyMessage="Không có dữ liệu"
                  size="small"
                  className="package-table"
                  reorderableColumns
                  rowClassName={rowClassName}
                  scrollable
                  onRowClick={(event) => {
                    setModalDetail(true);
                    setRowData(event.data);
                  }}
                >
                  <Column
                    header="STT"
                    body={(rowData, options) => (
                      <div className="text-center">
                        {first + options.rowIndex + 1}
                      </div>
                    )}
                    style={{ width: "5%" }}
                    alignHeader={"center"}
                    align={"center"}
                    frozen
                  />
                  {visibleColumns.map((col) => (
                    <Column
                      key={col.value}
                      field={col.value}
                      header={col.label}
                      body={col.body}
                      style={{
                        minWidth: col.minWidth,
                      }}
                      frozen={
                        col.value === "status" || col.value === "carton_no"
                      }
                      alignFrozen={col.value === "status" ? "right" : undefined}
                      align={col.value === "status" ? "center" : undefined}
                    />
                  ))}
                </DataTable>
                {loading && <LoadingSpinner />}
                <div className="card flex justify-content-center align-items-center">
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={packageData?.total}
                    onPageChange={onPageChange}
                  />
                  <Dropdown
                    value={rows}
                    onChange={(e) => setRows(e.value)}
                    options={rowsPerPageOptions}
                    optionLabel="name"
                    optionValue="code"
                    style={{ height: "40px" }}
                  />
                </div>
              </div>
            ) : (
              <LoadingSpinner />
            )
          ) : (
            <PermissionRequired />
          )
        ) : (
          <PlanPackage
            startDate={dates ? dates[0] : null}
            endDate={dates ? dates[1] : null}
          />
        )}
      </div>

      {/* <ModalAddPackage
                visible={addDialogVisible}
                onHide={() => setAddDialogVisible(false)}
                toast={toast}
            />
            <ModalEditPackage
                visible={modalEdit}
                onHide={() => setModalEdit(false)}
                rowData={rowData}
                toast={toast}
            /> */}
      {/* <ModelDelete
                visible={modalDelete}
                setVisible={setModalDelete}
                accept={acceptDelete}
                msg={
                    <div style={{ textAlign: "center" }}>
                        Bạn chắc chắn muốn xóa kiện hàng này?
                        <br />
                        Các thông tin liên quan đến kiện hàng sẽ bị xóa.
                    </div>
                }
            /> */}

      <ModalImportExcel
        visible={modelOpenImportExcel}
        onHide={() => setmodelOpenImportExcel(false)}
        toast={toast}
      />
      <ModalDetailPackage
        visible={modalDetail}
        onHide={() => setModalDetail(false)}
        rowData={rowData}
      />
    </div>
  );
}
