import React from "react";

export const PermissionRequired = () => {
  return (
    <div
      className="card p-5"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: "100vh",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <img
        alt="Under development"
        src="/images/errors/error-404.png"
        style={{
          display: "inline-block",
          maxWidth: "100%",
          width: 200,
        }}
      />
      <h3 className="font-semibold mt-3 mb-3">
        Bạn không có quyền xem phần này
      </h3>
    </div>
  );
};
