import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransitSate {
    transitData: any | null;
    error: any | null;
    loading: boolean;
}

const initialState: TransitSate = {
    transitData: null,
    error: null,
    loading: false,
};

const transitSlice = createSlice({
    name: "transit",
    initialState,
    reducers: {
        getTransitData: (
            state,
            action: PayloadAction<{
                page: number;
                limit: number;
                query?: any;
            }>
        ) => {
            state.loading = true;
        },
        getTransitDataSuccess: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.transitData = action.payload;
            state.error = null;
        },
        getTransitDataFail: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.transitData = null;
            state.error = action.payload;
        },
    },
});

export const { getTransitData, getTransitDataFail, getTransitDataSuccess } =
    transitSlice.actions;
export default transitSlice.reducer;
