import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
    getPlaneDataFail,
    getPlaneDataSuccess,
    insertPlaneFail,
    insertPlaneSuccess,
    deletePlaneFail,
    deletePlaneSuccess,
    updatePlaneFail,
    updatePlaneSuccess,
} from "./slice";
import {
    getAllPlane,
    insertPlane,
    deletePlane,
    updatePlane,
} from "../../api/backend_helper";

interface GetPlanePayload {
    page: number;
    limit: number;
    query?: any;
}

function* getPlaneSaga(
    action: PayloadAction<GetPlanePayload>
): Generator<any, void, any> {
    try {
        const { page, limit, query = {} } = action.payload;
        const data = yield call(getAllPlane, page, limit, query);
        yield put(getPlaneDataSuccess(data));
    } catch (error) {
        yield put(getPlaneDataFail(error));
    }
}

function* insertPlaneSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const data = yield call(insertPlane, action.payload);
        yield put(insertPlaneSuccess(data));
    } catch (error) {
        yield put(insertPlaneFail(error));
    }
}

function* deletePlaneSaga(
    action: PayloadAction<any>
): Generator<any, void, any> {
    try {
        const data = yield call(deletePlane, action.payload);
        yield put(deletePlaneSuccess(data));
    } catch (error) {
        yield put(deletePlaneFail(error));
    }
}

function* updatePlaneSaga(
    action: PayloadAction<{ id: string; data: any }>
): Generator<any, void, any> {
    try {
        const { id, data } = action.payload;
        const response = yield call(updatePlane, id, data);
        yield put(updatePlaneSuccess(response));
    } catch (error) {
        yield put(updatePlaneFail(error));
    }
}

function* PlaneSaga(): Generator<any, void, any> {
    yield takeLatest("plane/getPlaneData", getPlaneSaga);
    yield takeLatest("plane/insertPlane", insertPlaneSaga);
    yield takeLatest("plane/deletePlane", deletePlaneSaga);
    yield takeLatest("plane/updatePlane", updatePlaneSaga);
}

export default PlaneSaga;
