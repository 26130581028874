import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import logistic from "../../assets/images/avatas/logistic-banner.png";

// Đặt vfs cho pdfMake
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

// Định nghĩa kiểu dữ liệu cho gói hàng
interface Package {
  shopee_po_id: string;
  carton_no: string;
  name: string;
  amount_product: number;
  delivery_date: any;
  amount_package: number;
  description: string;
  warehouse_name: string;
}

// (pdfMake as any).fonts = {
//   NotoSansSC: {
//     normal: "NotoSansSC-Regular.ttf",
//     bold: "NotoSansSC-Medium.ttf",
//     italics: "NotoSansSC-Regular.ttf",
//     bolditalics: "NotoSansSC-Bold.ttf",
//   },
// };

// Hàm chuyển đổi Blob thành Base64
const getBase64Image = (img: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject(new Error("FileReader result is not a string"));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(img);
  });
};

// Hàm lấy ảnh từ URL và chuyển thành Base64
const fetchImageAsBase64 = async (imagePath: string): Promise<any> => {
  try {
    const response = await fetch(imagePath);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const blob = await response.blob();
    return await getBase64Image(blob);
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }
};

// Hàm nén ảnh
const compressImage = async (
  imageFile: Blob,
  maxWidth: number,
  maxHeight: number,
  quality: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Canvas context is null"));
        return;
      }

      let width = img.width;
      let height = img.height;

      // Tính toán kích thước mới
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);
      canvas.toBlob(
        async (blob) => {
          if (blob) {
            try {
              const base64 = await getBase64Image(blob);
              resolve(base64);
            } catch (err) {
              reject(err);
            }
          } else {
            reject(new Error("Canvas blob is null"));
          }
        },
        "image/jpeg",
        quality
      );
    };

    img.onerror = (error) => reject(error);
  });
};

// Hàm lấy ảnh từ URL và nén nó
const getBase64FromUrl = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const blob = await response.blob();

    if (!blob.type.startsWith("image/")) {
      throw new Error("Invalid image format");
    }

    return await compressImage(blob, 300, 300, 0.1);
  } catch (error) {
    console.error("Error converting image to base64:", error);

    try {
      const defaultImageUrl = "https://example.com/default-image.png"; // Thay thế bằng URL của ảnh mặc định
      const defaultResponse = await fetch(defaultImageUrl);

      if (!defaultResponse.ok) {
        throw new Error(
          `Network response was not ok: ${defaultResponse.statusText}`
        );
      }

      const defaultBlob = await defaultResponse.blob();
      return await compressImage(defaultBlob, 300, 300, 0.7);
    } catch (defaultError) {
      console.error("Error loading default image:", defaultError);
      return null;
    }
  }
};

// Hàm xuất PDF gói hàng
export const exportPackagePdf = async (
  action: "print" | "save",
  // dataTable: Package[],
  sampleData: Package[]
): Promise<void> => {
  const logoBase64 = await fetchImageAsBase64(logistic);
  const docDefinition: TDocumentDefinitions = {
    pageSize: { width: 1100, height: 1000 },
    content: [
      {
        image: logoBase64,
        width: 1020,
        height: 150,
        // absolutePosition: { x: 10, y: 50 },
      },
      {
        columns: [
          {
            text: "唛头: SWC0807",
            style: "header",
            fontSize: 12,
            margin: [0, 10, 0, 5],
            // font: "NotoSansTC",
            width: "*",
          },
          {
            text: "编号(NO#)：SWC20240807-1",
            style: "end",
            fontSize: 12,
            margin: [0, 10, 60, 0],
            alignment: "right",
          },
        ],
      },
      {
        text: "địa chỉ đưa hàng  派送地址  HCM address: Tan Phu SOC: Address: (120km from HCM Mega SOC) Lô 01, Kho số A01, Khu Công Nghiệp Bình Minh, Ấp Mỹ Lợi, Xã Mỹ Hòa, Thị Xã Bình Minh, Tỉnh Vĩnh Long",
        style: "header",
        fontSize: 11,
        margin: [0, 10, 0, 20],
      },
      {
        text: "Trần Văn Vàng：0981445657  Châu Văn Danh：0399059985 Trương Minh Triệu：0921453571 Dương Trọng Nghĩa：0777868844  Phạm Thanh Huy：0796912997    Võ Thanh Tâm：0987090121   Liễu Xuân Nhi：0353573056   Trần Bá Sang：0988631256   Trần Minh Phụng：0367198739  Lê Thy Gia：0983988607				",
        style: "header",
        fontSize: 11,
        margin: [0, 10, 0, 20],
      },
      {
        table: {
          headerRows: 1,
          widths: ["auto", 100, 175, 200, 100, 200, 150],
          body: [
            [
              {
                text: "STT",
                bold: true,
                alignment: "center",
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 20, 0, 20],
              },
              {
                text: "SHOPEE PO ID",
                bold: true,
                alignment: "center",
                fillColor: "#295bca",
                color: "#ffffff",
                margin: [0, 20, 0, 20],
              },
              {
                text: "Số thông 箱号CARTON NO.",
                bold: true,
                fillColor: "#295bca",
                alignment: "center",
                color: "#ffffff",
                margin: [0, 20, 0, 20],
              },
              {
                text: "Tên hàng 货名 DESCRIPTION OF GOODS",
                bold: true,
                fillColor: "#295bca",
                alignment: "center",
                color: "#ffffff",
                margin: [0, 10, 0, 10],
              },
              {
                text: "Parcel Pcs",
                bold: true,
                fillColor: "#295bca",
                alignment: "center",
                color: "#ffffff",
                margin: [0, 20, 0, 20],
              },
              {
                text: "Ngày giao hàng 交货日期 DATE OF RECEIVED",
                bold: true,
                fillColor: "#295bca",
                alignment: "center",
                color: "#ffffff",
                margin: [0, 10, 0, 10],
              },
              {
                text: "số kiện 件数 NUMBER OF PACKAGES",
                bold: true,
                fillColor: "#295bca",
                alignment: "center",
                color: "#ffffff",
                margin: [0, 10, 0, 10],
              },
            ],
            // Dynamic rows from sampleData
            ...sampleData.map((item, index) => [
              {
                text: (index + 1).toString(),
                alignment: "center",
                margin: [0, 5, 0, 5],
              },
              {
                text: item.shopee_po_id,
                margin: [0, 5, 0, 5],
                alignment: "center",
              },
              {
                text: item.carton_no,
                margin: [0, 5, 0, 5],
                alignment: "center",
              },
              { text: item.name, margin: [0, 5, 0, 5], alignment: "center" },
              {
                text: item.amount_product.toString(),
                margin: [0, 5, 0, 5],
                alignment: "center",
              }, // Parcel Pcs
              {
                text: item.delivery_date,
                margin: [0, 5, 0, 5],
                alignment: "center",
              },
              {
                text: item.amount_package.toString(),
                margin: [0, 5, 0, 5],
                alignment: "center",
              },
            ]),
            [
              {},
              {
                text: "hợp kế 合计 TOTAL：",
                colSpan: 1,
                alignment: "center",
                bold: true,
                margin: [0, 5, 0, 5],
              },
              {},
              {}, // empty cells for merged columns
              {
                text: "4393 PKGS",
                alignment: "center",
                bold: true,
                margin: [0, 5, 0, 5],
              },
              { text: "", margin: [0, 5, 0, 5], alignment: "center" },
              {
                text: sampleData
                  .reduce((sum, item) => sum + item.amount_package, 0)
                  .toString(),
                alignment: "center",
                bold: true,
                margin: [0, 5, 0, 5],
              },
            ],
          ],
        },
      },
    ],
    // defaultStyle: {
    //   font: "NotoSansSC",
    // },
  };

  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  if (action === "print") {
    pdfDocGenerator.getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      const newWindow = window.open(url, "_blank");
      if (newWindow) {
        newWindow.onload = () => newWindow.print();
      }
    });
  } else if (action === "save") {
    pdfDocGenerator.download("package.pdf");
  }
};
