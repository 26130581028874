import React, { useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputTextarea } from "primereact/inputtextarea";
import { setPhaseDataNull, updatePhase } from "../../store/phase/slice";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

type PhaseProps = {
  id: string;
  name: string;
  index: number;
  description: string;
};

export default function ModelEditPhase({
  visible,
  onHide,
  toast,
  rowData,
}: {
  visible: boolean;
  onHide: () => void;
  toast: any;
  rowData: PhaseProps;
}) {
  const header = <div>Chỉnh sửa giai đoạn</div>;
  const dispatch = useDispatch();
  const { updatePhaseData, updatePhaseError } = useSelector(
    (state: any) => ({
      updatePhaseData: state.Phase.updatePhaseData,
      updatePhaseError: state.Phase.updatePhaseError,
    }),
    shallowEqual
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rowData ? rowData.name : "",
      index: rowData ? rowData.index : "",
      description: rowData ? rowData.description : "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Tên giai đoạn không được để trống"),
      index: Yup.string().required("Vị trí giai đoạn không được để trống"),
    }),
    onSubmit: (values: any) => {
      dispatch(updatePhase({ id: rowData?.id, data: values }));
    },
  });

  useEffect(() => {
    if (updatePhaseData) {
      toast.current?.show({
        severity: "success",
        summary: "Thành công",
        detail: "Chỉnh sửa giai đoạn thành công",
        life: 3000,
      });
      onHide();
      formik.resetForm();
      dispatch(setPhaseDataNull());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePhaseData]);

  useEffect(() => {
    if (updatePhaseError) {
      toast.current?.show({
        severity: "error",
        summary: "Thất bại",
        detail: `${updatePhaseError?.response?.data?.message}`,
        life: 3000,
      });
    }
    dispatch(setPhaseDataNull());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePhaseError]);

  return (
    <Dialog
      visible={visible}
      onHide={() => onHide()}
      header={header}
      style={{ width: "30%" }}
    >
      <div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="Plase_plase">Tên giai đoạn</label>
          <InputText
            id="Plase_plase"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            invalid={formik.touched.name && formik.errors.name ? true : false}
          />
          {formik.touched.name && formik.errors.name ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.name)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="index">Vị trí</label>
          <InputText
            id="index"
            value={formik.values.index}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="index"
            invalid={formik.touched.index && formik.errors.index ? true : false}
          />
          {formik.touched.index && formik.errors.index ? (
            <p
              style={{
                color: "red",
                marginTop: "5px",
                fontSize: "0.9rem",
                marginLeft: "0",
                textAlign: "left",
              }}
            >
              {String(formik.errors.index)}
            </p>
          ) : null}
        </div>
        <div className="flex flex-column gap-1 flex-1 mb-3">
          <label htmlFor="description">Ghi chú</label>
          <InputTextarea
            autoResize
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            rows={4}
            name="description"
            className="w-full"
          />
        </div>
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Hủy"
            severity="secondary"
            size="small"
            onClick={onHide}
          />
          <Button
            type="submit"
            onClick={() => formik.handleSubmit()}
            label="Lưu"
            size="small"
          />
        </div>
      </div>
    </Dialog>
  );
}
